import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { FaChevronDown, FaBars, FaRegUser } from "react-icons/fa";
import { AiOutlineLogout, AiOutlineHome } from "react-icons/ai";
import { MdMarkEmailRead, MdOutlineNoteAlt } from "react-icons/md";
import { TbDeviceAnalytics } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { BiCategory } from "react-icons/bi";
import { IoAppsOutline } from "react-icons/io5";
import { RiCoupon3Line } from "react-icons/ri";

import { VscActivateBreakpoints } from "react-icons/vsc";

import "../../componentCss/admin/SuperAdminDashboard.css";
import "../../componentCss/admin/AdminDashboard.css";

// import { GET_ALL_COMPLAINTS } from '../../graphql/Query';

export default function AdminPanel() {
  const navigate = useNavigate();

  useEffect(() => {
    var dropdown = document.querySelectorAll(".dropdown");
    var flag = 1;
    dropdown.forEach((item) => {
      item.addEventListener("click", function () {
        if (flag == 1) {
          item.classList.add("active");
          flag = 0;
        } else {
          item.classList.remove("active");
          flag = 1;
        }
      });
    });
  }, []);

  useEffect(() => {
    var sidebar = document.querySelector(".sidebar");
    var flag2 = 0;
    const menu = document.querySelector(".bx-menu");
    menu.addEventListener("click", function () {
      if (flag2 == 0) {
        sidebar.classList.add("close");
        flag2 = 1;
      } else {
        sidebar.classList.remove("close");
        flag2 = 0;
      }
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("adminToken")) {
      navigate("/adminLogin");
    }
  }, [navigate]);

  function logoutHandel() {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminId");
    navigate("/");
  }

  return (
    <>
      <div className={"sidebar"}>
        <Link to="/" className="logo-box" style={{ textDecoration: "none" }}>
          <div className="logo-name">
            <h6
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: 25,
                textAlign: "center",
                marginTop: 30,
                marginLeft: 20,
              }}
            >
              Admin Dashboard
            </h6>
          </div>
        </Link>
        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/" className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-grid-alt">
                  <AiOutlineHome />
                </i>
                <span className="name">Home</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Home
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/adminPanel/superAdminDashboardView"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <TbDeviceAnalytics />
                </i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/superAdminDashboardView"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Dashboard
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <FaRegUser />
                </i>
                <span className="name">Manage Users</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/allUser"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Users
              </Link>
              {/* <Link
                to="/adminPanel/userTransaction"
                className="link"
                style={{ textDecoration: "none" }}
              >
                User Transactions & History
              </Link> */}
              {/* <Link
                to="/adminPanel/userRefund"
                className="link"
                style={{ textDecoration: "none" }}
              >
                User Refunds
              </Link> */}
              {/* <Link
                to="/adminPanel/userSetting"
                className="link"
                style={{ textDecoration: "none" }}
              >
                User Settings
              </Link> */}

              <Link
                to="/adminPanel/RedeemOptions"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Redeem Options
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <BiCategory />
                </i>
                <span className="name">Manage Category</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/addCategory"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Add Category
              </Link>
              <Link
                to="/adminPanel/allCategory"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Category
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <IoAppsOutline />
                </i>
                <span className="name">Manage Brands</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/addBrands"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Add Brands
              </Link>
              <Link
                to="/adminPanel/allBrands"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Brands
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <RiCoupon3Line />
                </i>
                <span className="name">Manage Coupons</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              {/* <Link
                to="/adminPanel/addCoupons"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Add Coupons
              </Link> */}
              <Link
                to="/adminPanel/allCoupons"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Coupons
              </Link>
            </div>
          </li>

          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <MdOutlineNoteAlt />
                </i>
                <span className="name">Manage Banner</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/addBanner"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Add Banner
              </Link>
              <Link
                to="/adminPanel/allBanner"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Banner
              </Link>
            </div>
          </li>
          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <MdOutlineNoteAlt />
                </i>
                <span className="name">Trending Deals</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/addtrendingDeal"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Add Trending Deals
              </Link>
              <Link
                to="/adminPanel/alltrendingDeal"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Trending Deals
              </Link>
            </div>
          </li>
          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <MdOutlineNoteAlt />
                </i>
                <span className="name">Vendors</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              {/* <Link
                to="/adminPanel/AddVendors"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Add Vendors
              </Link> */}
              <Link
                to="/adminPanel/AllVendors"
                className="link"
                style={{ textDecoration: "none" }}
              >
                All Vendors
              </Link>
            </div>
          </li>

          <li>
            <Link
              to="/adminPanel/AllOrders"
              className="link"
              style={{ textDecoration: "none" }}
            >
              <div className="title">
                <div className="link" style={{ textDecoration: "none" }}>
                  <i className="bx bx-grid-alt">
                    <MdOutlineNoteAlt />
                  </i>
                  <span className="name">All Orders</span>
                </div>
              </div>
            </Link>
          </li>

          <li>
            <div className="title">
              <Link
                to="/adminPanel/topOffers"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <TbDeviceAnalytics />
                </i>
                <span className="name">Top Offers</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/topOffers"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Top Offers
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/adminPanel/topBrands"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <TbDeviceAnalytics />
                </i>
                <span className="name">Top Brands</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/topBrands"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Top Brands
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/adminPanel/featuredBrands"
                className="link"
                style={{ textDecoration: "none" }}
              >
                <i className="bx bx-grid-alt">
                  <TbDeviceAnalytics />
                </i>
                <span className="name">Featured Brands</span>
              </Link>
            </div>
            {/* <div className="submenu">
              <Link
                to="/adminPanel/featuredBrands"
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Featured Brands
              </Link>
            </div> */}
          </li>
          <li className="dropdown" style={{ overflow: "hidden" }}>
            <div className="title">
              <Link className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-book-alt">
                  <VscActivateBreakpoints />
                </i>
                <span className="name">Company Pool</span>
              </Link>
              <i className="bx bxs-chevron-down">
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/adminPanel/PointsPool"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Points Pool
              </Link>
              {/* <Link
                to="/adminPanel/PoolDetails"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Pool Detail
              </Link> */}
              <Link
                to="/adminPanel/OrderDetail"
                className="link"
                style={{ textDecoration: "none" }}
              >
                Pool
              </Link>
            </div>
          </li>
          <li>
            <Link
              to="/adminPanel/Subscribetoemail"
              className="link"
              style={{ textDecoration: "none" }}
            >
              <div className="title">
                <div className="link" style={{ textDecoration: "none" }}>
                  <i className="bx bx-grid-alt">
                    <MdMarkEmailRead />
                  </i>
                  <span className="name">Subscribe to email</span>
                </div>
              </div>
            </Link>
          </li>

          <li>
            <div className="title">
              <Link to="" className="link" style={{ textDecoration: "none" }}>
                <i className="bx bx-grid-alt">
                  <TbDeviceAnalytics />
                </i>
                <span className="name">Settings</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to=""
                className="link submenu-title"
                style={{ textDecoration: "none" }}
              >
                Settings
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/"
                className="link"
                style={{ textDecoration: "none" }}
                onClick={() => logoutHandel()}
              >
                <i className="bx bx-grid-alt">
                  <AiOutlineLogout />
                </i>
                <span className="name">Log out</span>
              </Link>
            </div>
            <div className="submenu">
              <span
                className="link submenu-title"
                style={{ textDecoration: "none" }}
                onClick={() => logoutHandel()}
              >
                Log out
              </span>
            </div>
          </li>
        </ul>
      </div>
      <section className="home" style={{ marginLeft: 50 }}>
        <div className="toggle-sidebar">
          <FaBars
            className={"bx bx-menu"}
            style={{ width: 30, height: 30, color: "#4579f9", marginLeft: 20 }}
          />
        </div>
        <Container style={{ padding: "20px" }} fluid>
          <Row>
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
