import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Dropdown } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLBRAND } from "../../../graphql/Query";
import { UPDATE_TRANDINGDEAL } from "../../../graphql/Mutations";
import swal from "sweetalert";
export default function TrandingEditModal({ show, setShow, data }) {
  const { data: BrandList } = useQuery(GET_ALLBRAND);
  const [updateTrendingDeal] = useMutation(UPDATE_TRANDINGDEAL);
  const { id, dealName, image, description, dealUrl, createdDateTime, status } =
    data;
  console.log(dealName);
  const [title, setTitle] = useState(dealName);
  const [Image, setImage] = useState(image);
  const [dealUrlEdit, setDealUrlEdit] = useState(dealUrl);
  const [otherDetails, setotherDetails] = useState(description);
  const [bannerStatus, setBannerStatus] = useState(status);
  const [validated, setValidated] = useState(false);

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateTrendingDeal({
        variables: {
          trandingDealUpdateInput: {
            id: `${id}`,
            image: `${Image}`,
            dealName: `${title}`,
            description: `${otherDetails}`,
            dealUrl: `${dealUrlEdit}`,
            status: `${bannerStatus}`,
          },
        },
      })
        .then((data) => {
          swal({
            title: "Success",
            text: "Trending Deals Update Successfully",
            icon: "success",
          });
          setShow(false);
        })
        .catch((err) => alert(err));
    }
  }

  return (
    <div>
      <Modal size="xl" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-shadow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3 brands_form">
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Upload Trending Deals Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="txtinpt"
                    placeholder="Image"

                    // onChange={(e) => setImage(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Trending Deals Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Trending Deals Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="12">
                  <Form.Label>Trending Deals URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Trending Deals URL"
                    value={dealUrlEdit}
                    onChange={(e) => setDealUrlEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Add Description</Form.Label>

                  <Form.Control
                    as="textarea"
                    placeholder="Add Description here"
                    style={{ height: "100px" }}
                    value={otherDetails}
                    onChange={(e) => setotherDetails(e.target.value)}
                  />
                </Form.Group>

                {/* <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Choose The Brand :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3  text-capitalize"
                    >
                      {brandName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {BrandList?.getBrandAll?.map(
                        ({ id, brandName }, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setBrandIdEdit(id);
                              setBrandNameEdit(brandName);
                            }}
                          >
                            {brandName}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Select Status :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3 text-capitalize"
                    >
                      {bannerStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setBannerStatus("Active")}>
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setBannerStatus("Inactive")}
                      >
                        Inactive
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setBannerStatus("Empty")}>
                        {" "}
                        Empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button type="submit" className="d-block mx-auto  mt-5">
                Add Trending Deals
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
