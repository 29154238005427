import React, { useContext, useState } from "react";
import Navbar from "../Navbar";
import "../../componentCss/login.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { VENDOR_MAIN_LOGIN } from "../../graphql/Mutations";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import Subnavbar from "./../Subnavbar";
import { FaUserCheck } from "react-icons/fa";

const VendorLogin = () => {
  const { setUserId, setToken } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || localStorage.getItem("redirect") || "/vendorDashboard/vendorDashboardView";

  const [vendorSignin, { loading }] = useMutation(VENDOR_MAIN_LOGIN, {
    onError: (e) => {
      console.log("error", e);
      const msg = String(e).split(":")[1];
      setErrorMsg(msg);
      setErrorShow(true);
    },
  });

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("userToken") ||
  //     sessionStorage.getItem("userSessionToken")
  //   ) {
  //     navigate("/user");
  //   }
  // }, [navigate]);

  function handleSubmit(e) {
    e.preventDefault();
    vendorSignin({
      variables: {
        vendorMainInputInput: {
          email: `${email}`,
          password: `${password}`,
        },
      },
    }).then((data) => {
      // if (remember) {
      //   localStorage.setItem("vendorToken", data.data.vendorMainSignin.vendorMainToken);
      //   localStorage.setItem("vendorId", data.data.vendorMainSignin.vendorId);
      // }
      // sessionStorage.setItem("vendorSessionToken", data.data.vendorMainSignin.vendorMainToken);
      // sessionStorage.setItem("vendorSessionUserId", data.data.vendorMainSignin.vendorId);
      localStorage.setItem("vendorToken", data?.data?.vendorMainSignin?.vendorMainToken);
      localStorage.setItem("vendorId", data?.data?.vendorMainSignin?.vendorId);
      setToken(data?.data?.vendorMainSignin?.vendorMainToken);
      setUserId(data?.data?.vendorMainSignin?.vendorId);
      console.log("vendor login : ",data);
      if(data.data){

        navigate("/vendorDashboard/vendorDashboardView");
      }
    }).catch(error=>{
      console.log("vendor login error",error);
    })
    // window.location.reload();
  }

  return (
    <>
      <Navbar />
      <Subnavbar />

      <div className="login_main bg-white">
        <div>
          <div className="wrapper shadow bg-white">
            <div className="heading">
              <h2>Welcome!</h2>
              <p style={{ color: '#e74c3c' }}>Login as vendor</p>
            </div>
            <form action="" onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  required
                  type="email"
                  id="username"
                  className="input-field"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  required
                  type="password"
                  id="password"
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {errorShow && (
                <div className="error-msg text-capitalize" style={{ color: "red", textAlign: 'center', fontSize: 12, marginBottom: 10 }}>
                  {errorMsg}
                </div>
              )}

              <Row>
                <Col md={8}>
                  <input
                    type="checkbox"
                    id="remember"
                    className="mx-0 me-2"
                    onClick={() => setRemember(!remember)}
                  />
                  <label htmlFor="remember" className="mx-0">
                    Remember me?
                  </label>
                </Col>
                <Col md={4}>
                  <Link
                    onClick={() => {
                      localStorage.setItem("redirect", from);
                    }}
                    to={"/vendorSignup"}
                    style={{ fontFamily: "dm sans", textDecoration: 'none' }}
                  >
                    Register
                  </Link>
                </Col>
              </Row>

              {/* <div className="row">
                <a target="_blank">Forgot password?</a>
              </div> */}
              <div className="input-group">
                {loading ? (
                  <Spinner animation="border" className="mx-auto d-block" />
                ) : (
                  <Button
                    type="submit"
                    style={{ fontFamily: "dm sans" }}
                    className="loginBtnn mx-auto d-block"
                  >
                    Login
                  </Button>
                )}
              </div>
              <br />
              {/* <Link to={"/login"} style={{ textDecoration: 'none' }}>
                <p style={{ color: '#3498db', textAlign: 'center', fontFamily: "dm sans", marginBottom: '-10px', fontWeight: 'bold', fontSize: 12 }}> <FaUserCheck style={{ marginTop: '-2px' }} /> Login as user</p>
              </Link> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorLogin;
