import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { GET_OTP } from "../../graphql/Mutations";
import { useMutation } from "@apollo/client";

export default function OtpSenderForm({ done, outData }) {
  const [email, setEmail] = useState("");
  const [getOtp, { data, loading, error }] = useMutation(GET_OTP);

  const handleSubmit = (e) => {
    e.preventDefault();
    getOtp({
      variables: {
        email: `${email}`,
      },
    })
      .then(() => {
        outData(email);
        done(true);
      })
      .catch((err) => {
        console.log("Some error while sending otp");
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? "Sending..." : "Get OTP"}
      </Button>
      {error ? (
        <h5 style={{ color: "red", margin: "0.3rem" }}>{error.message}</h5>
      ) : null}
    </Form>
  );
}
