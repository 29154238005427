import React from "react";
import "../componentCss/footer.css";
import { BiSolidPhoneCall } from "react-icons/bi";
import {
  AiFillInstagram,
  AiFillTwitterCircle,
  AiTwotoneMail,
} from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo2.jpg";
import { Container, Row, Col, Image } from "react-bootstrap";

const Footer = () => {
  const navigate = useNavigate();

  const RedirectToAboutUs = () => {
    navigate("/about");
  };

  const RedirectToPrivacyPolicy = () => {
    navigate("/privacy");
  };

  const RedirectToTerms = () => {
    navigate("/terms");
  };

  const RedirectToReturnAndRefund = () => {
    navigate("/return");
  };

  const RedirectToFaq = () => {
    navigate("/faq");
  };

  const RedirectToLogin = () => {
    navigate("/login");
  };

  const RedirectToCoupon = () => {
    navigate("/all-top-offers");
  };

  const RedirectToCategory = () => {
    navigate(
      "/categories/656b5f84c73fef0011df7829/Health%20and%20Wellness/lqrrlqqc.jpg"
    );
  };

  return (
    <>
      <Container fluid>
        <Row className=" align-items-start">
          <Col md={3}>
            <Row>
              <Image style={{ width: 250, height: 100 }} src={logo} alt="" />
            </Row>
            <Row>
              <h1 style={{ fontSize: 15 }}>
                At Rewgift.com, we’re passionate about helping you save money
                while making your online shopping experience more rewarding than
                ever. We’re committed to making your shopping journey as
                seamless as possible by offering the best deals and discounts
                through coupons and affiliate marketing partnerships
              </h1>
            </Row>
            <Row>
              <h6 className="mt-4">
                <BiSolidPhoneCall className="me-2" /> +91-8920755737
              </h6>
              <h6 className="mt-3">
                <AiTwotoneMail className="me-2" /> contactus@rewgift.com
              </h6>
            </Row>
          </Col>
          <Col md={3}>
            <Row>
              <h4 style={{ fontWeight: "bold" }}>Company</h4>
            </Row>
            <Row>
              <p className="fw-bold" onClick={RedirectToAboutUs}>
                About Us
              </p>
              <p className="fw-bold" onClick={RedirectToPrivacyPolicy}>
                Privacy Policy
              </p>
              <p className="fw-bold" onClick={RedirectToTerms}>
                Terms & Condition
              </p>
              <p className="fw-bold" onClick={RedirectToFaq}>
                FAQ
              </p>
            </Row>
          </Col>
          <Col md={3}>
            <Row>
              <h4 style={{ fontWeight: "bold" }}>Features</h4>
            </Row>
            <Row>
              <p className="fw-bold" onClick={RedirectToLogin}>
                Login
              </p>
              <p className="fw-bold" onClick={RedirectToCoupon}>
                Coupons
              </p>
              <p className="fw-bold" onClick={RedirectToCategory}>
                Categories
              </p>
            </Row>
          </Col>
          <Col md={3}>
            <Row>
              <h4 style={{ fontWeight: "bold" }}>Register with us as vendor</h4>
            </Row>
            <Row>
              <Link to="/vendorSignup">Sell with us</Link>
            </Row>
            <br />
            <Row>
              <h4 style={{ fontWeight: "bold", marginTop: "-30px" }}>
                Socials
              </h4>
            </Row>

            <Row>
              <Col>
                <BsFacebook style={{ fontSize: 30 }} />
              </Col>
              <Col>
                <FaLinkedin style={{ fontSize: 30 }} />
              </Col>
              <Col>
                <AiFillInstagram style={{ fontSize: 30 }} />
              </Col>
              <Col>
                <AiFillTwitterCircle style={{ fontSize: 30 }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
