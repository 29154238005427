import React from "react";
import Navbar from "../components/Navbar";
import "../componentCss/faq.css";
import { FaWpbeginner } from "react-icons/fa";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import { useEffect } from "react";

const Faq = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
    <UpArrow/>
      <Navbar />
      <div className="mt-5 px-5">
        <h1 className="text-black fw-bold">
          <u>FAQ</u>
        </h1>
        <div className="row mt-4">
          <div className="col-md-4 Fcards mb-md-0 mb-4">
            <div className="Fcard px-3 py-4 rounded-4">
              <div className="d-flex align-items-center">
                <div className="Ficons">
                  <FaWpbeginner className="text-primary fs-3" />
                </div>
                <h6 className="text-white ms-4">Lorem ipsum dolor sit.</h6>
              </div>
              <p className="text-white mt-3">
                Moimply dummy text of the printing and type
                settingaindustry.Lorem Ipsum has been the industry’s standard
                dummy text ever since thelong established fact thaaret.
              </p>
            </div>
          </div>
          <div className="col-md-4 Fcards mb-md-0 mb-4">
            <div className="Fcard px-3 py-4 rounded-4">
              <div className="d-flex align-items-center">
                <div className="Ficons">
                  <FaWpbeginner className="text-primary fs-3" />
                </div>
                <h6 className="text-white ms-4">Lorem ipsum dolor sit.</h6>
              </div>
              <p className="text-white mt-3">
                Moimply dummy text of the printing and type
                settingaindustry.Lorem Ipsum has been the industry’s standard
                dummy text ever since thelong established fact thaaret.
              </p>
            </div>
          </div>
          <div className="col-md-4 Fcards mb-md-0 mb-4">
            <div className="Fcard px-3 py-4 rounded-4">
              <div className="d-flex align-items-center">
                <div className="Ficons">
                  <FaWpbeginner className="text-primary fs-3" />
                </div>
                <h6 className="text-white ms-4">Lorem ipsum dolor sit.</h6>
              </div>
              <p className="text-white mt-3">
                Moimply dummy text of the printing and type
                settingaindustry.Lorem Ipsum has been the industry’s standard
                dummy text ever since thelong established fact thaaret.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 px-5 mb-5">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Lorem Ipsum has been the industry’s standard dummy text ever?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Moimply dummy text of the printing and type
                settingaindustry.Lorem Ipsum has been the industry’s standard
                dummy text ever since thelong established fact thaaret.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                 Item #2
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                 Item #3
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <Footer />
    </>
  );
};

export default Faq;
