import React, { useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { TopBrands } from "../../graphql/Query";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./slider.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

import pizza from "../../assets/pizza_hut.png";
import flipcart from "../../assets/flipcart.png";

// import required modules
import { Grid, Pagination, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

const Slider = () => {
  const navigate = useNavigate();

  const { data } = useQuery(TopBrands);

  console.table(data?.getTopBrand);

  return (
    <>
      <div className="px-5 py-4 bg-body-tertiary ">
        <h5 className="mb-4">Handpicked for you</h5>
        <Swiper
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          slidesPerView={5}
          spaceBetween={30}
          grid={{
            rows: 1,
            fill: "row", // Ensure that this is set to 'row'
          }}
          loop={true}
          modules={[Grid, Pagination, Autoplay]}
          className="mySwiper"
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {data?.getTopBrand?.map((brand, index) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                if (brand.status === "Active") {
                  navigate(`/${brand.brandName}/${brand?.id}`);
                }
              }}
            >
              <div className="shadow py-3 px-2 bg-white rounded-4 mb-4">
                <img
                  className="d-block mx-auto mb-3"
                  src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${brand?.image}`}
                  height={100}
                  width={100}
                  alt="pizza"
                />
                <p
                  className="sliderTitleTruncate
                text-secondary text-center "
                >
                  {brand?.brandName}
                </p>
                <h6
                  style={{ backgroundColor: "#F1FCFF" }}
                  className="py-2 px-2 text-center "
                >
                  Saving {brand?.saving}%
                </h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
