import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Form, Alert, Spinner, FormControl, Row, Col } from "react-bootstrap";
import { GET_VERIFICATION_OTP, VERIFY_EMAIL_OTP } from "../graphql/Mutations";

export default function EmailVerifier({ Email, type, done }) {
  const [verified, setVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");

  const [getVerificationOtp, { loading: getLoading, error: getErr }] =
    useMutation(GET_VERIFICATION_OTP);

  const [verifyEmailOtp, { loading: vLoading, error: vErr }] =
    useMutation(VERIFY_EMAIL_OTP);

  const handleGetOtp = () => {
    getVerificationOtp({
      variables: {
        getVerifyOtpInput: {
          account_type: type,
          email: Email,
        },
      },
    })
      .then(() => {
        setIsOtpSent(true);
      })
      .catch((err) => console.log(err));
  };

  const handleVerify = () => {
    verifyEmailOtp({
      variables: {
        verifyEmailOtpInput: {
          email: Email,
          otp: otp,
        },
      },
    })
      .then(() => {
        done(true);
        setVerified(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (verified) return;

    setIsOtpSent(false);
    setOtp("");
    done(false);
  }, [Email, verified]);

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email);

  return (
    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
      {verified ? (
        <Button className="mb-2" size="sm" variant="success" disabled>
          Verified
        </Button>
      ) : isOtpSent ? (
        vLoading ? (
          <Button className="mb-2 w-100" variant="primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Loading...
          </Button>
        ) : (
          <Form>
            <Row className="g-2">
              <Col xs={8}>
                <FormControl
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                />
              </Col>
              <Col xs={4}>
                <Button
                  variant="outline-primary"
                  onClick={handleVerify}
                  className="w-100"
                >
                  Verify
                </Button>
              </Col>
            </Row>
            {vErr && (
              <Alert variant="danger" className="mt-2">
                {vErr.message}
              </Alert>
            )}
          </Form>
        )
      ) : (
        <>
          {getLoading ? (
            <Button className="mb-2 w-100" variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Loading...
            </Button>
          ) : (
            <Button
              className="mb-2 mt-2"
              size="sm"
              variant="primary"
              onClick={handleGetOtp}
              disabled={!isValidEmail}
              style={{ fontSize: 12 }}
            >
              {isValidEmail ? "Get OTP" : "Invalid Email"}
            </Button>
          )}
          {
            getErr && (
              <Alert variant="danger" className="mt-2">
                {getErr.message}
              </Alert>
            )}
        </>
      )}
    </div>
  );
}
