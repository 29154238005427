import "./orderDetail.css";
import "./poolDetail.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { GET_ALL_ORDERS, GET_ORDER_BY_DATE } from "../../../graphql/Query";
import { useLazyQuery, useQuery } from "@apollo/client";
import { TfiViewListAlt } from "react-icons/tfi";

export default function OrderDetail() {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dataArr, setDataArr] = useState([]);
  const [arrSum, setArrSum] = useState(0);

  const {
    data: AllData,
    refetch: allRefetch,
    loading: allLoading,
  } = useQuery(GET_ALL_ORDERS, {
    onCompleted: (data) => {
      setDataArr(data.getAllOrder);
      console.log("data", data.getAllOrder);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const [
    filterOrder,
    { loading: filterLoading, data: filterData, refetch: filterRefetch },
  ] = useLazyQuery(GET_ORDER_BY_DATE);

  function handleFilter(e) {
    e.preventDefault();
    filterOrder({
      variables: {
        filterDate: {
          dateFrom: `${dateFrom}`,
          dateTo: `${dateTo}`,
        },
      },
    }).then((res) => {
      setDataArr(res.data.getOrderByDate);
    });
  }

  function handleClear() {
    allRefetch().then((res) => {
      setDataArr(res.data.getAllOrder);
    });
    setDateFrom("");
    setDateTo("");
  }

  async function sum(data) {
    let points = 0;
    let refPoints = 0;
    let FirstPurchCompPoints = 0;
    let FirstPurchPoints = 0;
    let companyPoints = 0;
    let discount = 0;
    let loginPoints = 0;
    let nxtRefPoints = 0;
    let qty = 0;
    let commission = 0;
    let amount = 0;
    let totalPaying = 0;
    let totalPoints = 0;

    try {
      await data.forEach((order) => {
        order.orderedCoupons.forEach((coupon) => {
          points += coupon.points || 0;
          refPoints += coupon.refPoints || 0;
          loginPoints += coupon.loginPoints || 0;
          nxtRefPoints += coupon.nxtRefPoints || 0;
          companyPoints += coupon.companyPoints || 0;
          FirstPurchPoints += coupon.FirstPurchPoints || 0;
          FirstPurchCompPoints += coupon.FirstPurchCompPoints || 0;
          qty += coupon.qty || 0;
        });
      });
      return Promise.resolve({
        points,
        refPoints,
        loginPoints,
        nxtRefPoints,
        companyPoints,
        FirstPurchPoints,
        FirstPurchCompPoints,
        qty,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  useEffect(() => {
    sum(dataArr)
      .then((sum) => setArrSum(sum))
      .catch((e) => console.log("sum error:", e));
  }, [dataArr]);

  console.log("sum:", arrSum);

  return (
    <div>
      <Container>
        <Row>
          <Col md={3}>
            <h2>Pool</h2>
          </Col>
          <Col md={9} className="input-button-group">
            <form className="form-container" onSubmit={handleFilter}>
              <label htmlFor="dateFrom">From: </label>
              <input
                required
                id="dateFrom"
                type="date"
                className="detail-input"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
              <label htmlFor="dateTo">To: </label>
              <input
                required
                id="dateTo"
                type="date"
                className="detail-input"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
              <Button size="sm" type="submit">
                Filter
              </Button>
              <Button size="sm" className="clear-button" onClick={handleClear}>
                Clear
              </Button>
            </form>
          </Col>
        </Row>
        <div class="data-line mb-2">
          <div class="data-item">
            <span class="data-key">points:</span> {arrSum.points}
          </div>
          <div class="data-item">
            <span class="data-key">login points:</span> {arrSum.loginPoints}
          </div>
          <div class="data-item">
            <span class="data-key">next refferal points:</span>{" "}
            {arrSum.nxtRefPoints}
          </div>
          <div class="data-item">
            <span class="data-key">first purchase company points:</span>{" "}
            {arrSum.FirstPurchCompPoints}
          </div>
          <div class="data-item">
            <span class="data-key">first purchase points:</span>{" "}
            {arrSum.FirstPurchPoints}
          </div>
          <div class="data-item">
            <span class="data-key">company points:</span> {arrSum.companyPoints}
          </div>
          <div class="data-item">
            <span class="data-key">referral points:</span> {arrSum.refPoints}
          </div>
          <div class="data-item">
            <span class="data-key">quantity:</span> {arrSum.qty}
          </div>
        </div>

        <Table
          responsive
          bordered
          className="text-center"
          style={{ fontSize: 12 }}
        >
          <thead>
            <tr>
              <th>S. NO.</th>
              <th>Coupon Id</th>
              <th>Brand Name</th>
              <th>Vendor ID</th>
              <th>Amount</th>
              <th>Commission</th>
              <th>Points</th>
              <th>Total Points</th>
              <th>Total Paying</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {dataArr?.map((order, i) =>
              order?.orderedCoupons?.map((coupon, j) => (
                <tr key={j}>
                  <td>{i + 1}</td>
                  <td className="sliderTitleTruncate">{coupon.title}</td>
                  <td>{coupon.brandName}</td>
                  <td>
                    <Button
                      disabled={
                        String(coupon.vendorId) === "null" ||
                        String(coupon.vendorId) === "undefined"
                      }
                      onClick={() => {
                        console.log(coupon.vendorId);
                      }}
                    >
                      Vendor
                    </Button>
                  </td>
                  <td>{coupon.amount}</td>
                  <td>{coupon.commission}</td>
                  <td>
                    <TfiViewListAlt />
                    <div className="points-details">
                      <ul className="list-group">
                        <li className="list-group-item">
                          login points: {coupon.loginPoints}
                        </li>
                        <li className="list-group-item">
                          referral points: {coupon.refPoints}
                        </li>
                        <li className="list-group-item">
                          next refferal points: {coupon.nxtRefPoints}
                        </li>
                        <li className="list-group-item">
                          company points: {coupon.companyPoints}
                        </li>
                        <li className="list-group-item">
                          first purchase points: {coupon.FirstPurchPoints}
                        </li>
                        <li className="list-group-item">
                          first purchase company points:{" "}
                          {coupon.FirstPurchCompPoints}
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>{coupon.totalPoints}</td>
                  <td>{coupon.totalPaying}</td>
                  <td>{coupon.qty}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}
