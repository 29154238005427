import React, { useState } from "react";
import "../../componentCss/page2/hero2.css";
import { FaBookOpen, FaLocationCrosshairs } from "react-icons/fa6";
import { MdRedeem } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import { FaShare, FaGlobe } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import { GET_BRAND_BY_ID } from "../../graphql/Query";
import { AiOutlineEye } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import {
  Image,
  Modal,
  Row,
  Col,
  Card,
  Button,
  Tabs,
  Tab,
  Form,
} from "react-bootstrap";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import Slider from "./Slider";
import SliderHowtouse from "./SliderHowtouse";

const Hero = () => {
  const { brand } = useParams();
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedBrandName, setSelectedBrandName] = useState(null);
  const [copyUrl, setcopyUrl] = useState(false);
  const [instructions, setInstructions] = useState(false);
  const [redeem, setRedeem] = useState(false);

  const { data: brandData } = useQuery(GET_BRAND_BY_ID, {
    variables: {
      brandId: `${brand}`,
    },
  });

  function togglecontent(name) {
    switch (name) {
      case "instructions":
        setInstructions(true);
        setRedeem(false);
        break;
      case "redeem":
        setInstructions(false);
        setRedeem(true);
        break;
    }
  }
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose = () => setShow(false);
  const handleShow = (id, brandName) => {
    console.log(id);
    setSelectedId(id);
    setSelectedBrandName(brandName);
    setShow(true);
  };

  const handleShare = (socialMedia) => {
    const url = `https://rewgift.in/${selectedBrandName}/${selectedId}`;

    switch (socialMedia) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;

      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          "_blank"
        );
        break;

      case "whatsapp":
        window.open(`https://wa.me/?text=${url}`, "_blank");

      default:
        break;
    }
    // Close the modal after sharing
    handleClose();
  };

  const CopyTheUrl = () => {
    const url = `https://rewgift.in/${selectedBrandName}/${selectedId}`;
    navigator.clipboard.writeText(url);
    setcopyUrl(true);
  };

  return (
    <>
      <div className="row px-5 m-0" style={{ background: "#F8F9FA" }}>
        <h6 className="mt-4 mb-3">
          <span className="text-secondary">
            Home <MdKeyboardArrowRight />
          </span>{" "}
          Get Best Deals and Coupons
        </h6>
        <Card className="py-4">
          <Row className="d-flex align-items-start">
            <Col md={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ width: "100%", height: "100%" }} className="mx-auto d-block">
                <div style={{ width: "100%", margin: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Image
                    src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${brandData?.getBrandByID?.image}`}
                    style={{ width: 150, height: 150 }}
                  />
                </div>
              </div>
            </Col>
            <Col md={9} className="mt-2">
              {
                instructions ? (
                  <p>{brandData?.getBrandByID?.instructions}</p>
                ) : redeem ? (
                  <div style={{ minHeight: "90%" }}>
                    <p>{brandData?.getBrandByID?.instructions}</p>
                    <button
                      className="btn btn-primary btn-sm "
                      onClick={() =>
                        window.open(`${brandData?.getBrandByID?.brandUrl}`)
                      }
                    >
                      Go to Brand Page
                    </button>
                  </div>
                ) : (
                  <p>{brandData?.getBrandByID?.description}</p>
                )}

              <p className="mt-4 text-primary" onClick={handleShow2}>
                T&C*
              </p>
              <div className="d-flex align-items-center gap-2">
                <Button
                  style={{
                    borderRadius: 100,
                    backgroundColor: "#fff",
                    padding: "10px 20px",
                    fontSize: 12,
                  }}
                  onClick={() => togglecontent("instructions")}
                >
                  {" "}
                  <FaBookOpen
                    className="me-2 icns1"
                    style={{ marginTop: "-2px" }}
                  />
                  INSTRUCTIONS{" "}
                </Button>

                <Button
                  style={{
                    borderRadius: 100,
                    backgroundColor: "#fff",
                    padding: "10px 20px",
                    fontSize: 12,
                  }}
                  onClick={() =>
                    window.open(`${brandData?.getBrandByID?.brandUrl}`)
                  }
                >
                  {" "}
                  <FaGlobe
                    className="me-2 icns1"
                    style={{ marginTop: "-2px" }}
                  />
                  GO TO BRAND PAGE{" "}
                </Button>

                <Button
                  style={{
                    borderRadius: 100,
                    backgroundColor: "#fff",
                    padding: "10px 20px",
                    fontSize: 12,
                  }}
                  onClick={handleShow2}
                // onClick={() => togglecontent("redeem")}
                >
                  {" "}
                  <MdRedeem
                    className="me-2 icns2"
                    style={{ marginTop: "-2px" }}
                  />
                  HOW TO REDEEM{" "}
                </Button>
                <Button
                  style={{
                    borderRadius: 100,
                    padding: "10px 20px",
                    fontSize: 12,
                  }}
                  onClick={() =>
                    handleShow(
                      brandData?.getBrandByID?.id,
                      brandData?.getBrandByID?.brandName
                    )
                  }
                >
                  <FaShare
                    className="icns2"
                    style={{
                      color: "#f39c12",
                      marginRight: 10,
                      marginTop: "-2px",
                    }}
                  />
                  SHARE
                </Button>

                <Button
                  style={{
                    borderRadius: 100,
                    padding: "10px 20px",
                    fontSize: 12,
                  }}
                >
                  <FaLocationCrosshairs
                    className="icns2"
                    style={{
                      color: "#f39c12",
                      marginRight: 10,
                      marginTop: "-2px",
                    }}
                  />
                  STORE LOCATOR
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      {/* Social Media Share Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share With Your Friends</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-4 ">
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#3B5998",
              }}
              onClick={() => handleShare("facebook")}
              className="d-flex align-items-center justify-content-center "
            >
              <FaFacebookF className="fs-4 text-white " />
            </div>
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#007FB1",
              }}
              onClick={() => handleShare("linkedin")}
              className="d-flex align-items-center justify-content-center "
            >
              <FaLinkedinIn className="fs-4 text-white " />
            </div>

            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#25D366",
              }}
              onClick={() => handleShare("whatsapp")}
              className="d-flex align-items-center justify-content-center "
            >
              <FaWhatsapp className="fs-4 text-white " />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
              <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                {`https://rewgift.in/${selectedBrandName}/${selectedId}`}
              </p>

              <button
                className="btn btn-sm btn-primary mt-md-0 mt-2"
                onClick={CopyTheUrl}
              >
                {copyUrl ? <TiTick /> : "copy"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Model Redeem*/}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Tabs
          id="controlled-tab-example"
          // activeKey={key}
          // onSelect={(k) => setKey(k)}
          className="mb-2"
        >
          <Tab eventKey="tc" title="TERMS & CONDITIONS">
            <div className="tab-body px-4">
              <p>{brandData?.getBrandByID?.instructions}</p>
            </div>
          </Tab>
          <Tab eventKey="rules" title="IMPORTANT INSTRUCTIONS">
            <div className="tab-body px-4">
              <p>{brandData?.getBrandByID?.instructions}</p>
            </div>{" "}
          </Tab>
          <Tab eventKey="setting" title="HOW TO USE">
            <div className="tab-body px-4">
              <SliderHowtouse />{" "}
            </div>{" "}
          </Tab>
          <Tab eventKey="wt" title="WATCH VIDEO">
            <div className="tab-body px-4">
              <iframe
                width="750"
                height="450"
                src="https://www.youtube.com/embed/jRDSsYR8GPg?si=pfMvlrG27JE6qkFR"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </div>
          </Tab>
        </Tabs>
        <button
          className="btn btn-danger position-absolute top-0"
          style={{ right: "0" }}
          onClick={handleClose2}
        >
          X
        </button>
      </Modal>
    </>
  );
};

export default Hero;
