import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Offers from "../components/Offers";
import Mail from "../components/Mail";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import { useEffect } from "react";
import Banner from "../components/Banner";
import FilterPage from "../components/FilterPage";
import Subnavbar from "../components/Subnavbar";
import Stors from '../components/Stors';
import Brands from '../components/Brands';
import FeaturedStors from '../components/FeaturedStors';
import Gallery from '../components/Gallery';

const Homepage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <UpArrow />
      {/* <Loader/> */}
      <Navbar />

      <Subnavbar />
      <Hero />
      <FilterPage />
      {/* <Offers /> */}

      {/* <Banner /> */}

      {/* <Stors name={"Top Stores"} /> */}
      {/* <Brands /> */}
      {/* <Stors name={"Top Restaurants"} /> */}
      {/* <FeaturedStors /> */}
      {/* <Gallery /> */}
      <Mail />
      <Footer />
    </>
  );
};

export default Homepage;
