import { useQuery } from "@apollo/client";
import React from "react";
import { Container, Table } from "react-bootstrap";
import { GET_ALL_ORDERS } from "../../graphql/Query";

export default function AllOrders() {
  const { data: OrderList } = useQuery(GET_ALL_ORDERS);

  return (
    <Container>
      <h2>All Orders</h2>
      <Table bordered striped className="mt-4 text-center">
        <thead>
          <tr>
            <th>#</th>
            <th>OrderId</th>
            <th>Customer Id</th>
            <th>Coupons Purchased</th>
            <th>Total Purchase</th>
            <th>Total Points</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {OrderList?.getAllOrder?.map((order, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{order?.orderId}</td>
              <td>{order?.userId}</td>
              <td>{order?.orderedCoupons?.length}</td>
              <td>{order.totalOrderPaying}</td>
              <td>{order.totalOrderPoints}</td>
              <td>{order.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
