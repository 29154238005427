import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GET_ACTIVE_VENDORS,
  GET_ALL_VENDORS,
  GET_PENDING_VENDORS,
} from "../../../graphql/Query";
import {
  Button,
  Spinner,
  Table,
  Container,
  ButtonGroup,
} from "react-bootstrap";
import Moment from "react-moment";
import { VENDOR_STATUS_UPDATE } from "../../../graphql/Mutations";
import { FaEye } from "react-icons/fa";
import ShowVendorModal from "./ShowVendorModal";

export default function AllVendors() {
  const { data: dataA, error: errorA, refetch } = useQuery(GET_ALL_VENDORS);
  const [
    getActiveVendors,
    { loading: loadingAV, error: errorAV, data: dataAV },
  ] = useLazyQuery(GET_ACTIVE_VENDORS);
  const [
    getPendingVendors,
    { loading: loadingPV, error: errorPV, data: dataPV },
  ] = useLazyQuery(GET_PENDING_VENDORS);

  const [updateStatus] = useMutation(VENDOR_STATUS_UPDATE);

  const [optionType, setOptionType] = useState("all");
  const [vendorList, setVendorList] = useState([]);
  const [updatingVendorId, setUpdatingVendorId] = useState(null);
  const [vendorModal, setVendorModal] = useState(false);
  const [vendorID, setVendorID] = useState("");

  useEffect(() => {
    if (optionType === "all") {
      refetch().then(({ data }) => {
        setVendorList(data.getAllVendors);
      });
    }
    if (optionType === "active") {
      getActiveVendors().then(({ data }) => {
        setVendorList(data.getActiveVendorsMain);
      });
    }
    if (optionType === "pending") {
      getPendingVendors().then(({ data }) => {
        setVendorList(data.getPendingVendorsMain);
      });
    }
  }, [optionType, refetch, getActiveVendors, getPendingVendors]);

  const handleUpdateStatus = (id) => {
    setUpdatingVendorId(id);
    updateStatus({
      variables: {
        vendorId: id,
      },
    }).then(() => {
      setUpdatingVendorId(null);
      if (optionType === "all") {
        refetch().then(({ data }) => {
          setVendorList(data.getAllVendors);
        });
      }
      if (optionType === "active") {
        getActiveVendors().then(({ data }) => {
          setVendorList(data.getActiveVendorsMain);
        });
      }
      if (optionType === "pending") {
        getPendingVendors().then(({ data }) => {
          setVendorList(data.getPendingVendorsMain);
        });
      }
    });
  };

  const handleShow = (id) => {
    setVendorID(id);
    setVendorModal(true);
  };

  if (loadingAV || loadingPV) return <Spinner animation="border" />;
  if (errorA || errorAV || errorPV) return <p>Error loading vendors</p>;

  return (
    <>
      <ShowVendorModal
        show={vendorModal}
        setShow={setVendorModal}
        ID={vendorID}
      />
      <Container
        style={{ padding: "20px 50px 0px 0px" }}
        className="text-capitalize"
        fluid
      >
        <ButtonGroup className="button-group">
          <Button
            variant={optionType === "all" ? "primary" : "outline-primary"}
            onClick={() => setOptionType("all")}
          >
            All
          </Button>
          <Button
            variant={optionType === "active" ? "primary" : "outline-primary"}
            onClick={() => setOptionType("active")}
          >
            Active
          </Button>
          <Button
            variant={optionType === "pending" ? "primary" : "outline-primary"}
            onClick={() => setOptionType("pending")}
          >
            Pending
          </Button>
        </ButtonGroup>
        <div className="table-container">
          <Table responsive bordered hover style={{ fontSize: 12 }}>
            <thead className="table-head">
              <tr className="text-center ">
                <th>Vendor ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Business Name</th>
                {/* <th>Contact Information</th> */}
                <th>Business Type</th>
                <th>Created Date Time</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody className="text-center align-middle">
              {vendorList?.map((vendor, index) => (
                <tr key={index}>
                  <td>{vendor.vendorUniqueId}</td>
                  <td>{vendor.firstName}</td>
                  <td>{vendor.lastName}</td>
                  <td>{vendor.email}</td>
                  <td>{vendor.contact}</td>
                  <td>{vendor.businessName}</td>
                  {/* <td>{vendor.contactInformation}</td> */}
                  <td>{vendor.businessType}</td>
                  <td>
                    <Moment format="DD/MM/YYYY hh:mm A">
                      {new Date(Number(vendor.createdDateTime))}
                    </Moment>
                  </td>
                  <td>
                    <Button
                      variant={
                        vendor.status === "active" ? "primary" : "secondary"
                      }
                      onClick={() => handleUpdateStatus(vendor.id)}
                    >
                      {updatingVendorId === vendor.id ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        vendor.status
                      )}
                    </Button>
                  </td>
                  <td>
                    <FaEye onClick={() => handleShow(vendor.id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
}
