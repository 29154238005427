import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import { useQuery, useMutation } from "@apollo/client";
import { GET_TRENDINGDEAL } from "../../../graphql/Query";
import { DeleteTreandingDeal } from "../../../graphql/Mutations";
import TrandingEditModal from "./TrandingEditModal";

export default function AllTrandingDeals() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    id: "",
    dealName: "",
    image: "",
    description: "",
    dealUrl: "",
    createdDateTime: "",
    status: "",
  });

  const { data: allTreanding } = useQuery(GET_TRENDINGDEAL, {
    pollInterval: 300,
  });
  const [deleteTreand] = useMutation(DeleteTreandingDeal, {
    refetchQueries: [GET_TRENDINGDEAL],
  });

  function handleDelete(id) {
    deleteTreand({
      variables: {
        trandingDealId: `${id}`,
      },
    });
  }
  return (
    <>
      <Container
        style={{ padding: "20px 50px 0px 0px" }}
        fluid
        className="text-capitalize"
      >
        <Row>
          <h2 className="mb-3">All Trending Deals</h2>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center ">
                  <th>S.No.</th>
                  <th>Trending Deals Image</th>
                  <th>Trending Deals Title</th>
                  <th>Deal URL</th>
                  <th>Status</th>
                  <th>Edit Trending Deals</th>
                  <th>Remove Trending Deals</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {allTreanding?.getTrendingDeal?.map(
                  (
                    {
                      id,
                      dealName,
                      image,
                      description,
                      dealUrl,
                      createdDateTime,
                      status,
                    },
                    index
                  ) => (
                    <tr className="table-data" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Image
                          fluid
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                          style={{ height: "80%", width: 100 }}
                        />
                      </td>
                      <td>{dealName}</td>
                      <td>{dealUrl}</td>
                      <td>{status}</td>
                      <td>
                        <Button
                          variant="success"
                          className="mx-auto d-block"
                          onClick={() => {
                            setShowEditModal(true);
                            setEditModalData({
                              id,
                              dealName,
                              image,
                              description,
                              dealUrl,
                              createdDateTime,
                              status,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>

                      <td>
                        <Button
                          variant="danger"
                          className="mx-auto d-block"
                          onClick={() => handleDelete(id)}
                        >
                          <FaTrashAlt className="edit-icon" />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* Modal */}
      {showEditModal ? (
        <TrandingEditModal
          show={showEditModal}
          setShow={setShowEditModal}
          data={editModalData}
        />
      ) : null}
    </>
  );
}
