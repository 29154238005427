import { useQuery } from "@apollo/client";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Container, Spinner } from "react-bootstrap";
import { GET_STORES_BY_LOCATION } from "../graphql/Query";

export default function StoreLocatorModal({ show, setShow, keyword }) {
  const handleClose = () => setShow(false);

  const { data, loading, error } = useQuery(GET_STORES_BY_LOCATION, {
    variables: { keyword },
  });

  if (error) return <p>Error: {error.message}</p>;

  return (
    <Container
      style={{ padding: "20px 50px 0px 0px" }}
      className="text-capitalize"
      fluid
    >
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Store Locations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : data.getStoreLocation.length === 0 ? (
            <p>Sorry, we could not find a location with this keyword.</p>
          ) : (
            <Table responsive bordered hover style={{ fontSize: 12 }}>
              <thead className="table-head">
                <tr className="text-center ">
                  <th>#</th>
                  <th>Business Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  {/* <th>First Name</th>
                  <th>Last Name</th> */}
                  {/* <th>Product Categories</th> */}
                  <th>Store Address</th>
                  {/* <th>Vendor ID</th> */}
                  {/* <th>Warehouse Details</th> */}
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {data.getStoreLocation.map((store, index) => (
                  <tr key={store.id}>
                    <td>{index + 1}</td>
                    <td>{store.businessName}</td>
                    <td>{store.contact}</td>
                    <td>{store.email}</td>
                    {/* <td>{store.firstName}</td>
                    <td>{store.lastName}</td> */}
                    {/* <td>{store.productCategories}</td> */}
                    <td>{store.storeAddress}</td>
                    {/* <td>{store.vendorUniqueId}</td> */}
                    {/* <td>{store.warehouseDetails}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
