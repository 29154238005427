import React from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
const UpArrow = () => {
  return (
    <a href="#">
      <div className="topArrow">
        <span>
          <MdKeyboardArrowUp />
        </span>
      </div>
    </a>
  );
};

export default UpArrow;
