import React, { useEffect } from "react";
import Navbar from "./Navbar";
import "../componentCss/login.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { USER_SIGN_UP } from "../graphql/Mutations";
import { Button, Row, Spinner } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailVerifier from "./EmailVerifier";

export default function SignUp() {
  const [isAccepted, setIsAccepted] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isVarified, setVerified] = useState(false);
  const { Id } = useParams();

  const notify = () =>
    toast.success("Signup successfully", {
      onClose: () => navigate("/login"),
    });

  const navigate = useNavigate();
  const [createUserSignIn, { error, data, loading }] = useMutation(
    USER_SIGN_UP,
    {
      onCompleted: ({ data }) => {
        notify();
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );

  function handleSubmit(e) {
    e.preventDefault();
    if (!Id) {
      createUserSignIn({
        variables: {
          userInput: {
            firstName: "",
            lastName: "",
            email: `${email}`,
            contact: "",
            password: `${password}`,
            image: "",

            earning: 0,
            refferalLevel: 0,
            status: "active",
          },
        },
      });
    } else {
      createUserSignIn({
        variables: {
          userInput: {
            firstName: "",
            lastName: "",
            email: `${email}`,
            contact: "",
            password: `${password}`,
            image: "",
            // points: 0,
            earning: 0,
            refferalLevel: 0,
            referralId: `${Id}`,
            status: "active",
          },
        },
      });
    }
  }

  return (
    <>
      <Navbar />
      <ToastContainer closeOnClick closeButton={false} theme="light" />
      <div className="login_main">
        <div>
          <div className="wrapper">
            <div className="heading">
              <h2>Sign Up</h2>
              <p>Sign up to create your account</p>
            </div>
            <form action="" onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  required
                  type="text"
                  id="email"
                  className="input-field"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <EmailVerifier Email={email} type={"User"} done={setVerified} />
              </div>

              <div className="input-group">
                <input
                  required
                  type="password"
                  id="password"
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  required
                  type="password"
                  id="confirmPassword"
                  className="input-field"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="checkbox"
                  id="remember"
                  className="mx-1"
                  onClick={() => setIsAccepted(!isAccepted)}
                />
                <label htmlFor="remember" className="mx-0">
                  I agree to Ekagam's T&C of use and privacy policy
                </label>
              </div>

              <div className="input-group">
                {loading ? (
                  <Spinner animation="border" className="mx-auto d-block" />
                ) : !isAccepted ? (
                  <Button>
                    {!isVarified ? "Verify Email & " : null}please accept T&C
                  </Button>
                ) : (
                  <Button
                    disabled={!isVarified}
                    type="submit"
                    style={{ fontFamily: "dm sans" }}
                    className="loginBtnn mx-auto d-block"
                  >
                    Sign Up
                  </Button>
                )}
              </div>
              {error ? (
                <h5
                  style={{ color: "red", margin: "0.3rem", fontWeight: "bold" }}
                >
                  {error?.message}
                </h5>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
