import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import { useEffect } from "react";

const ReturnPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <UpArrow />
      <Navbar />
      <div className="mt-5 mx-5">
        <h1 className="text-black fw-bold">
          <u>Return and Refund Policy</u>
        </h1>
        <h6 className="text-secondary fw-bold mt-4">1. Return Policy</h6>
        <p className="text-secondary mt-3">
          a. Coupon Purchases: Coupons sold on Rewgift.com are generally not
          returnable or refundable. Once you have purchased a coupon, it is your
          responsibility to use it within the specified terms and conditions
          provided by the affiliate partner. If you encounter any issues with
          the coupon, please contact the affiliate partner directly for
          resolution.
        </p>
        <p className="text-secondary mt-3">
          b. Product Purchases: If you purchase a product or service directly
          through an affiliate partner via a link on our website, your return
          and refund options are subject to the affiliate partner’s policies.
          Please review the affiliate partner’s return and refund policy for
          specific details.
        </p>

        <h6 className="text-secondary fw-bold mt-4">2. Refund Policy</h6>
        <p className="text-secondary mt-3">
          a. Coupon Purchases: We do not offer refunds for coupon purchases
          unless there is a technical error or issue on our part that prevents
          you from redeeming the coupon. In such cases, please contact our
          customer support within [timeframe, e.g., 7 days] of purchase for
          assistance.
        </p>
        <p className="text-secondary mt-3">
          b. Product Purchases: If you experience issues with a product or
          service purchased through an affiliate partner, you must follow the
          refund policy of the affiliate partner. We are not responsible for
          processing refunds for products or services purchased through
          affiliate links on our site.
        </p>
        <h6 className="text-secondary fw-bold mt-4">3. Contact Us</h6>
        <p className="text-secondary mt-3">
          If you have questions or concerns about returns or refunds, or if you
          believe you are eligible for a refund based on the circumstances
          outlined in this policy, please contact our customer support team at
          [contact email or phone number].
        </p>
        <h6 className="text-secondary fw-bold mt-4">4. Exceptions</h6>
        <p className="text-secondary mt-3">
          a. In rare cases, we may offer refunds at our discretion, such as for
          billing errors, duplicate transactions, or technical issues on our
          website that prevent you from receiving the product or service as
          intended.
        </p>
        <p className="text-secondary mt-3">
          b. Any exceptions to this policy will be reviewed on a case-by-case
          basis, and a decision will be made within a reasonable timeframe.
        </p>
        <h6 className="text-secondary fw-bold mt-4">5. Policy Updates</h6>
        <p className="text-secondary mt-3">
          We reserve the right to update or modify this Return and Refund Policy
          at any time. Please check this policy periodically for changes.
        </p>
        <p className="text-secondary mt-3">
          This Return and Refund Policy is designed to provide guidelines for
          the return and refund process on Rewgift.com. It is essential to
          communicate clearly and transparently with your customers to build
          trust and manage their expectations.
        </p>
      </div>
      <hr />
      <Footer />
    </>
  );
};

export default ReturnPolicy;
