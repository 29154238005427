import React from "react";
import { Container, Table } from "react-bootstrap";

export default function UserTransaction() {
  const userTransactionData = [
    {
      name: "test1",
      email: "email1@gmail.com",
      contact: "1234567890",
      couponId: "coupon1234",
      transactionId: "transID1234",
      transactionAmount: "20.00",
      transactionMethod: "MasterCard",
      transactionDateTime: "28/11/2023 10:00 AM",
    },
    {
      name: "test2",
      email: "email2@gmail.com",
      contact: "9876543210",
      couponId: "coupon5678",
      transactionId: "transID5678",
      transactionAmount: "35.50",
      transactionMethod: "Visa",
      transactionDateTime: "28/11/2023 11:30 AM",
    },
    {
      name: "test3",
      email: "email3@gmail.com",
      contact: "1112223333",
      couponId: "coupon91011",
      transactionId: "transID91011",
      transactionAmount: "42.75",
      transactionMethod: "American Express",
      transactionDateTime: "28/11/2023 01:45 PM",
    },
    {
      name: "test4",
      email: "email4@gmail.com",
      contact: "4445556666",
      couponId: "coupon121314",
      transactionId: "transID121314",
      transactionAmount: "60.20",
      transactionMethod: "Discover",
      transactionDateTime: "28/11/2023 03:15 PM",
    },
    {
      name: "test5",
      email: "email5@gmail.com",
      contact: "7778889999",
      couponId: "coupon151617",
      transactionId: "transID151617",
      transactionAmount: "75.80",
      transactionMethod: "PayPal",
      transactionDateTime: "28/11/2023 05:00 PM",
    },
    {
      name: "test6",
      email: "email6@gmail.com",
      contact: "6666666666",
      couponId: "coupon181920",
      transactionId: "transID181920",
      transactionAmount: "90.00",
      transactionMethod: "Venmo",
      transactionDateTime: "28/11/2023 06:30 PM",
    },
    {
      name: "test7",
      email: "email7@gmail.com",
      contact: "5555555555",
      couponId: "coupon212223",
      transactionId: "transID212223",
      transactionAmount: "100.50",
      transactionMethod: "CashApp",
      transactionDateTime: "28/11/2023 08:45 PM",
    },
    {
      name: "test8",
      email: "email8@gmail.com",
      contact: "3333333333",
      couponId: "coupon242526",
      transactionId: "transID242526",
      transactionAmount: "115.25",
      transactionMethod: "Stripe",
      transactionDateTime: "28/11/2023 10:15 PM",
    },
    {
      name: "test9",
      email: "email9@gmail.com",
      contact: "9999999999",
      couponId: "coupon272829",
      transactionId: "transID272829",
      transactionAmount: "130.75",
      transactionMethod: "Apple Pay",
      transactionDateTime: "28/11/2023 11:45 PM",
    },
    {
      name: "test10",
      email: "email10@gmail.com",
      contact: "2224446668",
      couponId: "coupon303132",
      transactionId: "transID303132",
      transactionAmount: "150.00",
      transactionMethod: "Google Pay",
      transactionDateTime: "29/11/2023 01:00 AM",
    },
  ];

  return (
    <Container className="pe-5">
      <h2 className="mb-3">Users Transaction</h2>
      <Table responsive striped bordered hover>
        <thead className="text-center align-middle">
          <tr>
            <th>S. no.</th>
            <th>User name</th>
            <th>User email</th>
            <th>User contact</th>
            <th>Coupon id</th>
            <th>Transaction id</th>
            <th>Transaction amount</th>
            <th>Transaction method</th>
            <th>Transaction Date/Time</th>
          </tr>
        </thead>
        <tbody className="text-center align-middle">
          {userTransactionData.map(
            (
              {
                name,
                email,
                contact,
                couponId,
                transactionId,
                transactionAmount,
                transactionMethod,
                transactionDateTime,
              },
              index
            ) => (
              <tr>
                <td>{index + 1}</td>
                <td>{name}</td>
                <td>{email}</td>
                <td>{contact}</td>
                <td>{couponId}</td>
                <td>{transactionId}</td>
                <td>{transactionAmount}</td>
                <td>{transactionMethod}</td>
                <td>{transactionDateTime}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </Container>
  );
}
