import { useQuery } from "@apollo/client";
import React from "react";
import { Container, Table } from "react-bootstrap";
import { GET_ORDER_BY_VENDOR_BY_ID } from "../../graphql/Query";
import Moment from "react-moment";

export default function AllVendorOrders() {
  const vendorId = localStorage.getItem("vendorId");
  const { data: OrderList } = useQuery(GET_ORDER_BY_VENDOR_BY_ID, {
    variables: {
      vendorId: `${vendorId}`,
    },
  });
  console.log("OrderList:", OrderList);
  console.log("vendotr id:", vendorId);
  return (
    <Container>
      <h2>Vendor Orders</h2>
      <Table bordered className="mt-4 text-center" style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>#</th>
            <th>OrderId</th>
            <th>Order Date</th>
            <th>Coupons Title</th>
            <th>Category Name</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {OrderList?.getCouponsByVendorId?.map((order, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{order?.orderId}</td>
              <td>
                <Moment format="DD/MM/YYYY hh:mm:ss a">
                  {order?.orderedDateTime}
                </Moment>
              </td>
              <td>{order.coupons.map((c) => c.title)}</td>
              <td>{order.coupons.map((c) => c.categoryName)}</td>
              <td>{order.coupons.map((c) => c.amount)}</td>
              <td>{order.coupons.map((c) => c.status)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
