import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/page2/Hero";
import Cards2 from "../components/page2/Cards2";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import Slider from "../components/page2/Slider";
import Subnavbar from "../components/Subnavbar";

const Page2 = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <UpArrow />
      <Navbar />
      <Subnavbar />
      <Hero />
      <Cards2 />
      <Slider />
      <Footer />
    </>
  );
};

export default Page2;
