import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Image,
  Modal,
  Form,
  Dropdown,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import swal from "sweetalert";
import { useQuery, useMutation } from "@apollo/client";
import { GetBanner, GET_ALLBRAND } from "../../../graphql/Query";
import { DeleteBanner, UpdateBanner } from "../../../graphql/Mutations";
import BannerEditModal from "./BannerEdit";

const Allbanner = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    id: "",
    image: "",
    bannerTitle: "",
    other: "",
    location: "",
    brandName: "",
    brandId: "",
    createdDateTime: "",
    status: "",
  });

  const { data: allBanner } = useQuery(GetBanner, { pollInterval: 300 });
  const [deleteBanner] = useMutation(DeleteBanner, {
    refetchQueries: [GetBanner],
  });

  function handleDelete(id) {
    deleteBanner({
      variables: {
        bannerId: `${id}`,
      },
    });
  }

  return (
    <>
      <Container
        style={{ padding: "20px 50px 0px 0px" }}
        fluid
        className="text-capitalize"
      >
        <Row>
          <h2 className="mb-3">All Banner</h2>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center ">
                  <th>S.No.</th>
                  <th>Banner Image</th>
                  <th>Banner Title</th>
                  <th>Banner Location</th>
                  <th>Banner Brand</th>
                  <th>Status</th>
                  <th>Edit Banner</th>
                  <th>Remove Banner</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {allBanner?.getBanner?.map(
                  (
                    {
                      id,
                      image,
                      bannerTitle,
                      bannerUrl,
                      other,
                      location,
                      brandName,
                      brandId,
                      createdDateTime,
                      status,
                    },
                    index
                  ) => (
                    <tr className="table-data" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Image
                          fluid
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                          style={{ height: "80%", width: 100 }}
                        />
                      </td>
                      <td>{bannerTitle}</td>
                      <td>{location}</td>
                      <td>{brandName}</td>
                      <td>{status}</td>
                      <td>
                        <Button
                          variant="success"
                          className="mx-auto d-block"
                          onClick={() => {
                            setShowEditModal(true);

                            setEditModalData({
                              id,
                              image,
                              bannerTitle,
                              bannerUrl,
                              other,
                              location,
                              brandName,
                              brandId,
                              createdDateTime,
                              status,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          className="mx-auto d-block"
                          onClick={() => handleDelete(id)}
                        >
                          <FaTrashAlt className="edit-icon" />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      {/* Edit Modal */}
      {showEditModal ? (
        <BannerEditModal
          show={showEditModal}
          setShow={setShowEditModal}
          data={editModalData}
        />
      ) : null}
    </>
  );
};

export default Allbanner;
