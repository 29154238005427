import React from "react";
import "../componentCss/mail.css";
import { useMutation } from "@apollo/client";
import { CREATE_SUBSCRIBER } from "../graphql/Mutations";

const Mail = () => {
  const [createSubscriber] = useMutation(CREATE_SUBSCRIBER);

  function handleSubmite(e) {
    e.preventDefault();
    const emailValue = e.target["email"].value;
    createSubscriber({
      variables: {
        subscriberInput: {
          email: `${emailValue}`,
        },
      },
    })
      .then(() => {
        e.target["email"].value = null;
        alert("You have subscribed to our news feed");
      })
      .catch((e) => {
        console.log("subs error: ", e);
        alert(e.message);
      });
  }
  return (
    <div className="mail_main mt-5">
      <div className="row mail_inner p-4 rounded-5">
        <div className="col-md-6">
          <h2>
            Subscribe to our emails and never miss a discount coupon & deals .
          </h2>
          <p className="mt-3">
            Stay in the savings loop! Subscribe to our email list for exclusive
            access to the latest deals, discounts, and special offers. Join now
            and never miss a chance to save on your favorite products – it's the
            easiest way to stay ahead of the savings curve! Sign up today!
          </p>
        </div>
        <div className="col-md-6 mt-md-0 mt-4 d-flex justify-content-md-end align-items-center ps-md-5">
          <form className="form_part ms-md-5" onSubmit={handleSubmite}>
            <input
              required
              id="email"
              type="email"
              placeholder="Enter your email"
            />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Mail;
