import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { GET_REDEEM_VALUE } from "../../../graphql/Query";
import { UPDATE_REDEEM_VALUE } from "../../../graphql/Mutations";
import { useMutation, useQuery } from "@apollo/client";

export default function RedeemOptions() {
  const [redeemValue, setRedeemValue] = useState(0);

  const { data: prevRedeemValue, refetch: preRefech } =
    useQuery(GET_REDEEM_VALUE);

  const [updateRedeemValue] = useMutation(UPDATE_REDEEM_VALUE);

  function handleUpdate() {
    updateRedeemValue({
      variables: {
        redeemValue: parseInt(redeemValue),
      },
    }).then(() => {
      preRefech();
    });
  }

  return (
    <>
      <h3>Redeem Options</h3>
      <Container style={containerStyle}>
        <Row>
          <Col>Previous Redeem : {prevRedeemValue?.getRedeemValue} %</Col>
          <Col>
            <label htmlFor="inputRedeem">New Redeem : </label>
            <input
              id="inputRedeem"
              type="number"
              style={inputStyle}
              value={redeemValue}
              onChange={(e) => setRedeemValue(e.target.value)}
            />
          </Col>
          <Col>
            <Button disabled={redeemValue === 0} onClick={handleUpdate}>
              Update
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const containerStyle = {
  textAlign: "center",
  marginTop: "20px",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
};

const inputStyle = {
  padding: "4px",
  marginInline: "4px",
  fontSize: "1rem",
  width: "70px",
  textAlign: "center",
  border: "1px solid #ccc",
  borderRadius: "4px",
  outline: "none",
};
