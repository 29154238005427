import React, { useContext, useState } from "react";
import Navbar from "./Navbar";
import "../componentCss/login.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { USER_SIGN_IN } from "../graphql/Mutations";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import Subnavbar from "./Subnavbar";
import { FaShop } from "react-icons/fa6";
import ForgetPasswordModal from "./forgetPassManager/ForgetPasswordModal";

const Login = () => {
  const { setUserId, setToken } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fpwShow, setFpwShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state?.from?.pathname ||
    localStorage.getItem("redirect") ||
    "/user";

  const [signInUser, { loading }] = useMutation(USER_SIGN_IN, {
    onCompleted: ({ userSignIn }) => {
      console.log("userSignIn", userSignIn);
      localStorage.setItem("userToken", userSignIn.userToken);
      localStorage.setItem("userId", userSignIn.userId);
      setToken(userSignIn.userToken);
      setUserId(userSignIn.userId);

      navigate("/user");
    },
    onError: (e) => {
      console.log("error", e);
      const msg = String(e).split(":")[1];
      setErrorMsg(msg);
      setErrorShow(true);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
    signInUser({
      variables: {
        userSigninInput: {
          email: `${email}`,
          password: `${password}`,
        },
      },
    });
  }

  return (
    <>
      <Navbar />
      <Subnavbar />
      {fpwShow ? (
        <ForgetPasswordModal show={fpwShow} setShow={setFpwShow} />
      ) : null}
      <div className="login_main bg-white">
        <div>
          <div className="wrapper shadow bg-white">
            <div className="heading">
              <h2>Welcome!</h2>
              <p style={{ color: "#3498db" }}>Login as user</p>
            </div>

            <form action="" onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  required
                  type="text"
                  id="username"
                  className="input-field"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  required
                  type="password"
                  id="password"
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {errorShow && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: 12,
                    fontFamily: "dm sans",
                    fontWeight: "bold",
                  }}
                >
                  {errorMsg}
                </div>
              )}
              <Row>
                <Col md={8}>
                  <input
                    type="checkbox"
                    id="remember"
                    className="mx-0 me-2"
                    onClick={() => setRemember(!remember)}
                  />
                  <label htmlFor="remember" className="mx-0">
                    Remember me?
                  </label>
                </Col>
                <Col md={4}>
                  <Link
                    onClick={() => {
                      localStorage.setItem("redirect", from);
                    }}
                    to={"/signup"}
                    style={{ fontFamily: "dm sans", textDecoration: "none" }}
                  >
                    Sign Up
                  </Link>
                </Col>
              </Row>

              <div className="row">
                <a target="_blank" onClick={() => setFpwShow(!fpwShow)}>
                  Forget password?
                </a>
              </div>
              <div className="input-group">
                {loading ? (
                  <Spinner animation="border" className="mx-auto d-block" />
                ) : (
                  <Button
                    type="submit"
                    style={{ fontFamily: "dm sans" }}
                    className="loginBtnn mx-auto d-block"
                  >
                    Login
                  </Button>
                )}
              </div>
              <br />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
