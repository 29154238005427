import "../../../componentCss/admin/AllCouponsStyle.css";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useQuery, useMutation } from "@apollo/client";
import { GET_COUPON_BY_VENDOR_BY_ID } from "../../../graphql/Query";
import { DeleteCoupon, UPDATE_COUPON_STATUS } from "../../../graphql/Mutations";
import EditCouponModal from "./EditVendorCouponModal";
import { FaPen } from "react-icons/fa";

export default function AllVendorCoupons() {
  const vendorId = localStorage.getItem("vendorId");
  const [couponId, setCouponId] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    id: "",
    title: "",
    couponId: "",
    brandName: "",
    brandId: "",
    storeName: "",
    couponCode: "",
    expirationDateTime: "",
    termsAndConditions: "",
    image: "",
    amount: "",
    quantity: "",
    points: "",
    discount: "",
    createdDateTime: "",
    home: "",
    status: "",
  });

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { data: allCoupons } = useQuery(GET_COUPON_BY_VENDOR_BY_ID, {
    variables: {
      vendorMainId: `${vendorId}`,
    },
    pollInterval: 300,
  });
  const [deleteCoupon] = useMutation(DeleteCoupon, {
    refetchQueries: [GET_COUPON_BY_VENDOR_BY_ID],
  });

  const [updateCouponStatus] = useMutation(UPDATE_COUPON_STATUS, {
    refetchQueries: [GET_COUPON_BY_VENDOR_BY_ID],
  });

  function handleDelete(id) {
    deleteCoupon({
      variables: {
        couponId: `${id}`,
      },
    });
  }

  function handleChangeStatus(id) {
    setCouponId(id);
    handleShow();
  }
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverOut = () => {
    setHoveredIndex(null);
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      updateCouponStatus({
        variables: {
          couponId: `${couponId}`,
          status: `${status}`,
        },
      }).then(() => {
        handleClose();
      });
    }
  };

  return (
    <>
      <Container
        style={{ padding: "20px 50px 0px 0px" }}
        className="text-capitalize"
        fluid
      >
        <Row>
          <h2 className="mb-3">Vendor Coupon</h2>
          <Col>
            <Table responsive bordered style={{ fontSize: 12 }}>
              <thead className="table-head">
                <tr className="text-center ">
                  <th>S.No.</th>
                  <th>Coupon Image</th>
                  <th>Coupon Title</th>
                  <th>Coupon Code</th>
                  <th>Store Name</th>
                  <th>Expiration Date Time</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Discount</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {allCoupons?.getCouponByVendorMainId?.map(
                  (
                    {
                      id,
                      title,
                      couponId,
                      brandName,
                      brandId,
                      storeName,
                      couponCode,
                      expirationDateTime,
                      termsAndConditions,
                      image,
                      amount,
                      quantity,
                      points,
                      discount,
                      createdDateTime,
                      home,
                      status,
                    },
                    index
                  ) => (
                    <React.Fragment key={index}>
                      <tr className="table-data">
                        <td>{index + 1}</td>
                        <td>
                          <Image
                            fluid
                            src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                            style={{ height: "80%", width: 100 }}
                          />
                        </td>
                        <td className="couponsTitleAdmintruncate">{title}</td>
                        <td>{couponCode}</td>
                        <td>{storeName}</td>
                        <td>{expirationDateTime}</td>
                        <td>CA$ {amount}</td>
                        <td>{quantity}</td>
                        <td>{discount}%</td>
                        <td>{status} </td>
                        <td>
                          <Button
                            variant="danger"
                            className="mx-auto d-block"
                            onClick={() => handleDelete(id)}
                            size="sm"
                          >
                            <FaTrashAlt className="edit-icon" />
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      {showEditModal ? (
        <EditCouponModal
          show={showEditModal}
          setShow={setShowEditModal}
          data={editModalData}
        />
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Select
                  required
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="" selected="selected" disabled="disabled">
                    Select Status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Button
              type="submit"
              size="sm"
              variant="success"
              className="mx-auto d-block"
            >
              Change
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
