import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Dropdown } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLBRAND } from "../../../graphql/Query";
import { UPDATE_COUPON } from "../../../graphql/Mutations";
import swal from 'sweetalert'
export default function EditCouponModal({ show, setShow, data }) {
  const { data: BrandList } = useQuery(GET_ALLBRAND);
  const [updateCoupon] = useMutation(UPDATE_COUPON);
  const {
    id,
    title,
    couponId,
    brandName,
    brandId,
    storeName,
    couponCode,
    expirationDateTime,
    termsAndConditions,
    image,
    amount,
    quantity,
    points,
    discount,
    createdDateTime,
    home,
    status,
  } = data;
  console.log(home);
  const [couponTitle, setCouponTitle] = useState(title);
  const [couponIdEdit, setCouponIdEdit] = useState(couponId);
  const [brandNameEdit, setBrandNameEdit] = useState(brandName);
  const [brandIdEdit, setBrandIdEdit] = useState(brandId);
  const [storeNameEdit, setStoreNameEdit] = useState(storeName);
  const [couponCodeEdit, setCouponCodeEdit] = useState(couponCode);
  const [expirationDateTimeEdit, setExpirationDateTimeEdit] = useState(expirationDateTime);
  const [couponImageEdit, setCouponImageEdit] = useState(image);
  const [termsAndConditionsEdit, setTermsAndConditionsEdit] = useState(termsAndConditions);
  const [couponAmount, setCouponAmount] = useState(amount);
  const [couponQuantity, setCouponQuantity] = useState(quantity);
  const [brandStatus, setBrandStatus] = useState(status);
  const [brandShowHome, setBrandShowHoem] = useState(home);
  const [couponsPoint, setCouponPoint] = useState(points);
  const [couponsDiscount, setCouponDiscount] = useState(discount);
  const [validated, setValidated] = useState(false);

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateCoupon({
        variables: {
          couponUpdateInput: {
            id: `${id}`,
            title: `${couponTitle}`,
            couponId: `${couponIdEdit}`,
            brandName: `${brandNameEdit}`,
            brandId: `${brandIdEdit}`,
            storeName: `${storeNameEdit}`,
            couponCode: `${couponCodeEdit}`,
            expirationDateTime: `${expirationDateTimeEdit}`,
            termsAndConditions: `${termsAndConditionsEdit}`,
            image: `${couponImageEdit}`,
            amount: parseFloat(couponAmount),
            quantity: parseInt(couponQuantity),
            points: parseFloat(couponsPoint),
            discount: parseFloat(couponsDiscount),
            home: brandShowHome,
            status: `${brandStatus}`,
          },
        },
      })
        .then((data) => {
          swal({
            title: "Success",
            text: "Coupon Updated Successfully",
            icon: "success",
          });
          setShow(false);
          window.location.reload();
        })
        .catch((err) => alert(err));
    }
  }
  return (
    <div>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-shadow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3 brands_form">
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupon Title"
                    value={couponTitle}
                    onChange={(e) => setCouponTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupon ID"
                    value={couponIdEdit}
                    onChange={(e) => setCouponIdEdit(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Store Name"
                    value={storeNameEdit}
                    onChange={(e) => setStoreNameEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Choose The Brand :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3  text-capitalize"
                    >
                      {brandName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {BrandList?.getBrandAll?.map(
                        ({ id, brandName }, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setBrandIdEdit(id);
                              setBrandNameEdit(brandName);
                            }}
                          >
                            {brandName}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupon Code"
                    value={couponCodeEdit}
                    onChange={(e) => setCouponCodeEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Coupon Amount"
                    value={couponAmount}
                    onChange={(e) => setCouponAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Coupon Quantity"
                    value={couponQuantity}
                    onChange={(e) => setCouponQuantity(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Expiration Date Time</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Expiration Date Time"
                    value={expirationDateTimeEdit}
                    onChange={(e) => setExpirationDateTimeEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon Points</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Coupon Points"
                    value={couponsPoint}
                    onChange={(e) => setCouponPoint(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Coupon Discount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Coupon Discount"
                    value={couponsDiscount}
                    onChange={(e) => setCouponDiscount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="txtinpt"
                    placeholder="Image"

                  // onChange={(e) => setCouponImageEdit(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Add Terms And Conditions</Form.Label>

                  <Form.Control
                    as="textarea"
                    placeholder="Add Terms And Conditions here"
                    style={{ height: "100px" }}
                    value={termsAndConditionsEdit}
                    onChange={(e) => setTermsAndConditionsEdit(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Select Status :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3 text-capitalize"
                    >
                      {brandStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setBrandStatus("Active")}>
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setBrandStatus("Inactive")}>
                        Inactive
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setBrandStatus("Empty")}>
                        {" "}
                        Empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Show On Home :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <div className="form-check form-switch form-switch-md pt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={brandShowHome}
                      onClick={() => setBrandShowHoem(!brandShowHome)}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Button type="submit" className="d-block mx-auto  mt-5">
                Update
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
