import React from "react";
import "../componentCss/hero.css";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiSolidDiscount } from "react-icons/bi";

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useQuery } from "@apollo/client";

//trending deals
import { GetBanner } from "../graphql/Query";
import { Link } from "react-router-dom";

const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };

  const { data: BannerData } = useQuery(GetBanner);

  console.log("sad: ", BannerData);
  return (
    <div className="hero_main">
      <div className="row">
        <div className="col-md-12 m-0 p-0 mx-1">
          <Slider {...settings}>
            {BannerData?.getBanner?.map(
              (
                { id, image, bannerTitle, bannerUrl, location, brandId },
                index
              ) => (
                <div className="hero_main_left" key={index}>
                  {/* <img
                    src="https://plus.unsplash.com/premium_photo-1661947876256-b44c1bfc04d2?q=80&w=2119&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="object-fit-cover"
                  /> */}
                  <img
                    src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                    className="object-fit-cover"
                  />
                  <div className="content">
                    <div className="left">
                      <h2
                        className="text-white text-capitalize"
                        style={{ fontFamily: "dm sans" }}
                      >
                        {bannerTitle}
                      </h2>
                      <p className="text-white">
                        <HiOutlineLocationMarker
                          className="mb-1 me-2"
                          style={{ fontFamily: "dm sans" }}
                        />
                        {location}
                      </p>
                    </div>
                    <div className="right">
                      <button style={{ fontFamily: "dm sans" }}>
                        <BiSolidDiscount className="fs-4 me-2" />
                        <a
                          className="text-style-for-anchor "
                          href={`${bannerUrl}`}
                          target="_blank"
                        >
                          Get Details
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Hero;
