import React, { useEffect, useState } from "react";
import "../../componentCss/page2/cards2.css";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../contextApi";
import { useQuery, useMutation } from "@apollo/client";
import { GET_COUPON_BRAND_ID } from "../../graphql/Query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TiMinus, TiPlus, TiTick } from "react-icons/ti";
import { FaFacebookF, FaLinkedinIn, FaShare, FaWhatsapp } from "react-icons/fa";
import { Modal, Placeholder } from "react-bootstrap";
import AuthContext from "../../context/AuthProvider";
import { logDOM } from "@testing-library/react";

const Cards2 = () => {
  const { brand, CouponId, UserId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { token, userId, setRefUserId, setRefCouponId } =
    useContext(AuthContext);
  const { cartValue, setcartValue, setcartData, addToCart, cartData } =
    useContext(DataContext);

  const [show, setShow] = useState(false);
  const [copyUrl, setcopyUrl] = useState(false);
  const [couponToRefId, setCouponToRefId] = useState("");
  const [refCouponCard, setRefCouponCard] = useState({});

  const { data: CouponData } = useQuery(GET_COUPON_BRAND_ID, {
    variables: {
      brandId: `${brand}`,
    },
    onCompleted: (data) => {
      const coupon = data?.getCoupon?.filter((card) => card.id === CouponId);
      setRefCouponCard(coupon[0]);
    },
  });

  let price = 0;
  cartData.map((data) => {
    price += parseInt(data?.youPaying * data?.qty);
  });
  const addToCartFn = (card) => {
    setcartValue(cartValue + 1);
    addToCart(card);
    setRefUserId(UserId);
    setRefCouponId(CouponId);
    toast.success("Item added in the cart !", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const increaseQuantity = (i) => {
    setcartData((prevValue) =>
      prevValue.map((data, o) => {
        if (i === o) {
          if (!(data.quantity > data.qty)) {
            return data;
          }
          const updatedQty = data.qty + 1;
          return {
            ...data,
            qty: updatedQty,
          };
        }
        return data;
      })
    );
  };

  const decreaseQuantity = (i) => {
    setcartData((prevValue) =>
      prevValue.map((data, o) => {
        if (i === o) {
          if (data.qty > 1) {
            const updatedQty = data.qty - 1;
            return { ...data, qty: updatedQty };
          } else {
            return data;
          }
        }
        return data;
      })
    );
  };

  const removeFromCart = (i) => {
    if (
      // window.confirm(
      //   "Are you sure you want to remove this item from your cart?"
      // )
      toast.success("Item removed from the cart !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    ) {
      setcartValue((prevValue) => prevValue - 1);
      setcartData((prev) => prev.filter((item, o) => i !== o));
    } else {
      // alert('No');
    }
  };

  //referral section

  const handleClose = () => {
    setShow(false);
    setcopyUrl(false);
  };
  const handleShow = (id) => {
    setCouponToRefId(id);
    setShow(true);
  };

  const handleShare = (socialMedia) => {
    const url = `${window.location.href}/${couponToRefId}/${userId}`;

    switch (socialMedia) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;

      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          "_blank"
        );
        break;

      case "whatsapp":
        window.open(`https://wa.me/?text=${url}`, "_blank");

      default:
        break;
    }

    // Close the modal after sharing
    handleClose();
  };

  const CopyTheUrl = () => {
    const url = `${window.location.href}/${couponToRefId}/${userId}`;
    navigator.clipboard.writeText(url);
    setcopyUrl(true);
  };

  return (
    <>
      {CouponId && UserId ? (
        <>
          {token ? null : (
            <Navigate to="/login" state={{ from: location }} replace />
          )}
          <div className="cards2_main pt-5 px-5 bg-body-tertiary py-4">
            <h3>Your Referrd Coupon</h3>
            <div className="row align-items-start mt-4">
              <div className="col-md-9 d-flex justify-content-start ">
                <div className="row justify-content-start w-100">
                  <div className="col-md-4 cards2 mb-md-0 mb-4">
                    <div className="card2 border rounded bg-white">
                      <div className="top">
                        {" "}
                        <div className="Clogo">
                          <img
                            className="mb-4"
                            src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${refCouponCard?.image}`}
                            alt=""
                          />
                        </div>
                        {/* <h6
                        className="Cname"
                        //onClick={() => handleShow(id)}
                      >
                        aesDFASDFFG <FaShare className="ms-2" />
                      </h6> */}
                        <div className="Cprice">
                          <h5 className="text-white fw-bold">
                            CA$ {refCouponCard?.amount}
                          </h5>
                          <div className="tri1"></div>
                          <div className="tri2"></div>
                        </div>
                      </div>
                      <div className="bottom px-2">
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-secondary">DISCOUNT</h6>
                          <h6 className="text-black">
                            {refCouponCard?.discount} %
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-secondary">Mileage (EARN)</h6>
                          <h6 className="text-black">
                            {refCouponCard?.points} P
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-success">SAVING</h6>
                          <h6 className="text-success">
                            CA$ {refCouponCard?.saving}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-primary">YOU PAY</h6>
                          <h6 className="text-primary">
                            {" "}
                            CA$ {refCouponCard?.youPaying}
                          </h6>
                        </div>
                        {refCouponCard?.status !== "Active" ? (
                          <p className="add_btn mt-2 text-center">
                            Out of stock
                          </p>
                        ) : (
                          <button
                            className="add_btn mt-2"
                            onClick={() =>
                              addToCartFn({ ...refCouponCard, qty: 1 })
                            }
                          >
                            ADD
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="cards2_main pt-3 px-5 bg-body-tertiary py-4 p-0 m-0">
        <div className="row d-flex align-items-start mt-2">
          <div className="col-md-9 d-flex justify-content-start flex-column">
            {/* <div
              className="row m-0 p-0 p-2 bg-white shadow"
              style={{
                width: "100%",
              }}
            >
              <div className="col">
                <div className="py-1 px-2" style={{ width: "fit-content" }}>
                  <input
                    type="text"
                    placeholder="Enter your coupon code"
                    className="p-1"
                    style={{
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                  <input
                    type="submit"
                    value="Apply"
                    style={{ marginLeft: 10, backgroundColor: "#5A8DEE" }}
                    className="py-1 px-3 text-white"
                  />
                </div>
              </div>
            </div> */}
            {/* <h3 className="mb-3 mt-3">Related offers </h3> */}
            <div className="row justify-content-start w-100">
              {CouponData?.getCoupon?.map((obj, index) => (
                <div key={index} className="col-md-4 cards2 mb-4">
                  <div className="card2 border rounded bg-white">
                    <div className="top">
                      <div className="Clogo">
                        <img
                          className="mb-4"
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${obj?.image}`}
                          alt=""
                        />
                      </div>
                      <h6 className="Cname" onClick={() => handleShow(obj?.id)}>
                        {obj?.brandName} <FaShare className="ms-2" />
                      </h6>

                      <div className="Cprice">
                        <h5 className="text-white fw-bold">
                          CA$ {obj?.amount}
                        </h5>
                        <div className="tri1"></div>
                        <div className="tri2"></div>
                      </div>
                    </div>

                    <div className="bottom px-2">
                      <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                        <h6 className="text-secondary">DISCOUNT</h6>
                        <h6 className="text-black">{obj?.discount} %</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                        <h6 className="text-secondary">Mileage (EARN)</h6>
                        <h6 className="text-black">{obj?.points} P</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                        <h6 className="text-success">SAVING</h6>
                        <h6 className="text-success">CA$ {obj?.saving}</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                        <h6 className="text-primary">YOU PAY</h6>
                        <h6 className="text-primary"> CA$ {obj?.youPaying}</h6>
                      </div>
                      {obj?.status !== "Active" ? (
                        <p className="add_btn mt-2 text-center">Out of stock</p>
                      ) : (
                        <button
                          className="add_btn mt-2"
                          onClick={() => addToCartFn({ ...obj, qty: 1 })}
                        >
                          ADD
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3 m-0">
            <div className="cart bg-white">
              <h5
                style={{ backgroundColor: "#343539" }}
                className="text-white py-3 px-3"
              >
                CART
              </h5>
              <div className="d-flex align-items-center justify-content-between p-3 border">
                <h6>You Pay</h6>
                <h5>CA$ {price}</h5>
              </div>
              {/* side cart */}
              {cartData?.map((data, index) => (
                <div className="px-3 py-3 border bg-body-tertiary" key={index}>
                  <div className="row">
                    <div className="col-8">
                      <h6 className="text-secondary fw-bold m-0">
                        {data?.brandName}
                      </h6>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      <RiDeleteBin5Line
                        className="text-danger"
                        onClick={() => removeFromCart(index)}
                      />
                    </div>
                  </div>
                  <div className="row m-0 mt-4">
                    <div className="col-4">
                      <p className="m-0 ">CA$ {data?.amount}</p>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-between">
                      <TiMinus onClick={() => decreaseQuantity(index)} />
                      <h6 className="fw-bold">{data?.qty}</h6>
                      <TiPlus onClick={() => increaseQuantity(index)} />
                    </div>
                    <div className="col-4">CA$ {data?.amount * data?.qty}</div>
                  </div>
                  <hr className="m-0 my-2" />
                  <div className="row m-0">
                    <div className="col-8">{data?.discount}% Discount</div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      CA$ {data?.saving * data?.qty}
                    </div>
                    <hr className="m-0 my-2" />
                    <div className="row m-0">
                      <div className="col-8">Paying:</div>
                      <div className="col-4 d-flex align-items-center justify-content-end">
                        CA$ {data?.youPaying * data?.qty}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="d-flex align-items-center p-3 border">
                <button
                  style={{ width: "100%", backgroundColor: "#E3F2FF" }}
                  className="border-0 py-3 fw-bold"
                  onClick={() => navigate("/cart")}
                >
                  View / Edit Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Social Media Share Modal */}
      {show ? (
        <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Share With Your Freinds</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-4 ">
              <div
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#3B5998",
                }}
                onClick={() => handleShare("facebook")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaFacebookF className="fs-4 text-white " />
              </div>
              <div
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#007FB1",
                }}
                onClick={() => handleShare("linkedin")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaLinkedinIn className="fs-4 text-white " />
              </div>

              <div
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#25D366",
                }}
                onClick={() => handleShare("whatsapp")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaWhatsapp className="fs-4 text-white " />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
                <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                  {`${window.location.href}/${couponToRefId}/${userId}`}
                </p>

                <button
                  className="btn btn-sm btn-primary mt-md-0 mt-2"
                  onClick={CopyTheUrl}
                >
                  {copyUrl ? <TiTick /> : "copy"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default Cards2;
