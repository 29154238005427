import React, { useEffect } from "react";
import "./pointpool.css";
import { useQuery, useMutation } from "@apollo/client";
import { GET_POOL } from "../../../graphql/Query";
import { INCREASE_POOL_POINTS } from "../../../graphql/Mutations";
import { DECREASE_POOL_POINTS } from "../../../graphql/Mutations";
import { useState } from "react";
import { Container, Card, Row, Col, Table } from "react-bootstrap";
import Moment from "react-moment";

import { LuRefreshCw } from "react-icons/lu";

export default function PointsPool() {
  const [pool, setPool] = useState({});
  const [pointsInput, setPointsInput] = useState("");

  const { data: poolData, refetch } = useQuery(GET_POOL, {
    onCompleted: () => {
      setPool(poolData?.getPool);
      console.log(poolData);
    },
    onError: (e) => console.log("query error: ", e),
  });

  useEffect(() => {
    setPool(poolData?.getPool);
  }, [poolData]);

  const [increasePoints] = useMutation(INCREASE_POOL_POINTS, {
    onCompleted: () => {
      alert("Points are added to the pool");
      setPointsInput("");
    },
  });
  const [decreasePoints] = useMutation(DECREASE_POOL_POINTS, {
    onCompleted: () => {
      alert("Points are removed from the pool");
      setPointsInput("");
    },
  });

  async function addPointsInPool(points) {
    try {
      await increasePoints({
        variables: {
          points: parseFloat(points),
        },
      });
    } catch (error) {
      console.log("mutation error: ", error);
    }
    refetch();
  }
  async function removePointsInPool(points) {
    try {
      await decreasePoints({
        variables: {
          points: parseInt(points),
        },
      });
    } catch (error) {
      console.log("mutation error: ", error);
    }
    refetch();
  }
  return (
    <>
      <h1>Points Pool</h1>
      <Container className="mt-5" style={{ minWidth: "100%", display: "flex", justifyContent: "center" }}>
        <Card style={{ width: "30rem" }}>
          <Card.Header>
            <Row>
              <Col md={10}>
                <h3>Pool details</h3>
              </Col>
              <Col md={2}>
                <button
                  style={{ border: 0, background: "none", fontSize: 18 }}
                  // onClick={() => setRefreshTrigger(!refreshTrigger)}
                  onClick={() => refetch()}
                >
                  <LuRefreshCw />
                </button>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <h5>
                <b>Points:</b> {pool?.points}
              </h5>
              <h5>
                <b>Status:</b> {pool?.status}
              </h5>
              <h5>
                <b>Freezed:</b> {pool?.freezed ? "freezed" : "not freezed"}
              </h5>
              <h5>
                <b>Last Updated:</b>{" "}
                <Moment
                  format="DD/MM/YYYY hh:mm:ss a"
                  date={pool?.lastUpdate}
                />
              </h5>
            </Row>
            {/* <hr />
            <div className="PoolWrapper">
              <button
                className="plusminus"
                onClick={() => addPointsInPool(pointsInput)}
              >
                +
              </button>
              <input
                type="number"
                className="num"
                placeholder="points"
                value={pointsInput}
                onChange={(e) => setPointsInput(e.target.value)}
              />
              <button
                className="plusminus"
                onClick={() => removePointsInPool(pointsInput)}
              >
                -
              </button>
            </div> */}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
