import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { RESET_PASSWORD } from "../../graphql/Mutations";
import { useMutation } from "@apollo/client";

export default function PwdResetterForm({ setSuccess, email }) {
  const [otp, setOtp] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [cnfPwd, setCnfPwd] = useState("");
  const [isMatch, setIsMatch] = useState(false);

  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword({
      variables: {
        forgetPasswordInput: {
          otp: `${otp}`,
          email: `${email}`,
          newPassword: `${newPwd}`,
        },
      },
    }).then(() => {
      setSuccess(true);
    });
  };

  useEffect(() => {
    if (newPwd === cnfPwd) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  }, [newPwd, cnfPwd]);
  return (
    <Form onSubmit={handleSubmit}>
      <h5>Email: {email}</h5>

      <Form.Group className="mb-3" controlId="otp">
        <Form.Label>OTP</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="newpassword">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={newPwd}
          onChange={(e) => setNewPwd(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="confirmpassword">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Confirm Password"
          value={cnfPwd}
          onChange={(e) => setCnfPwd(e.target.value)}
        />
        {isMatch ? (
          <Form.Text>Good to go</Form.Text>
        ) : (
          <Form.Text>Didn't Match</Form.Text>
        )}
      </Form.Group>

      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? "Loading..." : "Submit"}
      </Button>

      {error ? (
        <h5 style={{ color: "red", margin: "0.3rem" }}>{error.message}</h5>
      ) : null}
    </Form>
  );
}
