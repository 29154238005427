import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Homepage from "./screens/Homepage";
import About from "./screens/About";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";
import ReturnPolicy from "./screens/ReturnPolicy";
import Faq from "./screens/Faq";
import Page2 from "./screens/Page2";
import User from "./screens/User";
import Login from "./components/Login";
import AdminLogin from "./components/admin/AdminLogin";
import AdminPanel from "./components/admin/SuperAdminDashboard";
import SuperAdminDashboardView from "./components/admin/SuperAdminDashboardView";

import AddCoupons from "./components/admin/coupons/AddCoupons";
import AllCoupons from "./components/admin/coupons/AllCoupons";
import AddBrands from "./components/admin/brands/AddBrands";
import AllBrands from "./components/admin/brands/AllBrands";
import AddCategory from "./components/admin/category/AddCategory";
import AllCategory from "./components/admin/category/AllCategory";
import AllUser from "./components/admin/users/AllUsers";
import UserTransaction from "./components/admin/users/UserTransaction";
import UserRefund from "./components/admin/users/UserRefund";
import UserSetting from "./components/admin/users/UserSetting";
import AllTrendingDeal from "./components/admin/trandingDeals/AllTrandingDeals";
import AddTrendingDeal from "./components/admin/trandingDeals/AddTrandingDeals";
import TopOffers from "./components/admin/topOffers/AllTopOffers";
import TopBrands from "./components/admin/topBrands/AllTopBrands";
import FeaturedBrands from "./components/admin/featuredBrands/AllFeaturedBrands";
import AddBanner from "./components/admin/banner/AddBanner";
import AllBanner from "./components/admin/banner/Allbanner";
import SignUp from "./components/SignUp";
import Cart from "./screens/Cart";
import Categories from "./screens/Categories";
import Coupon from "./components/user/Coupon";
import Points from "./components/user/Points";
import UserOrders from "./components/admin/users/UserOrders";
import Referral from "./components/user/Referral";
import PointsPool from "./components/admin/PointsPool/PointsPool";
import AddVendor from "./components/admin/vendor/AddVendor";
import AllVendors from "./components/admin/vendor/AllVendors";
import AllOrders from "./components/admin/AllOrders";
import SearchCoupon from "./screens/SearchCoupon";
import Subscribetoemail from "./components/admin/Subscribetoemail/Subscribetoemail";
import AllTopOffers from "./screens/AllTopOffers";

import VendorLogin from "./components/vendor/VendorLogin";
import VendorSignup from "./components/vendor/VendorSignup";

import VendorDashboard from "./components/vendor/VendorDashboard";
import VendorDashboardView from "./components/vendor/VendorDashboardView";
import AddVendorCoupons from "./components/vendor/coupons/AddVendorCoupons";
import AllVendorCoupons from "./components/vendor/coupons/AllVendorCoupons";
import AllVendorOrders from "./components/vendor/AllVendorOrders";
import PoolDetails from "./components/admin/PointsPool/PoolDetails";
import OrderDetail from "./components/admin/PointsPool/OrderDetail";

import VendorProfile from "./components/vendor/VendorProfile";
import RedeemOptions from "./components/admin/users/RedeemOptions";

import Down from "./Down";
import PaymentStatus from "./screens/PaymentStatus";

const App = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Down />} />  */}
      <Route path="/" element={<Homepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/return" element={<ReturnPolicy />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/:brand/:brand" element={<Page2 />}>
        <Route path=":CouponId/:UserId" element={<Page2 />} />
      </Route>
      <Route path="/user" element={<User />}>
        <Route path="userCoupons" element={<Coupon />} />
        <Route path="userPoints" element={<Points />} />
        <Route path="Referral" element={<Referral />} />
      </Route>
      <Route path="/signup" element={<SignUp />}>
        <Route path=":Id" element={<SignUp />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/adminLogin" element={<AdminLogin />} />
      <Route path="/vendorLogin" element={<VendorLogin />} />
      <Route path="/vendorSignup" element={<VendorSignup />} />
      <Route path="/adminPanel" element={<AdminPanel />}>
        <Route path="superAdminDashboardView" element={<SuperAdminDashboardView />} />
        <Route path="addCoupons" element={<AddCoupons />} />
        <Route path="allCoupons" element={<AllCoupons />} />
        <Route path="addBrands" element={<AddBrands />} />
        <Route path="allBrands" element={<AllBrands />} />
        <Route path="addCategory" element={<AddCategory />} />
        <Route path="allCategory" element={<AllCategory />} />
        <Route path="allUser" element={<AllUser />} />
        <Route path="RedeemOptions" element={<RedeemOptions />} />
        <Route path="userTransaction" element={<UserTransaction />} />
        <Route path="userRefund" element={<UserRefund />} />
        <Route path="userSetting" element={<UserSetting />} />
        <Route path="alltrendingDeal" element={<AllTrendingDeal />} />
        <Route path="addtrendingDeal" element={<AddTrendingDeal />} />
        <Route path="topOffers" element={<TopOffers />} />
        <Route path="topBrands" element={<TopBrands />} />
        <Route path="featuredBrands" element={<FeaturedBrands />} />
        <Route path="addBanner" element={<AddBanner />} />
        <Route path="allbanner" element={<AllBanner />} />
        <Route path="userOrders/:id" element={<UserOrders />} />
        <Route path="PointsPool" element={<PointsPool />} />
        <Route path="PoolDetails" element={<PoolDetails />} />
        <Route path="OrderDetail" element={<OrderDetail />} />
        <Route path="AddVendors" element={<AddVendor />} />
        <Route path="AllVendors" element={<AllVendors />} />
        <Route path="AllOrders" element={<AllOrders />} />
        <Route path="Subscribetoemail" element={<Subscribetoemail />} />
      </Route>

      <Route path="/vendorDashboard" element={<VendorDashboard />}>
        <Route path="vendorDashboardView" element={<VendorDashboardView />} />
        <Route path="addVendorCoupons" element={<AddVendorCoupons />} />
        <Route path="allVendorCoupons" element={<AllVendorCoupons />} />
        <Route path="addCoupons" element={<AddCoupons />} />
        <Route path="allCoupons" element={<AllCoupons />} />
        <Route path="allVendorOrders" element={<AllVendorOrders />} />
        <Route path="vendorProfile" element={<VendorProfile />} />
      </Route>

      <Route path="/cart" element={<Cart />} />
      <Route path="/payment/:status" element={<PaymentStatus />} />
      <Route path="/categories/:Id/:CategoryName/:imgKey" element={<Categories />} />
      <Route path="/search-coupons/:name" element={<SearchCoupon />} />
      <Route path="/all-top-offers" element={<AllTopOffers />} />
    </Routes>
  );
};

export default App;
