import React, { useContext, useState } from "react";
import "../componentCss/page2/cards2.css";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/Navbar";
import Subnavbar from "../components/Subnavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SEARCH_COUPONS_BY_NAME } from "../graphql/Query";
import { DataContext } from "../contextApi";
import { toast } from "react-toastify";
import AuthContext from "../context/AuthProvider";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TiMinus, TiPlus, TiTick } from "react-icons/ti";

import { FaFacebookF, FaLinkedinIn, FaShare, FaWhatsapp } from "react-icons/fa";
import { Modal } from "react-bootstrap";

const SearchCoupon = () => {
  const [show, setShow] = useState(false);
  const [copyUrl, setcopyUrl] = useState(false);
  const [couponToRefId, setCouponToRefId] = useState("");
  const navigate = useNavigate();
  const { name } = useParams();

  const { cartValue, setcartValue, addToCart, setcartData, cartData } =
    useContext(DataContext);

  const { userId } = useContext(AuthContext);

  const { data: CouponList, loading } = useQuery(SEARCH_COUPONS_BY_NAME, {
    variables: {
      name: `${name}`,
    },
  });

  console.log("cuufr", CouponList);

  const handleClose = () => {
    setShow(false);
    setcopyUrl(false);
  };
  const handleShow = (id) => {
    setCouponToRefId(id);
    setShow(true);
  };

  const increaseQuantity = (i) => {
    setcartData((prevValue) =>
      prevValue.map((data, o) => {
        if (i === o) {
          if (!(data.quantity > data.qty)) {
            return data;
          }
          const updatedQty = data.qty + 1;
          return {
            ...data,
            qty: updatedQty,
          };
        }
        return data;
      })
    );
  };

  const decreaseQuantity = (i) => {
    setcartData((prevValue) =>
      prevValue.map((data, o) => {
        if (i === o) {
          if (data.qty > 1) {
            const updatedQty = data.qty - 1;
            return { ...data, qty: updatedQty };
          } else {
            return data;
          }
        }
        return data;
      })
    );
  };

  let price = 0;
  cartData.map((data) => {
    price += parseInt(data?.youPaying * data?.qty);
  });

  const addToCartFn = (card) => {
    setcartValue(cartValue + 1);
    addToCart(card);
    toast.success("Item added in the cart !", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const removeFromCart = (i) => {
    if (
      // window.confirm(
      //   "Are you sure you want to remove this item from your cart?"
      // )
      toast.success("Item removed from the cart !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    ) {
      setcartValue((prevValue) => prevValue - 1);
      setcartData((prev) => prev.filter((item, o) => i !== o));
    } else {
      // alert('No');
    }
  };

  const handleShare = (socialMedia) => {
    const url = `${window.location.href}/${couponToRefId}/${userId}`;

    switch (socialMedia) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;

      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          "_blank"
        );
        break;

      case "whatsapp":
        window.open(`https://wa.me/?text=${url}`, "_blank");

      default:
        break;
    }

    // Close the modal after sharing
    handleClose();
  };

  const CopyTheUrl = () => {
    const url = `${window.location.href}/${couponToRefId}/${userId}`;
    navigator.clipboard.writeText(url);
    setcopyUrl(true);
  };
  if (loading)
    return (
      <>
        <Navbar />
        <Subnavbar />
        <h2>Loading...</h2>
      </>
    );

  return (
    <>
      <Navbar />
      <Subnavbar />

      <div className="container-fluid ps-4 mt-4">
        <div className="row mt-4 justify-content-start align-items-start w-100">
          <div className="col-md-9 ps-3">
            <div className="row justify-content-start w-100">
              {CouponList?.searchCoupons.length === 0 ? (
                <>
                  <h2>Nothing to Show</h2>
                </>
              ) : (
                CouponList?.searchCoupons?.map((obj, index) => (
                  <div key={index} className="col-md-4 cards2 mb-4">
                    <div className="card2 border rounded bg-white">
                      <div className="top">
                        <div className="Clogo">
                          <img
                            className="mb-4"
                            src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${obj?.image}`}
                            alt=""
                          />
                        </div>
                        <h6
                          className="Cname"
                          onClick={() => handleShow(obj?.id)}
                        >
                          {obj?.brandName} <FaShare className="ms-2" />
                        </h6>

                        <div className="Cprice">
                          <h5 className="text-white fw-bold">
                            CA$ {obj?.amount}
                          </h5>
                          <div className="tri1"></div>
                          <div className="tri2"></div>
                        </div>
                      </div>

                      <div className="bottom px-2">
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-secondary">DISCOUNT</h6>
                          <h6 className="text-black">{obj?.discount} %</h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-secondary">Mileage (EARN)</h6>
                          <h6 className="text-black">{obj?.points} P</h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-success">SAVING</h6>
                          <h6 className="text-success">CA$ {obj?.saving}</h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 py-2 mt-2 bg-body-tertiary">
                          <h6 className="text-primary">YOU PAY</h6>
                          <h6 className="text-primary">
                            {" "}
                            CA$ {obj?.youPaying}
                          </h6>
                        </div>
                        {obj?.status !== "Active" ? (
                          <p className="add_btn mt-2 text-center">
                            Out of stock
                          </p>
                        ) : (
                          <button
                            className="add_btn mt-2"
                            onClick={() => addToCartFn({ ...obj, qty: 1 })}
                          >
                            ADD
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-md-3 m-0">
            <div className="cart bg-white">
              <h5
                style={{ backgroundColor: "#343539" }}
                className="text-white py-3 px-3"
              >
                CART
              </h5>
              <div className="d-flex align-items-center justify-content-between p-3 border">
                <h6>You Pay</h6>
                <h5>CA$ {price}</h5>
              </div>
              {/* side cart */}
              {cartData?.map((data, index) => (
                <div className="px-3 py-3 border bg-body-tertiary" key={index}>
                  <div className="row">
                    <div className="col-8">
                      <h6 className="text-secondary fw-bold m-0">
                        {data?.brandName}
                      </h6>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      <RiDeleteBin5Line
                        className="text-danger"
                        onClick={() => removeFromCart(index)}
                      />
                    </div>
                  </div>
                  <div className="row m-0 mt-4">
                    <div className="col-4">
                      <p className="m-0 ">CA$ {data?.amount}</p>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-between">
                      <TiMinus onClick={() => decreaseQuantity(index)} />
                      <h6 className="fw-bold">{data?.qty}</h6>
                      <TiPlus onClick={() => increaseQuantity(index)} />
                    </div>
                    <div className="col-4">CA$ {data?.amount * data?.qty}</div>
                  </div>
                  <hr className="m-0 my-2" />
                  <div className="row m-0">
                    <div className="col-8">{data?.discount}% Discount</div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      CA$ {data?.saving * data?.qty}
                    </div>
                    <hr className="m-0 my-2" />
                    <div className="row m-0">
                      <div className="col-8">Paying:</div>
                      <div className="col-4 d-flex align-items-center justify-content-end">
                        CA$ {data?.youPaying * data?.qty}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="d-flex align-items-center p-3 border">
                <button
                  style={{ width: "100%", backgroundColor: "#E3F2FF" }}
                  className="border-0 py-3 fw-bold"
                  onClick={() => navigate("/cart")}
                >
                  View / Edit Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Social Media Share Modal */}
      {show ? (
        <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Share With Your Freinds</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-4 ">
              <div
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#3B5998",
                }}
                onClick={() => handleShare("facebook")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaFacebookF className="fs-4 text-white " />
              </div>
              <div
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#007FB1",
                }}
                onClick={() => handleShare("linkedin")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaLinkedinIn className="fs-4 text-white " />
              </div>

              <div
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  backgroundColor: "#25D366",
                }}
                onClick={() => handleShare("whatsapp")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaWhatsapp className="fs-4 text-white " />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
                <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                  {`${window.location.href}/${couponToRefId}/${userId}`}
                </p>

                <button
                  className="btn btn-sm btn-primary mt-md-0 mt-2"
                  onClick={CopyTheUrl}
                >
                  {copyUrl ? <TiTick /> : "copy"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default SearchCoupon;
