import React from "react";
import "../componentCss/loader.css";

const Loader = () => {
  return (
    <>
      <div className="wrapper">
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
        <span>
          <span className="bubble" />
        </span>
      </div>
    </>
  );
};

export default Loader;
