import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Subnavbar from "../components/Subnavbar";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaShare,
  FaTags,
  FaWhatsapp,
} from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { useQuery } from "@apollo/client";
import { TopOffers } from "../graphql/Query";

const AllTopOffers = () => {
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedBrandName, setSelectedBrandName] = useState(null);
  const [copyUrl, setcopyUrl] = useState(false);
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const handleClose = () => setShow(false);
  const handleShow = (id, brandName) => {
    console.log(id);
    setSelectedId(id);
    setSelectedBrandName(brandName);
    setShow(true);
  };

  const { data: topoffer } = useQuery(TopOffers, { pollInterval: 300 });

  const handleShare = (socialMedia) => {
    const url = `https://rewgift.in/${selectedBrandName}/${selectedId}`;

    switch (socialMedia) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;

      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          "_blank"
        );
        break;

      case "whatsapp":
        window.open(`https://wa.me/?text=${url}`, "_blank");

      default:
        break;
    }

    // Close the modal after sharing
    handleClose();
  };

  const CopyTheUrl = () => {
    const url = `https://rewgift.in/${selectedBrandName}/${selectedId}`;
    navigator.clipboard.writeText(url);
    setcopyUrl(true);
  };
  return (
    <>
      <Navbar />
      <Subnavbar />

      <div className="container-fluid px-4 mt-5">
        <div className="row justify-content-start">
          {topoffer?.getTopOffer?.map(
            (
              {
                id,
                title,
                brandName,
                brandId,
                expirationDateTime,
                image,
                amount,
                discount,
                youPaying,
              },
              index
            ) => (
              <div key={index} className="col-md-3 mb-4">
                <div className="mt-md-0 mt-4 cards">
                  <div className="card">
                    <div className="card_top bg-body-tertiary">
                      <span className="new_logo">New</span>
                      <img
                        style={{
                          height: "130px",
                          width: "auto",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                        alt=""
                      />
                      <div className="company_logo">
                        <div className="logo overflow-hidden">
                          <img
                            src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                            alt=""
                          />
                        </div>
                        <h6>{brandName}</h6>
                        <div className="share_btn">
                          <h6
                            onClick={() => handleShow(brandId, brandName)}
                            className="d-flex align-items-center py-1 px-2"
                          >
                            Share
                            <FaShare className="ms-2" />
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="card_bottom">
                      <p className="mt-2 text-capitalize titleTruncate">
                        {" "}
                        {title}
                      </p>
                      <div className="review mt-2">
                        <FaTags />

                        <span className="fw-bold ms-1">
                          {" "}
                          Discount ({discount} %){" "}
                        </span>
                      </div>
                      <div className="price mt-1 d-flex justify-content-between align-items-center">
                        <h6>
                          CA$ {youPaying}
                          <span>CA${amount}</span>
                        </h6>
                      </div>
                      <div className="end_part mt-2">
                        <div>
                          <IoIosTimer className="me-2" />
                          <span>
                            Ends in{" "}
                            {Math.abs(
                              Math.floor(
                                (new Date(`${expirationDateTime}`) -
                                  new Date()) /
                                  millisecondsInADay
                              )
                            )}{" "}
                            days
                          </span>
                        </div>
                        <Link to={`/${brandName}/${brandId}`}>
                          <button className="btn btn-primary btn-sm">
                            Get Offer
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {/* Social Media Share Modal */}
      <Modal show={show} size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share With Your Friends</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-4 ">
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#3B5998",
              }}
              onClick={() => handleShare("facebook")}
              className="d-flex align-items-center justify-content-center "
            >
              <FaFacebookF className="fs-4 text-white " />
            </div>
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#007FB1",
              }}
              onClick={() => handleShare("linkedin")}
              className="d-flex align-items-center justify-content-center "
            >
              <FaLinkedinIn className="fs-4 text-white " />
            </div>

            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#25D366",
              }}
              onClick={() => handleShare("whatsapp")}
              className="d-flex align-items-center justify-content-center "
            >
              <FaWhatsapp className="fs-4 text-white " />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
              <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                {`https://rewgift.in/${selectedBrandName}/${selectedId}`}
              </p>

              <button
                className="btn btn-sm btn-primary mt-md-0 mt-2"
                onClick={CopyTheUrl}
              >
                {copyUrl ? <TiTick /> : "copy"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllTopOffers;
