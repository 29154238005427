import { useQuery } from "@apollo/client";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GET_VENDOR_BY_ID } from "../../../graphql/Query";
import PdfViewer from "./PdfViewer"; // Import the PdfViewer component if using pdf.js

export default function ShowVendorModal({ show, setShow, ID }) {
  const { data, loading, error } = useQuery(GET_VENDOR_BY_ID, {
    variables: { vendorMainId: ID },
  });

  const handleClose = () => setShow(false);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const vendor = data?.getVendorMainById;
  const baseUrl = "https://satyambucket.s3.ap-south-1.amazonaws.com/";

  return (
    <Modal show={show} onHide={handleClose} size="lg" fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Vendor Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {vendor ? (
          <div className="vendor-details">
            <ul className="list-unstyled">
              <li>
                <strong>First Name:</strong> {vendor.firstName || "N/A"}
              </li>
              <li>
                <strong>Last Name:</strong> {vendor.lastName || "N/A"}
              </li>
              <li>
                <strong>Email:</strong> {vendor.email || "N/A"}
              </li>
              <li>
                <strong>Contact:</strong> {vendor.contact || "N/A"}
              </li>
              <li>
                <strong>Business Name:</strong> {vendor.businessName || "N/A"}
              </li>
              <li>
                <strong>Business Type:</strong> {vendor.businessType || "N/A"}
              </li>
              <li>
                <strong>Store Address:</strong> {vendor.storeAddress || "N/A"}
              </li>
            </ul>
            <h5 style={{ fontWeight: 'bold' }}>File Previews</h5>
            <div className="file-previews">
              {vendor.businessRegistrationCertificate && (
                <div className="file-preview">
                  <h6 style={{ marginTop: 20, textAlign: 'center', paddingBottom: 20 }}>Business Registration Certificate</h6>
                  <PdfViewer
                    url={baseUrl + vendor.businessRegistrationCertificate}
                  />
                </div>
              )}
              {
                vendor.businessNumberFile && (
                  <div className="file-preview">
                    <h6 style={{ marginTop: 20, textAlign: 'center', paddingBottom: 20 }}>Business Number File</h6>
                    <PdfViewer url={baseUrl + vendor.businessNumberFile} />
                  </div>
                )
              }
              {vendor.gstHstRegistration && (
                <div className="file-preview">
                  <h6>GST/HST Registration</h6>
                  <PdfViewer url={baseUrl + vendor.gstHstRegistration} />
                </div>
              )}
              {vendor.supplierAgreements && (
                <div className="file-preview">
                  <h6>Supplier Agreements</h6>
                  <PdfViewer url={baseUrl + vendor.supplierAgreements} />
                </div>
              )}
              {vendor.insuranceProof && (
                <div className="file-preview">
                  <h6>Insurance Proof</h6>
                  <PdfViewer url={baseUrl + vendor.insuranceProof} />
                </div>
              )}
              {vendor.vendorAgreement && (
                <div className="file-preview">
                  <h6>Vendor Agreement</h6>
                  <PdfViewer url={baseUrl + vendor.vendorAgreement} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <p>No vendor details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
