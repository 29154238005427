import { useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { FaChevronDown, FaBars } from "react-icons/fa";
import { AiOutlineLogout, AiOutlineHome } from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";
import { TbDeviceAnalytics } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client'
import { GET_VENDOR_BY_ID } from '../../graphql/Query'
import { FaExclamationTriangle } from "react-icons/fa";

import "../../componentCss/admin/SuperAdminDashboard.css";
import "../../componentCss/admin/AdminDashboard.css";

// import { GET_ALL_COMPLAINTS } from '../../graphql/Query';

export default function VendorDashboard() {

  const navigate = useNavigate();
  const vendorId = localStorage.getItem('vendorId');


  useEffect(() => {
    var dropdown = document.querySelectorAll(".dropdown");
    var flag = 1;
    dropdown.forEach((item) => {
      item.addEventListener("click", function () {
        if (flag === 1) {
          item.classList.add("active");
          flag = 0;
        } else {
          item.classList.remove("active");
          flag = 1;
        }
      });
    });
  }, []);

  useEffect(() => {
    var sidebar = document.querySelector(".sidebar");
    var flag2 = 0;
    const menu = document.querySelector(".bx-menu");
    if (sidebar && menu) {
      menu.addEventListener("click", function () {
        if (flag2 === 0) {
          sidebar.classList.add("close");
          flag2 = 1;
        } else {
          sidebar.classList.remove("close");
          flag2 = 0;
        }
      });
    }

  }, []);

  useEffect(() => {
    if (!localStorage.getItem("vendorToken")) {
      navigate("/vendorLogin");
    }
  }, [navigate]);

  useEffect(() => {
    setInterval(() => {
      refetch()
    }, 1000);
  }, [])


  const { data, loading, refetch } = useQuery(GET_VENDOR_BY_ID, {
    variables: {
      "vendorMainId": `${vendorId}`
    }
  })

  function logoutHandel() {
    localStorage.removeItem("vendorId");
    localStorage.removeItem("vendorToken");
    navigate("/");
  }

  console.log('data', data)

  return (
    <>
      {
        loading ?
          <Spinner animation="border" role="status" className="mx-auto d-block" style={{ marginTop: 200 }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner> :

          data && data.getVendorMainById?.status === "pending" ?
            <>
              <div className={"sidebar"}>
                <Link to="/" className="logo-box" style={{ textDecoration: "none" }}>
                  <div className="logo-name">
                    <h6 style={{ color: "#fff", fontWeight: "bold", fontSize: 25, textAlign: "center", marginTop: 30, marginLeft: 20 }}>
                      Vendor Dashboard
                    </h6>
                  </div>
                </Link>
                <ul className="sidebar-list">
                  <li>
                    <div className="title">
                      <Link to="/" className="link" style={{ textDecoration: "none" }}>
                        <i className="bx bx-grid-alt">
                          <AiOutlineHome />
                        </i>
                        <span className="name">Home</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <Link to="/" className="link submenu-title" style={{ textDecoration: "none" }}>
                        Home
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div className="title">
                      <Link to="/" className="link" style={{ textDecoration: "none" }} onClick={() => logoutHandel()}>
                        <i className="bx bx-grid-alt">
                          <AiOutlineLogout />
                        </i>
                        <span className="name">Log out</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <span className="link submenu-title" style={{ textDecoration: "none" }} onClick={() => logoutHandel()}>
                        Log out
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <section className="home" style={{ marginLeft: 50 }}>
                <div className="toggle-sidebar">
                  <FaBars className={"bx bx-menu"} style={{ width: 30, height: 30, color: "#4579f9", marginLeft: 20 }} />
                </div>
                <Container style={{ padding: "20px" }} fluid>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} style={{ marginTop: 100 }}>
                          <FaExclamationTriangle style={{ textAlign: 'center', fontSize: 100, color: 'red' }} className="mx-auto d-block" />
                          <h1 style={{ textAlign: 'center' }}>Please wait!!! your profile is under review</h1>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            </>
            :
            <>
              <div className={"sidebar"}>
                <Link to="/" className="logo-box" style={{ textDecoration: "none" }}>
                  <div className="logo-name">
                    <h6 style={{ color: "#fff", fontWeight: "bold", fontSize: 25, textAlign: "center", marginTop: 30, marginLeft: 20 }}>
                      Vendor Dashboard
                    </h6>
                  </div>
                </Link>
                <ul className="sidebar-list">
                  <li>
                    <div className="title">
                      <Link to="/" className="link" style={{ textDecoration: "none" }}>
                        <i className="bx bx-grid-alt">
                          <AiOutlineHome />
                        </i>
                        <span className="name">Home</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <Link to="/" className="link submenu-title" style={{ textDecoration: "none" }}>
                        Home
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div className="title">
                      <Link to="/vendorDashboard/vendorDashboardView" className="link" style={{ textDecoration: "none" }}>
                        <i className="bx bx-grid-alt">
                          <TbDeviceAnalytics />
                        </i>
                        <span className="name">Dashboard</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <Link to="/adminPanel/superAdminDashboardView" className="link submenu-title" style={{ textDecoration: "none" }}>
                        Dashboard
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div className="title">
                      <Link to="/vendorDashboard/addVendorCoupons" className="link" style={{ textDecoration: "none" }}>
                        <i className="bx bx-grid-alt">
                          <TbDeviceAnalytics />
                        </i>
                        <span className="name">Add Coupon</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <Link to="/vendorDashboard/addVendorCoupons" className="link submenu-title" style={{ textDecoration: "none" }}>
                        Add Coupon
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div className="title">
                      <Link to="/vendorDashboard/allVendorCoupons" className="link" style={{ textDecoration: "none" }}>
                        <i className="bx bx-grid-alt">
                          <TbDeviceAnalytics />
                        </i>
                        <span className="name">All Coupon</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <Link to="/vendorDashboard/allVendorCoupons" className="link submenu-title" style={{ textDecoration: "none" }}>
                        All Coupon
                      </Link>
                    </div>
                  </li>

                  {/* <li className="dropdown" style={{ overflow: "hidden" }}>
                    <div className="title">
                      <Link className="link" style={{ textDecoration: "none" }}>
                        <i className="bx bx-book-alt">
                          <MdOutlineNoteAlt />
                        </i>
                        <span className="name">Manage Coupons</span>
                      </Link>
                      <i className="bx bxs-chevron-down">
                        <FaChevronDown />
                      </i>
                    </div>
                    <div className="submenu">
                      <Link to="/vendorDashboard/addVendorCoupons" className="link" style={{ textDecoration: "none" }}>
                        Add Coupons
                      </Link>
                      <Link to="/vendorDashboard/allVendorCoupons" className="link" style={{ textDecoration: "none" }}>
                        All Coupons
                      </Link>
                    </div>
                  </li> */}

                  <li>
                    <Link to="/vendorDashboard/allVendorOrders" className="link" style={{ textDecoration: "none" }}>
                      <div className="title">
                        <div className="link" style={{ textDecoration: "none" }}>
                          <i className="bx bx-grid-alt">
                            <MdOutlineNoteAlt />
                          </i>
                          <span className="name">All Orders</span>
                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/vendorDashboard/vendorProfile" className="link" style={{ textDecoration: "none" }}>
                      <div className="title">
                        <div className="link" style={{ textDecoration: "none" }}>
                          <i className="bx bx-grid-alt">
                            <MdOutlineNoteAlt />
                          </i>
                          <span className="name">Profile</span>
                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <div className="title">
                      <Link to="/" className="link" style={{ textDecoration: "none" }} onClick={() => logoutHandel()}>
                        <i className="bx bx-grid-alt">
                          <AiOutlineLogout />
                        </i>
                        <span className="name">Log out</span>
                      </Link>
                    </div>
                    <div className="submenu">
                      <span className="link submenu-title" style={{ textDecoration: "none" }} onClick={() => logoutHandel()}>
                        Log out
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <section className="home" style={{ marginLeft: 50 }}>
                <div className="toggle-sidebar">
                  <FaBars className={"bx bx-menu"} style={{ width: 30, height: 30, color: "#4579f9", marginLeft: 20 }} />
                </div>
                <Container style={{ padding: "20px" }} fluid>
                  <Row>
                    <Col md={12}>
                      <Outlet />
                    </Col>
                  </Row>
                </Container>
              </section>
            </>
      }

    </>
  );
}
