import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Dropdown } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CATEGORY } from "../../../graphql/Query";
import { UPDATE_BRAND } from "../../../graphql/Mutations";
import swal from "sweetalert";

export default function BrandEditModal({ show, setShow, data }) {
  const { data: categoryList } = useQuery(GET_CATEGORY);
  const [updateBrand] = useMutation(UPDATE_BRAND);
  const {
    id,
    brandName,
    brandTitle,
    brandUrl,
    image,
    category,
    categoryId,
    status,
    home,
    description,
    instructions,
    saving,
    redeemInstructions,
  } = data;
  console.log(id);
  const [validated, setValidated] = useState(false);
  const [brandNameEdit, setBrandNameEdit] = useState(brandName);
  const [brandTitleEdit, setBrandTitleEdit] = useState(brandTitle);
  const [brandUrlEdit, setBrandUrlEdit] = useState(brandUrl);
  const [brandImage, setBrandImage] = useState(image);
  const [brandCategoryName, setBrandCategoryName] = useState(category);
  const [brandCategoryID, setBrandCategoryID] = useState(categoryId);
  const [brandDescription, setBrandDescription] = useState(description);
  const [brandInstruction, setBrandInstruction] = useState(instructions);
  const [brandRedeemInstruction, setBrandRedeemInstruction] =
    useState(redeemInstructions);
  const [brandStatus, setBrandStatus] = useState(status);
  const [brandShowHome, setBrandShowHoem] = useState(home);
  const [savingEdit, setSavingedit] = useState(saving);

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateBrand({
        variables: {
          brandUpdateInput: {
            id: `${id}`,
            category: `${brandCategoryName}`,
            categoryId: `${brandCategoryID}`,
            brandName: `${brandNameEdit}`,
            brandTitle: `${brandTitleEdit}`,
            brandUrl: `${brandUrlEdit}`,
            image: `${brandImage}`,
            description: `${brandDescription}`,
            instructions: `${brandInstruction}`,
            redeemInstructions: `${brandRedeemInstruction}`,
            saving: parseInt(savingEdit),
            home: brandShowHome,
            status: `${brandStatus}`,
          },
        },
      })
        .then((data) => {
          swal({
            title: "Success",
            text: "Brand Updated Successfully",
            icon: "success",
          });
          setShow(false);
          window.location.reload();
        })
        .catch((err) => alert(err));
    }
  }

  return (
    <div>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-shadow">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3 brands_form">
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Brand Name"
                    value={brandNameEdit}
                    onChange={(e) => setBrandNameEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Brand Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Brand Title"
                    value={brandTitleEdit}
                    onChange={(e) => setBrandTitleEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="9">
                  <Form.Label>Brand URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Brand URL"
                    value={brandUrlEdit}
                    onChange={(e) => setBrandUrlEdit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="3">
                  <Form.Label>Savings</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Savings"
                    value={savingEdit}
                    onChange={(e) => setSavingedit(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6">
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="txtinpt"
                    placeholder="Image"

                  // onChange={(e) => setBrandImage(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Choose The Categories :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3 text-capitalize"
                    >
                      {brandCategoryName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {categoryList?.getCategory?.map(
                        (
                          { id, categoryName, createdDateTime, status },
                          index
                        ) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setBrandCategoryID(id);
                              setBrandCategoryName(categoryName);
                            }}
                          >
                            {categoryName}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Add Description</Form.Label>

                  <Form.Control
                    as="textarea"
                    placeholder="Add Description here"
                    style={{ height: "100px" }}
                    value={brandDescription}
                    onChange={(e) => setBrandDescription(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Add Instruction</Form.Label>

                  <Form.Control
                    as="textarea"
                    placeholder="Add Instruction here"
                    style={{ height: "100px" }}
                    value={brandInstruction}
                    onChange={(e) => setBrandInstruction(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Redeem Instruction</Form.Label>

                  <Form.Control
                    as="textarea"
                    placeholder="Add Redeem Instruction here"
                    style={{ height: "100px" }}
                    value={brandRedeemInstruction}
                    onChange={(e) => setBrandRedeemInstruction(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Select Status :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                      className="rounded-pill w-100 mt-3 text-capitalize"
                    >
                      {brandStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setBrandStatus("Active")}>
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setBrandStatus("Inactive")}>
                        Inactive
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setBrandStatus("Empty")}>
                        {" "}
                        Empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustom02"
                  className="pt-4"
                >
                  <Form.Label>Show On Home :</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <div className="form-check form-switch form-switch-md pt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={brandShowHome}
                      onClick={() => setBrandShowHoem(!brandShowHome)}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Button type="submit" className="d-block mx-auto  mt-5">
                Update
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
