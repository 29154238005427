import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import { useQuery, useMutation } from "@apollo/client";
import { TopBrands } from "../../../graphql/Query";

export default function AllTopBrands() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data: topBrands } = useQuery(TopBrands, { pollInterval: 300 });
  // const [deleteBrand] = useMutation(DELETE_BRAND, {
  //   refetchQueries: [GET_ALLBRAND],
  // });

  // function handleDelete(id) {
  //   deleteBrand({
  //     variables: {
  //       brandId: `${id}`,
  //     },
  //   });
  // }
  return (
    <>
      <Container style={{ padding: 20 }} fluid className="text-capitalize">
        <Row>
          <h2 className="mb-3">All Top Brands</h2>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center">
                  <th>S.No.</th>
                  <th>Top Brand Image</th>
                  <th>Top Brand Name</th>
                  <th>Top Brand Category</th>
                  <th>Status</th>
                  <th>Home</th>
                  <th>Remove Top Brand</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {topBrands?.getTopBrand?.map(
                  (
                    {
                      id,
                      category,
                      categoryId,
                      brandName,
                      brandTitle,
                      image,
                      description,
                      instructions,
                      redeemInstructions,
                      createdDateTime,
                      home,
                      status,
                    },
                    index
                  ) => (
                    <tr className="table-data" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Image
                          fluid
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                          style={{ height: "80%", width: 100 }}
                        />
                      </td>
                      <td>{brandName}</td>
                      <td>{category}</td>
                      <td>{status}</td>
                      <td>{home ? "show" : "hidden"}</td>

                      <td>
                        <Button
                          variant="danger"
                          className="mx-auto d-block"
                          // onClick={() => handleDelete(id)}
                        >
                          <FaTrashAlt className="edit-icon" />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Coming Soon !!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
