import React, { useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_ORDER_BY_TOKEN } from "../../graphql/Query";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import Moment from "react-moment";

const Coupon = () => {
  const { token } = useContext(AuthContext);

  const { loading, error, data } = useQuery(GET_ORDER_BY_TOKEN, {
    variables: {
      token: token,
    },
  });

  if (data) {
    // console.log("dataaaa", data);
  }

  const orderList = data?.getOrderByToken;

  return (
    <>
      {orderList?.map((order, index) => (
        <Accordion className="mt-3" key={index}>
          <Accordion.Item
            className={
              order && order.status === "succeeded"
                ? "border border-success"
                : order.status === "Pending"
                ? "border border-warning"
                : "border border-danger"
            }
            eventKey="0"
          >
            <Accordion.Header>
              <div className="row w-100">
                <div className="col-3">
                  {" "}
                  <h6 className="fw-bold">Order No.: {order?.orderId}</h6>
                </div>
                <div className="col-3">
                  {" "}
                  <h6 className="fw-bold">
                    Date:{" "}
                    <Moment format="DD-MM-YYYY">
                      {order?.orderedDateTime}
                    </Moment>
                  </h6>
                </div>
                <div className="col-3">
                  <h6 className="fw-bold">
                    Total Price: CA$ {order?.totalOrderPaying}
                  </h6>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={6}>
                  {" "}
                  <b>Payment ID :</b> {order && order.transactionID}
                </Col>
                <Col md={6}>
                  {" "}
                  <b>Status : </b> {order && order.status}
                </Col>
              </Row>

              <hr />

              {order.orderedCoupons?.map((data, index) => (
                <div className="row" key={index}>
                  <div className="col-2 overflow-hidden ">
                    <img
                      style={{
                        objectFit: "cover",
                      }}
                      height={50}
                      src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${data?.image}`}
                      alt=""
                    />
                  </div>
                  <div className="col-1">
                    <p className="m-0">{data?.brandName}</p>
                  </div>
                  <div className="col-3 ps-5">
                    <p className="m-0">CouponId: {data?.couponId}</p>
                  </div>
                  <div className="col-2">
                    <p className="m-0">Quantity:{data?.qty}</p>
                  </div>
                  <div className="col-2">
                    <p className="m-0">Price: CA$ {data?.totalPaying}</p>
                  </div>

                  <div className="col-2">
                    <p className="m-0">Points: {data?.totalPoints}</p>
                  </div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </>
  );
};

export default Coupon;
