import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useMutation } from "@apollo/client";
import { CREATE_VENDOR } from "../../../graphql/Mutations";
import { Container } from "react-bootstrap";

export default function AddVendors() {
  const [validated, setValidated] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [ifscError, setIfscError] = useState('');
  const [createVendor] = useMutation(CREATE_VENDOR);

  const fetchBankDetails = (ifsc) => {
    fetch(`https://ifsc.razorpay.com/${ifsc}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Invalid IFSC Code");
        }
        return res.json();
      })
      .then((data) => {
        setBankDetails(data);
        setIfscError('');
      })
      .catch(() => {
        setBankDetails({});
        setIfscError('Invalid IFSC Code');
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setValidated(false);

      // Form data processing logic
      const formData = {
        VendorName: form.vendorName.value,
        VendorNum: form.vendorNumber.value,
        AccName: form.accountName.value,
        BankName: bankDetails.BANK || form.bankName.value, // Use fetched bank name if available
        BankBranch: bankDetails.BRANCH || form.bankBranch.value, // Use fetched bank branch if available
        ACCNum: form.accountNumber.value,
        IFSC: form.ifsc.value,
      };

      createVendor({
        variables: {
          vendorInput: {
            vendorName: `${formData.VendorName}`,
            vendorNumber: `${formData.VendorNum}`,
            accountName: `${formData.AccName}`,
            bankName: `${formData.BankName}`,
            bankBranch: `${formData.BankBranch}`,
            accountNumber: `${formData.ACCNum}`,
            ifsc: `${formData.IFSC}`,
            status: `not paid`,
          },
        },
      });

      // Reset form fields
      form.reset();

      // setValidated(true);
      window.location.reload();
      // Consider handling the form submission response here
    }
  };

  return (
    <>
      <Container>
        <Row className="mx-4 ">
          <h2 className="page-title mb-3">Add Vendor</h2>
          <Col className="shadow p-3 rounded ">
            <div className="box-shadow">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {/* Vendor Name and Contact Number */}
                <Row className="mb-3">
                  {/* Vendor Name */}
                  <Form.Group as={Col} md="7" controlId="vendorName">
                    <Form.Label>Vendor Name <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control required type="text" placeholder="Vendor Name" />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  {/* Vendor Contact Number */}
                  <Form.Group as={Col} md="5" controlId="vendorNumber">
                    <Form.Label>Vendor Contact Number <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control required type="number" placeholder="Vendor Contact Number" />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                {/* Bank Account Details */}
                <Row className="mb-3">
                  {/* Name on Bank Account */}
                  <Form.Group as={Col} md="4" controlId="accountName">
                    <Form.Label>Name on Bank Account <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control required type="text" placeholder="Name on Bank Account" />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  {/* Bank Name */}
                  <Form.Group as={Col} md="4" controlId="bankName">
                    <Form.Label>Bank Name <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Bank Name"
                      value={bankDetails.BANK || ''}
                      readOnly={!!bankDetails.BANK}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  {/* Bank Branch */}
                  <Form.Group as={Col} md="4" controlId="bankBranch">
                    <Form.Label>Bank Branch <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Bank Branch"
                      value={bankDetails.BRANCH || ''}
                      readOnly={!!bankDetails.BRANCH}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                {/* Account Number and IFSC */}
                <Row className="mb-3">
                  {/* Account Number */}
                  <Form.Group as={Col} md="8" controlId="accountNumber">
                    <Form.Label>Account Number <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control required type="number" placeholder="Account Number" />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  {/* IFSC Code */}
                  <Form.Group as={Col} md="4" controlId="ifsc">
                    <Form.Label>IFSC Code <span className="required-asterisk">*</span></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="IFSC Code"
                      onChange={(e) => fetchBankDetails(e.target.value)}
                    />
                    {ifscError && <div style={{ color: 'red' }}>{ifscError}</div>}
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                {/* Submit Button */}
                <Button type="submit">Submit form</Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
