import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Modal,
  Form,
  Image,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { GET_CATEGORY } from "../../../graphql/Query";
import { useQuery, useMutation } from "@apollo/client";
import {
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_STATUS,
} from "../../../graphql/Mutations";
import CategoryEditModal from "./CategoryEditModal";
import { FaPen } from "react-icons/fa";

export default function AllCategory() {
  const [categoryId, setCategoryId] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    id: "",
    categoryBannerImage: "",
    categoryName: "",
    status: "",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data } = useQuery(GET_CATEGORY, { pollInterval: 300 });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    refetchQueries: [GET_CATEGORY],
  });

  const [updateCategoryStatus] = useMutation(UPDATE_CATEGORY_STATUS, {
    refetchQueries: [GET_CATEGORY],
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [GET_CATEGORY],
  });

  function handleDelete(id) {
    deleteCategory({
      variables: {
        categoryId: `${id}`,
      },
    });
  }

  function handleChangeStatus(id) {
    setCategoryId(id);
    handleShow();
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      updateCategoryStatus({
        variables: {
          categoryId: `${categoryId}`,
          status: `${status}`,
        },
      }).then(() => {
        handleClose();
      });
    }
  };

  return (
    <>
      <Container style={{ padding: 20 }} fluid className="text-capitalize">
        <Row>
          <h2 className="mb-3">All Category</h2>
          <Col className="me-5">
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center">
                  <th>S.No.</th>
                  <th>Images</th>
                  <th>Category Name</th>
                  <th>Status</th>
                  <th>Edit Category</th>
                  <th>Remove Category</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {data?.getCategory?.map(
                  (
                    {
                      id,
                      categoryName,
                      categoryBannerImage,
                      createdDateTime,
                      status,
                    },
                    index
                  ) => (
                    <tr className="table-data" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Image
                          fluid
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${categoryBannerImage}`}
                          style={{ height: "80%", width: 100 }}
                        />
                      </td>
                      <td>{categoryName}</td>
                      <td>
                        {status}{" "}
                        <FaPen
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChangeStatus(id)}
                        />
                      </td>
                      <td>
                        {" "}
                        <Button
                          variant="success"
                          className="mx-auto d-block"
                          onClick={() => {
                            setShowEditModal(true);
                            setEditModalData({
                              id,
                              categoryBannerImage,
                              categoryName,
                              status,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          className="mx-auto d-block"
                          onClick={() => handleDelete(id)}
                        >
                          <FaTrashAlt className="edit-icon" />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* Edit Modal */}
      {showEditModal ? (
        <CategoryEditModal
          show={showEditModal}
          setShow={setShowEditModal}
          data={editModalData}
        />
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Select
                  required
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="" selected="selected" disabled="disabled">
                    Select Status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Button
              type="submit"
              size="sm"
              variant="success"
              className="mx-auto d-block"
            >
              Change
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
