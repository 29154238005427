import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <UpArrow />
      <Navbar />
      <div className="mt-5 px-5">
        <h1 className="text-black fw-bold">
          <u>Terms and Conditions</u>
        </h1>
        <h6 className="text-secondary fw-bold mt-4">
          1. Acceptance of Terms
        </h6>
        <p className="text-secondary mt-3">
          By accessing and using Rewgift.com, you agree to comply with and be
          bound by the following terms and conditions of use. If you do not
          agree with these terms, please do not use this website.
        </p>

        <h6 className="text-secondary fw-bold mt-4">2. Use of Services</h6>
        <p className="text-secondary mt-3">
          a. Rewgift.com provides coupons and affiliate marketing services.
          Users are solely responsible for their use of these services,
          including any purchases or interactions with affiliate partners.
        </p>
        <p className="text-secondary mt-3">
          b. Users must be at least 18 years of age to use this website.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          3. Account Registration
        </h6>
        <p className="text-secondary mt-3">
          a. To access certain features of Rewgift.com, you may be required to
          create an account. You are responsible for the security of your
          account information
        </p>
        <p className="text-secondary mt-3">
          b. You agree to provide accurate and complete information during the
          registration process.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          4. Coupon Codes and Offers
        </h6>
        <p className="text-secondary mt-3">
          a. Rewgift.com provides coupon codes and offers from third-party
          merchants. We do not guarantee the accuracy or availability of these
          offers.
        </p>
        <p className="text-secondary mt-3">
          b. Users should check the terms and conditions of each coupon or offer
          before making a purchase.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          5. Affiliate Marketing
        </h6>
        <p className="text-secondary mt-3">
          a. Rewgift.com participates in affiliate marketing programs. We may
          earn commissions on purchases made through affiliate links on our
          site.
        </p>
        <p className="text-secondary mt-3">
          b. Users acknowledge that affiliate links may be present on our
          website.
        </p>
        <h6 className="text-secondary fw-bold mt-4">6. User Conduct</h6>
        <p className="text-secondary mt-3">
          a. Users must not engage in any unlawful, fraudulent, or harmful
          activities while using Rewgift.com.
        </p>
        <p className="text-secondary mt-3">
          b. Users must not post or share any content that is offensive,
          defamatory, or infringing on the rights of others..
        </p>
        <h6 className="text-secondary fw-bold mt-4">7. Privacy</h6>
        <p className="text-secondary mt-3">
          a. Our Privacy Policy outlines how we collect, use, and protect your
          personal information. By using Rewgift.com, you consent to our Privacy
          Policy.
        </p>
        <h6 className="text-secondary fw-bold mt-4">8. Termination</h6>
        <p className="text-secondary mt-3">
          Rewgift.com reserves the right to terminate or suspend user accounts
          for violations of these terms and conditions.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          9. Limitation of Liability
        </h6>
        <p className="text-secondary mt-3">
          Rewgift.com is not liable for any damages or losses resulting from the
          use of our services or reliance on the information provided on our
          website.
        </p>
        <h6 className="text-secondary fw-bold mt-4">10. Changes to Terms</h6>
        <p className="text-secondary mt-3">
          Rewgift.com may update these terms and conditions at any time. Users
          should periodically review them for changes.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          11. Contact Information
        </h6>
        <p className="text-secondary mt-3">
          If you have any questions or concerns about these terms and
          conditions, please contact us at [contact email or address]
        </p>
        <p className="text-secondary mt-3">
          Please remember that this is a general template and should be reviewed
          and customized by a legal professional to ensure it complies with the
          specific legal requirements and regulations applicable to your
          business.
        </p>
      </div>
      <hr />
      <Footer />
    </>
  );
};

export default Terms;
