import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { FaEye, FaTrashAlt } from "react-icons/fa";

import AuthContext from "../../../context/AuthProvider";

import { useQuery, useMutation } from "@apollo/client";
import { GetAllUser } from "../../../graphql/Query";
import { DeleteUser } from "../../../graphql/Mutations";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

export default function AllUsers() {
  const { setUserId } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data: allUser } = useQuery(GetAllUser, { pollInterval: 300 });
  const [deleteUser] = useMutation(DeleteUser, {
    refetchQueries: [GetAllUser],
  });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverOut = () => {
    setHoveredIndex(null);
  };

  function handleDelete(id) {
    console.log("enter");
    deleteUser({
      variables: {
        userId: `${id}`,
      },
    });
  }

  const navigate = useNavigate();
  function RedirectToUserOrders(id) {
    setUserId(id);
    navigate(`/adminPanel/userOrders/${id}`);
  }
  return (
    <>
      <Container style={{ padding: 20 }} fluid className="text-capitalize pe-5">
        <Row>
          <h2 className="mb-3">All Users Details</h2>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center">
                  <th>S.No.</th>
                  <th>User Image</th>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>Contact</th>
                  <th>Points</th>
                  <th>Earning</th>
                  <th>Referral Level</th>
                  <th>Remove User</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {allUser?.getUser?.map(
                  (
                    {
                      id,
                      firstName,
                      lastName,
                      email,
                      contact,
                      password,
                      image,
                      points,
                      earning,
                      refferalLevel,
                      createdDateTime,
                      status,
                    },
                    index
                  ) => (
                    <React.Fragment key={index}>
                      <tr className="table-data">
                        <td>{index + 1}</td>
                        <td>
                          <Image
                            fluid
                            src="https://via.placeholder.com/50"
                            style={{ height: "50%", width: 50 }}
                          />
                        </td>
                        <td>{firstName}</td>
                        <td>{email}</td>
                        <td>{contact}</td>
                        <td
                          onMouseEnter={() => handleHover(index)}
                          onMouseLeave={handleHoverOut}
                        >
                          {points.totalPoints}{" "}
                          {hoveredIndex === index && (
                            <div
                              className="position-absolute bg-light border"
                              style={{ zIndex: 1000 }}
                            >
                              <ul className="list-group">
                                <li className="list-group-item">
                                  login points: {points.loginPoints}
                                </li>
                                <li className="list-group-item">
                                  referral points: {points.referralPoints}
                                </li>
                                <li className="list-group-item">
                                  coupon points: {points.couponPoints}
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                        <td>{earning}</td>
                        <td>{refferalLevel}</td>

                        <td>
                          <Button
                            variant="danger"
                            className="mx-auto d-block"
                            onClick={() => handleDelete(id)}
                          >
                            <FaTrashAlt className="edit-icon" />
                          </Button>
                        </td>
                        <td>
                          <Button
                            onClick={() => RedirectToUserOrders(id)}
                            variant="primary"
                            className="mx-auto d-block"
                          >
                            <FaEye />
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Coming Soon !!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
