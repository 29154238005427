import "../../../componentCss/admin/AllCouponsStyle.css";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Table, Image, Modal, Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLBRAND, GetAllCoupon, GET_PENDING_COUPONS, GET_ACTIVE_COUPONS, GET_INACTIVE_COUPONS } from "../../../graphql/Query";
import { DELETE_BRAND, DeleteCoupon, UPDATE_COUPON_STATUS } from "../../../graphql/Mutations";
import EditCouponModal from "./EditCouponModal";
import { FaPen } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'

export default function AllCoupons() {
  const navigate = useNavigate()
  const [couponId, setCouponId] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [filter, setFilter] = useState("all");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    id: "",
    title: "",
    couponId: "",
    brandName: "",
    brandId: "",
    storeName: "",
    couponCode: "",
    expirationDateTime: "",
    termsAndConditions: "",
    image: "",
    amount: "",
    quantity: "",
    points: "",
    discount: "",
    createdDateTime: "",
    home: "",
    status: "",
  });

  const { data: allCoupons } = useQuery(GetAllCoupon, {
    pollInterval: 300,
    onCompleted: (data) => {
      setCouponList(data.getCouponAll);
    },
  });
  const { data: activeData } = useQuery(GET_ACTIVE_COUPONS, {
    pollInterval: 300,
  });
  const { data: inactiveData } = useQuery(GET_INACTIVE_COUPONS, {
    pollInterval: 300,
  });
  const { data: pendingData } = useQuery(GET_PENDING_COUPONS, {
    pollInterval: 300,
  });
  const [deleteCoupon] = useMutation(DeleteCoupon, {
    refetchQueries: [GetAllCoupon],
  });

  const [updateCouponStatus] = useMutation(UPDATE_COUPON_STATUS, {
    refetchQueries: [GetAllCoupon],
  });

  function handleDelete(id) {
    deleteCoupon({
      variables: {
        couponId: `${id}`,
      },
    });
  }

  function handleChangeStatus(id) {
    setCouponId(id);
    handleShow();
  }

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverOut = () => {
    setHoveredIndex(null);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      updateCouponStatus({
        variables: {
          couponId: `${couponId}`,
          status: `${status}`,
        },
      }).then((data) => {
        updateLocalCouponStatus(couponId, status);
        handleClose();
      });
    }
  };

  const updateLocalCouponStatus = (id, newStatus) => {
    setCouponList(prevList =>
      prevList.map(coupon =>
        coupon.id === id ? { ...coupon, status: newStatus } : coupon
      )
    );
  };

  useEffect(() => {
    if (filter === "all") {
      setCouponList(allCoupons?.getCouponAll);
    }
    if (filter === "pending") {
      setCouponList(pendingData?.getPendingCoupon);
    }
    if (filter === "active") {
      setCouponList(activeData?.getActiveCoupon);
    }
    if (filter === "inactive") {
      setCouponList(inactiveData?.getInActiveCoupon);
    }
  }, [filter]);

  function navigateToEdit({
    id,
    title,
    couponId,
    brandName,
    categoryName,
    brandId,
    categoryId,
    vendorId,
    storeName,
    couponCode,
    expirationDateTime,
    termsAndConditions,
    image,
    amount,
    commission,
    quantity,
    points,
    refPoints,
    nxtRefPoints,
    loginPoints,
    companyPoints,
    FirstPurchPoints,
    FirstPurchCompPoints,
    discount,
    saving,
    youPaying,
    createdDateTime,
    home,
    status,
  }) {
    navigate('/adminPanel/addCoupons', {
      state: {
        id,
        title,
        couponId,
        brandName,
        categoryName,
        brandId,
        categoryId,
        vendorId,
        storeName,
        couponCode,
        expirationDateTime,
        termsAndConditions,
        image,
        amount,
        commission,
        quantity,
        points,
        refPoints,
        nxtRefPoints,
        loginPoints,
        companyPoints,
        FirstPurchPoints,
        FirstPurchCompPoints,
        discount,
        saving,
        youPaying,
        createdDateTime,
        home,
        status,
      }
    })
  }

  return (
    <>
      <Container
        style={{ padding: "20px 50px 0px 0px" }}
        className="text-capitalize"
        fluid
      >
        <div className="filter-row" style={{ maxWidth: "40%" }}>
          <div className="filter-col">
            <button className="filter-button" style={{ backgroundColor: filter === "all" ? "grey" : "#0056B3" }} onClick={() => setFilter("all")}>
              All
            </button>
          </div>
          <div>
            <button className="filter-button" style={{ backgroundColor: filter === "pending" ? "grey" : "#0056B3" }} onClick={() => setFilter("pending")}>
              Pending
            </button>
          </div>

          <div>
            <button className="filter-button" style={{ backgroundColor: filter === "active" ? "grey" : "#0056B3" }} onClick={() => setFilter("active")}>
              Active
            </button>
            <button className="filter-button" style={{ backgroundColor: filter === "inactive" ? "grey" : "#0056B3" }} onClick={() => setFilter("inactive")}>
              Inactive
            </button>
          </div>
        </div>

        <Row>
          <h2 className="mb-3">All Coupon</h2>
          <Col>
            <Table responsive bordered style={{ fontSize: 12 }}>
              <thead className="table-head">
                <tr className="text-center ">
                  <th>S.No.</th>
                  <th>Coupon Image</th>
                  <th>Coupon Title</th>
                  <th>Coupon Code</th>
                  <th>Store Name</th>
                  <th>Expiration Date Time</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Points</th>
                  <th>Discount</th>
                  <th>Status</th>
                  <th>Home</th>
                  <th>Edit Coupon</th>
                  <th>Change Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {couponList?.slice().reverse().map(
                  (
                    {
                      id,
                      title,
                      couponId,
                      brandName,
                      categoryName,
                      brandId,
                      categoryId,
                      vendorId,
                      storeName,
                      couponCode,
                      expirationDateTime,
                      termsAndConditions,
                      image,
                      amount,
                      commission,
                      quantity,
                      points,
                      refPoints,
                      nxtRefPoints,
                      loginPoints,
                      companyPoints,
                      FirstPurchPoints,
                      FirstPurchCompPoints,
                      discount,
                      saving,
                      youPaying,
                      createdDateTime,
                      home,
                      status,
                    },
                    index
                  ) => (
                    <React.Fragment key={index}>
                      <tr className="table-data">
                        <td>{index + 1}</td>
                        <td>
                          <Image fluid src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`} style={{ height: "80%", width: 100 }} />
                        </td>
                        <td className="couponsTitleAdmintruncate">{title}</td>
                        <td>{couponCode}</td>
                        <td>{storeName}</td>
                        <td>{expirationDateTime}</td>
                        <td>{amount}</td>
                        <td>{quantity}</td>
                        <td>{points}</td>
                        <td>{discount}%</td>
                        <td>
                          {status}{" "}
                        </td>
                        <td>{home ? "show" : "hidden"}</td>

                        <td>
                          <Button variant="success" size="sm" className="mx-auto d-block" onClick={() => navigateToEdit(
                            {
                              id,
                              title,
                              couponId,
                              brandName,
                              categoryName,
                              brandId,
                              categoryId,
                              vendorId,
                              storeName,
                              couponCode,
                              expirationDateTime,
                              termsAndConditions,
                              image,
                              amount,
                              commission,
                              quantity,
                              points,
                              refPoints,
                              nxtRefPoints,
                              loginPoints,
                              companyPoints,
                              FirstPurchPoints,
                              FirstPurchCompPoints,
                              discount,
                              saving,
                              youPaying,
                              createdDateTime,
                              home,
                              status,
                            }
                          )}>
                            Edit
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant={status === "Active" ? "success" : "danger"}
                            className="mx-auto d-block"
                            onClick={() => {
                              handleChangeStatus(id);
                            
                            }}
                          >
                            {
                           status
                          }
                          </Button>
                        </td>

                        <td>
                          <Button
                            variant="danger"
                            className="mx-auto d-block"
                            onClick={() => handleDelete(id)}
                            size="sm"
                          >
                            <FaTrashAlt className="edit-icon" />
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      {showEditModal ? (
        <EditCouponModal
          show={showEditModal}
          setShow={setShowEditModal}
          data={editModalData}
        />
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Select
                  required
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="" selected="selected" disabled="disabled">
                    Select Status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Button
              type="submit"
              size="sm"
              variant="success"
              className="mx-auto d-block"
            >
              Change
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
