import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import { useEffect } from "react";
const Privacy = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <UpArrow />
      <Navbar />
      <div className="mt-5 px-5">
        <h1 className="text-black fw-bold">
          <u>Privacy Policy</u>
        </h1>
        <h6 className="text-secondary fw-bold mt-4">1. Introduction :</h6>
        <p className="text-secondary mt-3">
          At Rewgift.com, we value your privacy and are committed to protecting
          your personal information. This Privacy Policy outlines how we
          collect, use, disclose, and safeguard your data when you visit our
          website or use our services. By using Rewgift.com, you consent to the
          practices described in this policy.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          2. Information We Collect :
        </h6>
        <p className="text-secondary mt-3">
          a. Personal Information: We may collect personal information such as
          your name, email address, and other contact details when you create an
          account or interact with our services.
        </p>
        <p className="text-secondary mt-3">
          b. Usage Data: We automatically collect information about your usage
          of our website, including your IP address, device information, browser
          type, and pages visited.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          3. How We Use Your Information
        </h6>
        <p className="text-secondary mt-3">
          a. To Provide Services: We use your information to deliver our
          services, including offering coupons, deals, and affiliate marketing
          opportunities.
        </p>
        <p className="text-secondary mt-3">
          b. Communication: We may send you emails related to your account,
          updates, promotions, or newsletters. You can opt-out of promotional
          emails.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          4. Information Sharing :
        </h6>
        <p className="text-secondary mt-3">
          a. Affiliate Partners: We may share information with affiliate
          partners when you make purchases through their links. Please review
          their privacy policies for details on how they handle your data.
        </p>
        <h6 className="text-secondary fw-bold mt-4">5. Data Security :</h6>
        <p className="text-secondary mt-3">
          a. We employ reasonable security measures to protect your data, but no
          data transmission over the internet can be guaranteed as 100% secure.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          6. Cookies and Tracking :
        </h6>
        <p className="text-secondary mt-3">
          a. We use cookies and tracking technologies to enhance your user
          experience and collect data about how you interact with our website.
          You can manage cookie preferences in your browser settings.
        </p>
        <h6 className="text-secondary fw-bold mt-4">7. Your Choices :</h6>
        <p className="text-secondary mt-3">
          a. You can access, update, or delete your account information. Contact
          us to make such requests.
        </p>
        <p className="text-secondary mt-3">
          b. You can opt-out of promotional emails by following the unsubscribe
          instructions in the emails.
        </p>
        <h6 className="text-secondary fw-bold mt-4">8. Children’s Privacy :</h6>
        <p className="text-secondary mt-3">
          a. Rewgift.com is not intended for use by individuals under the age of
          18. We do not knowingly collect or store information about children.
        </p>
        <h6 className="text-secondary fw-bold mt-4">
          9. Changes to this Privacy Policy :
        </h6>
        <p className="text-secondary mt-3">
          a. We reserve the right to update or modify this Privacy Policy. Any
          changes will be posted on this page, and we encourage you to review it
          periodically.
        </p>
        <h6 className="text-secondary fw-bold mt-4">10. Contact Us :</h6>
        <p className="text-secondary mt-3">
          If you have questions or concerns regarding this Privacy Policy or
          your data, please contact us at [contact email or phone number].
        </p>
        <p className="text-secondary mt-3">
          This Privacy Policy is intended to inform you about how we handle your
          personal information and data. We take privacy seriously and are
          committed to protecting your information. Please review this policy
          and contact us if you have any questions or concerns. Your trust is
          important to us.
        </p>
      </div>
      <hr />
      <Footer />
    </>
  );
};

export default Privacy;
