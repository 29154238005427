import React, { useState, useEffect } from "react";
import { Spinner, Row, Col, Button, Form, Container } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_VENDOR_BY_ID } from "../../graphql/Query";
import { VENDOR_UPDATE } from "../../graphql/Mutations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCrossCircled } from "react-icons/rx";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function VendorProfile() {
  const [validated, setValidated] = useState(false);
  const vendorId = localStorage.getItem("vendorId");

  const [vendorUniqueId, setVendorUniqueId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [brandName, setBrandName] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopContact, setShopContact] = useState("");
  const [shopEmail, setShopEmail] = useState("");
  const [shopPan, setShopPan] = useState("");
  const [shopTan, setShopTan] = useState("");
  const [shopGst, setShopGst] = useState("");
  const [shopAliasName, setShopAliasName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [ifscStatus, setIfscStatus] = useState(false);
  let ifscTimeout;
  let ifscIconsStyle = {
    color: ifscStatus ? "green" : "red",
    fontSize: 20,
  };

  const { data: userData, loading: userDataLoading } = useQuery(
    GET_VENDOR_BY_ID,
    {
      variables: {
        vendorMainId: `${vendorId}`,
      },
    }
  );

  const [updateVendor, { loading: vendorUpdateLoading, error }] = useMutation(
    VENDOR_UPDATE,
    {
      refetchQueries: [GET_VENDOR_BY_ID],
    }
  );

  async function fetchBankDetails(ifscCode) {
    try {
      const res = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
      const data = await res.json();
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  useEffect(() => {
    setVendorUniqueId(userData && userData.getVendorMainById.vendorUniqueId);
    setFirstName(userData && userData.getVendorMainById.firstName);
    setLastName(userData && userData.getVendorMainById.lastName);
    setEmail(userData && userData.getVendorMainById.email);
    setContact(userData && userData.getVendorMainById.contact);
    setBrandName(userData && userData.getVendorMainById.brandName);
    setShopName(userData && userData.getVendorMainById.shopName);
    setShopAddress(userData && userData.getVendorMainById.shopAddress);
    setShopContact(userData && userData.getVendorMainById.shopContact);
    setShopEmail(userData && userData.getVendorMainById.shopEmail);
    setShopPan(userData && userData.getVendorMainById.shopPan);
    setShopTan(userData && userData.getVendorMainById.shopTan);
    setShopGst(userData && userData.getVendorMainById.shopGst);
    setShopAliasName(userData && userData.getVendorMainById.shopAliasName);
    setBankName(userData && userData.getVendorMainById.bankName);
    setBankBranchName(userData && userData.getVendorMainById.bankBranch);
    setAccountHolderName(
      userData && userData.getVendorMainById.accountHolderName
    );
    setIfsc(userData && userData.getVendorMainById.ifsc);
    setAccountName(userData && userData.getVendorMainById.accountNumber);
  }, [userData]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      updateVendor({
        variables: {
          vendorMainUpdateInput: {
            vendorId: `${vendorId}`,
            firstName: `${firstName}`,
            lastName: `${lastName}`,
            email: `${email}`,
            contact: `${contact}`,
            brandName: `${brandName}`,
            shopName: `${shopName}`,
            shopAddress: `${shopAddress}`,
            shopEmail: `${shopEmail}`,
            shopContact: `${shopContact}`,
            shopPan: `${shopPan}`,
            shopTan: `${shopTan}`,
            shopGst: `${shopGst}`,
            accountHolderName: `${accountName}`,
            bankName: `${bankName}`,
            bankBranch: `${bankBranchName}`,
            accountNumber: `${accountHolderName}`,
            ifsc: `${ifsc}`,
            shopAliasName: `${shopAliasName}`,
          },
        },
      })
        .then(() => {
          toast.success("Update successfully!!!");
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (ifscTimeout) {
      clearTimeout(ifscTimeout);
    }

    ifscTimeout = setTimeout(() => {
      fetchBankDetails(ifsc)
        .then((data) => {
          const type = typeof data;
          if (type === "object") {
            setIfscStatus(true);
            setBankName(data.BANK);
            setBankBranchName(data.BRANCH);
          } else {
            setIfscStatus(false);
            setBankName("--Not Found--");
            setBankBranchName("--Not Found--");
          }
        })
        .catch((error) => {
          setIfscStatus(false);
          setBankName("--Not Found--");
          setBankBranchName("--Not Found--");
        });
    }, 1000);
  }, [ifsc]);

  return (
    <>
      {userDataLoading ? (
        <Spinner animation="border" className="mx-auto d-block" />
      ) : (
        <Container>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Vendor Unique Id
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  style={{ fontSize: 12 }}
                  disabled
                  value={vendorUniqueId}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  First name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  style={{ fontSize: 12 }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Last name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  style={{ fontSize: 12 }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Email
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  style={{ fontSize: 12 }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Contact
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Contact"
                  style={{ fontSize: 12 }}
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Brand Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Brand Name"
                  style={{ fontSize: 12 }}
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Shop Name"
                  style={{ fontSize: 12 }}
                  value={shopName}
                  onChange={(e) => setShopName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop Address
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Shop Address"
                  style={{ fontSize: 12 }}
                  value={shopAddress}
                  onChange={(e) => setShopAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop Email
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Shop Email"
                  style={{ fontSize: 12 }}
                  value={shopEmail}
                  onChange={(e) => setShopEmail(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop Contact
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Shop Contact"
                  style={{ fontSize: 12 }}
                  value={shopContact}
                  onChange={(e) => setShopContact(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop PAN
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Shop PAN"
                  style={{ fontSize: 12 }}
                  value={shopPan}
                  disabled
                  onChange={(e) => setShopPan(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop TAN
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Shop TAN"
                  style={{ fontSize: 12 }}
                  value={shopTan}
                  disabled
                  onChange={(e) => setShopTan(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop GST
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Shop GST"
                  style={{ fontSize: 12 }}
                  value={shopGst}
                  disabled
                  onChange={(e) => setShopGst(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Shop Alias Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Shop Alias Name"
                  style={{ fontSize: 12 }}
                  value={shopAliasName}
                  onChange={(e) => setShopAliasName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Bank Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Bank Name"
                  style={{ fontSize: 12 }}
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Bank Branch Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Bank Branch Name"
                  style={{ fontSize: 12 }}
                  value={bankBranchName}
                  onChange={(e) => setBankBranchName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Accoount Holder Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Accoount Holder Name"
                  style={{ fontSize: 12 }}
                  value={accountHolderName}
                  onChange={(e) => setAccountHolderName(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Bank IFSC
                </Form.Label>
                <Row>
                  <Col md={10}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Bank IFSC"
                      style={{ fontSize: 12 }}
                      value={ifsc}
                      onChange={(e) => setIfsc(e.target.value)}
                    />
                  </Col>
                  <Col md={2}>
                    {ifscStatus ? (
                      <IoCheckmarkCircleOutline style={ifscIconsStyle} />
                    ) : (
                      <RxCrossCircled style={ifscIconsStyle} />
                    )}
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 12, fontWeight: "bold" }}>
                  Account Number
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Account Number"
                  style={{ fontSize: 12 }}
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </Form.Group>
            </Row>
            {vendorUpdateLoading ? (
              <Spinner animation="border" className="mx-auto d-block" />
            ) : (
              <Button type="submit" className="mx-auto d-block" size="sm">
                Update
              </Button>
            )}
            {error ? <h5 style={{ color: "red" }}>{error.message}</h5> : null}
          </Form>
        </Container>
      )}
    </>
  );
}
