import React from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AuthContext from "../../context/AuthProvider";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdContentCopy } from "react-icons/md";
import { useState } from "react";

export default function Referral() {
  const { userId } = useContext(AuthContext);

  const [urlCopied, setUrlCopied] = useState(false);

  const CopyTheUrl = () => {
    const url = `https://rewgift.in/signup/${userId}`;
    navigator.clipboard.writeText(url);
    setUrlCopied(true);
  };

  const handleShare = (socialMedia) => {
    const url = `https://rewgift.in/signup/${userId}`;

    switch (socialMedia) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;

      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          "_blank"
        );
        break;

      case "whatsapp":
        window.open(`https://wa.me/?text=${url}`, "_blank");

      default:
        break;
    }
  };

  return (
    <>
      <Container className="m-4 p-4">
        <h3 className="mb-4">Get Rewarded Instantly!</h3>
        <h6 className="mb-3">
          Join our referral program to <b>earn 10 points per referral</b> and
          give your friend <b>10 points on their first login</b>. It's a
          win-win! As a referrer, not only do you benefit, but you also gift
          your friends a head start.
        </h6>
        <h4 className="mb-3 text-center">
          Copy and send this gift to your friends:
        </h4>
        <Row
          className="mb-4 py-4 mx-5 border border-2 border-dark"
          style={{ backgroundColor: "#F0E1FC" }}
        >
          <Col md={11}>
            <h5 className="text-center">https://rewgift.in/signup/{userId}</h5>
          </Col>
          <Col md={1}>
            <button className="btn btn-sm mt-md-0 mt-2" onClick={CopyTheUrl}>
              {urlCopied ? <TiTick /> : <MdContentCopy />}
            </button>
          </Col>
        </Row>

        <h4 className="mb-3 text-center">Start Earning Points Now!</h4>
        <Container>
          <Row>
            <Col className="d-flex align-items-center justify-content-center ">
              <div
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "50%",
                  backgroundColor: "#3B5998",
                }}
                onClick={() => handleShare("facebook")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaFacebookF className="fs-4 text-white " />
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-center ">
              <div
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "50%",
                  backgroundColor: "#007FB1",
                }}
                onClick={() => handleShare("linkedin")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaLinkedinIn className="fs-4 text-white " />
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-center ">
              <div
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "50%",
                  backgroundColor: "#25D366",
                }}
                onClick={() => handleShare("whatsapp")}
                className="d-flex align-items-center justify-content-center "
              >
                <FaWhatsapp className="fs-4 text-white " />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
