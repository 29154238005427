import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLBRAND } from "../../../graphql/Query";
import { DELETE_BRAND, UPDATE_BRAND_STATUS } from "../../../graphql/Mutations";
import BrandEditModal from "./BrandEditModal";
import { FaPen } from "react-icons/fa";

export default function AllBrands() {
  const [brandId, setBrandId] = useState("");
  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    id: "",
    brandName: "",
    brandTitle: "",
    image: "",
    category: "",
    categoryId: "",
    status: "",
    home: "",
    description: "",
    instructions: "",
    saving: 0,
    redeemInstructions: "",
  });

  const { data: allBrands } = useQuery(GET_ALLBRAND, { pollInterval: 300 });
  const [deleteBrand] = useMutation(DELETE_BRAND, {
    refetchQueries: [GET_ALLBRAND],
  });

  const [updateBrandStatus] = useMutation(UPDATE_BRAND_STATUS, {
    refetchQueries: [GET_ALLBRAND],
  });

  function handleDelete(id) {
    deleteBrand({
      variables: {
        brandId: `${id}`,
      },
    });
  }

  function handleChangeStatus(id) {
    setBrandId(id);
    handleShow();
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      updateBrandStatus({
        variables: {
          brandId: `${brandId}`,
          status: `${status}`,
        },
      }).then(() => {
        handleClose();
      });
    }
  };
  return (
    <>
      <Container style={{ padding: 20 }} fluid className="text-capitalize">
        <Row className="pe-5">
          <h2 className="mb-3">All Brands</h2>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center">
                  <th>S.No.</th>
                  <th>Brand Image</th>
                  <th>Brand Name</th>
                  <th>Brand Category</th>
                  <th>Saving</th>
                  <th>Status</th>
                  <th>Home</th>
                  <th>Edit Brand</th>
                  <th>Remove Brand</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {allBrands?.getBrandAll?.map(
                  (
                    {
                      id,
                      brandName,
                      brandTitle,
                      brandUrl,
                      image,
                      category,
                      categoryId,
                      status,
                      home,
                      description,
                      instructions,
                      saving,
                      redeemInstructions,
                    },
                    index
                  ) => (
                    <tr className="table-data" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Image
                          fluid
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                          style={{ height: "80%", width: 100 }}
                        />
                      </td>
                      <td>{brandName}</td>
                      <td>{category}</td>
                      <td>{saving}</td>
                      <td>
                        {status}{" "}
                        <FaPen
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChangeStatus(id)}
                        />
                      </td>
                      <td>{home ? "show" : "hidden"}</td>

                      <td>
                        <Button
                          variant="success"
                          className="mx-auto d-block"
                          onClick={() => {
                            setShowEditModal(true);
                            setEditModalData({
                              id,
                              brandName,
                              brandTitle,
                              brandUrl,
                              image,
                              category,
                              categoryId,
                              status,
                              home,
                              description,
                              instructions,
                              saving,
                              redeemInstructions,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>

                      <td>
                        <Button
                          variant="danger"
                          className="mx-auto d-block"
                          onClick={() => handleDelete(id)}
                        >
                          <FaTrashAlt className="edit-icon" />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* Edit Modal */}
      {showEditModal ? (
        <BrandEditModal
          show={showEditModal}
          setShow={setShowEditModal}
          data={editModalData}
        />
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Select
                  required
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="" selected="selected" disabled="disabled">
                    Select Status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Button
              type="submit"
              size="sm"
              variant="success"
              className="mx-auto d-block"
            >
              Change
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
