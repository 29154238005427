import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import { useQuery, useMutation } from "@apollo/client";
import { TopOffers } from "../../../graphql/Query";
import { DeleteCoupon } from "../../../graphql/Mutations";

export default function AllTopOffers() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data: topoffer } = useQuery(TopOffers, { pollInterval: 300 });
  // const [deleteCoupon] = useMutation(DeleteCoupon, {
  //   refetchQueries: [TopOffers],
  // });

  // function handleDelete(id) {
  //   deleteCoupon({
  //     variables: {
  //       couponId: `${id}`,
  //     },
  //   });
  // }
  return (
    <>
      <Container
        style={{ padding: "20px 50px 0px 0px" }}
        className="text-capitalize"
        fluid
      >
        <Row>
          <h2 className="mb-3">All Offers</h2>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr className="text-center ">
                  <th>S.No.</th>
                  <th>Offer Image</th>
                  <th>Offer Title</th>
                  <th>Offer ID</th>
                  <th>Offer Code</th>
                  <th>Store Name</th>
                  <th>Expiration Date Time</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Home</th>
                  <th>Remove Offer</th>
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {topoffer?.getTopOffer?.map(
                  (
                    {
                      id,
                      title,
                      couponId,
                      brandName,
                      brandId,
                      storeName,
                      couponCode,
                      expirationDateTime,
                      termsAndConditions,
                      image,
                      amount,
                      quantity,
                      createdDateTime,
                      home,
                      status,
                    },
                    index
                  ) => (
                    <tr className="table-data" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Image
                          fluid
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                          style={{ height: "80%", width: 100 }}
                        />
                      </td>
                      <td>{title}</td>
                      <td>{couponId}</td>
                      <td>{couponCode}</td>
                      <td>{storeName}</td>
                      <td>{expirationDateTime}</td>
                      <td>{amount}</td>
                      <td>{quantity}</td>
                      <td>{status}</td>
                      <td>{home ? "show" : "hidden"}</td>

                      <td>
                        <Button
                          variant="danger"
                          className="mx-auto d-block"
                          // onClick={() => handleDelete(id)}
                        >
                          <FaTrashAlt className="edit-icon" />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Coming Soon !!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
