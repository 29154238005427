import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import UpArrow from "../components/UpArrow";
import { useEffect } from "react";
const About = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
    <UpArrow/>
      <Navbar />

      <div className="about_main mt-5 px-5">
        <h1 className="text-black fw-bold">
          <u>About Us</u>
        </h1>
        <p className="mt-4 text-secondary">
          Welcome to <strong>Rewgift.com –</strong>Your Ultimate Destination for
          Savings and Rewards!
        </p>
        <p className="mt-4 text-secondary">
          At <strong>Rewgift.com,</strong> we’re passionate about helping you
          save money while making your online shopping experience more rewarding
          than ever. We’re committed to making your shopping journey as seamless
          as possible by offering the best deals and discounts through coupons
          and affiliate marketing partnerships.
        </p>

        <h6 className="text-secondary fw-bold mt-4">Our Mission</h6>
        <p className="text-secondary mt-3">
          Our mission is simple: to empower online shoppers like you with the
          best money-saving opportunities while connecting you with top brands
          and retailers. We’re dedicated to transforming the way you shop
          online, ensuring that every purchase brings you joy and savings.
        </p>
        <h6 className="text-secondary fw-bold mt-4">What Sets Us Apart</h6>
        <ol className="mt-3">
          <li className="text-secondary">
            Handpicked Coupons: Our team of experts scours the web to curate a
            handpicked selection of the most valuable coupons, discounts, and
            offers. We ensure that you have access to the latest and greatest
            deals
          </li>
          <li className="text-secondary mt-3">
            Trusted Affiliate Partners: We collaborate with reputable
            affiliate partners, guaranteeing you access to an extensive range of
            products and services. When you shop through our affiliate links,
            you’re not only saving money but also supporting our platform.
          </li>
          <li className="text-secondary mt-3">
            User-Centric Approach: Your satisfaction is our top priority.
            We’ve designed our platform to be user-friendly, ensuring you have a
            hassle-free experience while exploring deals and making purchases.
          </li>
        </ol>
        <h6 className="text-secondary fw-bold mt-4">
          Why Choose Rewgift.com?
        </h6>
        <ol className="mt-3">
          <li className="text-secondary">
            Save More, Shop Smarter: Our coupons and deals are carefully
            selected to help you save on everything from fashion and electronics
            to travel and more.
          </li>
          <li className="text-secondary mt-3">
            Supporting You: We’re here to answer your questions, provide
            guidance, and assist you in getting the most out of your shopping
            experience.
          </li>
          <li className="text-secondary mt-3">
            Transparency: We’re transparent about our affiliate relationships
            and how we earn commissions. Your trust is essential to us.
          </li>
        </ol>
        <h6 className="text-secondary fw-bold mt-4">
          Join the Rewgift.com Community
        </h6>
        <p className="text-secondary mt-3">
          Become a part of the Rewgift.com community and start your savings
          journey today. Whether you’re a seasoned online shopper or new to the
          world of coupons and affiliate marketing, we’re here to guide you and
          make your shopping experience truly rewarding.
        </p>
        <p className="text-secondary mt-3">
          Thank you for choosing Rewgift.com. Let’s embark on a journey of smart
          shopping together!
        </p>
        <h6 className="text-secondary fw-bold mt-4">-</h6>
        <p className="text-secondary mt-3">
          Feel free to modify this content to reflect your brand’s unique values
          and mission. It’s essential to convey authenticity and build trust
          with your visitors through your “About Us” page.
        </p>
      </div>
      <hr />
      <Footer />
    </>
  );
};

export default About;
