import React from "react";
import { Row, Col } from "react-bootstrap";
import "../../componentCss/admin/SuperAdminDashboardView.css";
import { Chart as ChartJS, Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";

export default function SuperAdminDashboardView() {
  // Static dummy data for a coupon-selling website
  const dummyData = {
    totalCoupons: 500,
    pendingOrders: 200,
    completedOrders: 300,
    totalCustomers: 1000,
  };

  const userData = {
    labels: [
      "Total Coupons",
      "Pending Orders",
      "Completed Orders",
      "Total Customers",
    ],
    datasets: [
      {
        label: "Orders Placed",
        data: [
          dummyData.totalCoupons,
          dummyData.pendingOrders,
          dummyData.completedOrders,
          dummyData.totalCustomers,
        ],
        backgroundColor: ["#2ecc71", "#3498db", "#e67e22", "#9b59b6"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      <h1>Dashboard</h1>
      <div className="main">
        <div className="cardBox">
          <div className="card">
            <div>
              <div className="numbers">{dummyData.totalCoupons}</div>
              <div className="cardName">Total Coupons</div>
            </div>
            <div className="iconBx"></div>
          </div>
          <div className="card">
            <div>
              <div className="numbers">{dummyData.pendingOrders}</div>
              <div className="cardName">Pending Orders</div>
            </div>
            <div className="iconBx"></div>
          </div>
          <div className="card">
            <div>
              <div className="numbers">{dummyData.completedOrders}</div>
              <div className="cardName">Completed Orders</div>
            </div>
            <div className="iconBx"></div>
          </div>
          <div className="card">
            <div>
              <div className="numbers">{dummyData.totalCustomers}</div>
              <div className="cardName">Total Customers</div>
            </div>
            <div className="iconBx"></div>
          </div>
        </div>
        <Row>
          <Col md={6}>
            <Bar data={userData} />
          </Col>
          <Col md={6}>
            <Pie data={userData} style={{ width: "280px", height: "280px" }} />
          </Col>
        </Row>
      </div>
    </>
  );
}
