import React, { useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_SUBSCRIBERS } from "../../../graphql/Query";
import {
  UPDATE_SUBSCRIBER,
  DELETE_SUBSCRIBER,
} from "../../../graphql/Mutations";
import Moment from "react-moment";
import { FaPen, FaTrashAlt } from "react-icons/fa";

const Subscribetoemail = () => {
  const { data: SubsList } = useQuery(GET_ALL_SUBSCRIBERS);
  const [updateSubscriber] = useMutation(UPDATE_SUBSCRIBER);
  const [deleteSubscriber] = useMutation(DELETE_SUBSCRIBER);

  const reload = () => window.location.reload();

  const [status, setStatus] = useState("");
  const [validated, setValidated] = useState(false);
  const [subId, setSubId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleChangeStatus(id) {
    setSubId(id);
    handleShow();
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      updateSubscriber({
        variables: {
          subscriberUpdateInput: {
            id: `${subId}`,
            status: `${status}`,
          },
        },
      }).then(() => {
        handleClose();
        reload();
      });
    }
  };

  function handleDeleteSub(id) {
    deleteSubscriber({
      variables: {
        subId: `${id}`,
      },
    }).then(() => reload());
  }

  return (
    <>
      <div className="row">
        <div className="col shadow me-5 rounded-2">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">S NO.</th>
                <th scope="col">Email</th>
                <th scope="col">Created Date & Time</th>
                <th scope="col">Status</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {SubsList?.getAllSubscribers?.map(
                ({ id, email, createdDateTime, status }, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{email}</td>
                    <td>
                      <Moment
                        format="DD/MM/YYYY hh:mm:ss a"
                        date={createdDateTime}
                      />
                    </td>
                    <td>
                      {status}{" "}
                      <FaPen
                        style={{ cursor: "pointer" }}
                        onClick={() => handleChangeStatus(id)}
                      />
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        className="mx-auto d-block"
                        onClick={() => handleDeleteSub(id)}
                      >
                        <FaTrashAlt className="edit-icon" />
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Select
                  required
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="" selected="selected" disabled="disabled">
                    Select Status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Button
              type="submit"
              size="sm"
              variant="success"
              className="mx-auto d-block"
            >
              Change
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Subscribetoemail;
