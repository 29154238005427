/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Subnavbar from "../components/Subnavbar";
import "../componentCss/cart.css";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import axios from "axios";
import { TiMinus } from "react-icons/ti";
import Footer from "../components/Footer";
import { TbTruckDelivery } from "react-icons/tb";
import { useContext } from "react";
import { DataContext } from "../contextApi";
import AuthContext from "../context/AuthProvider";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER } from "../graphql/Mutations";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { GET_USER_BY_TOKEN } from "../graphql/Query";

import { loadStripe } from "@stripe/stripe-js";

const Cart = () => {
  const { token, refUserId, refCouponId } = useContext(AuthContext);
  const { cartData, setcartData, cartValue, setcartValue } =
    useContext(DataContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { data: UserData } = useQuery(GET_USER_BY_TOKEN, {
    variables: {
      token: `${token}`,
    },
  });
  console.log(UserData);
  const [createOrder, { loading }] = useMutation(CREATE_ORDER, {
    onCompleted: () => {
      console.log("order done !!!");
      emptyCart("Please wait while we are processing your order");
    },
    onError: (e) => {
      if (e.message === "unauthorized") {
        console.log("unauthorized");
        //any thing else
      }
    },
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [products, setProducts] = useState(cartData);

  const increaseQuantity = (i) => {
    setProducts((prevValue) =>
      prevValue.map((data, o) => {
        if (i === o) {
          if (!(data.quantity > data.qty)) {
            return data;
          }
          const updatedQty = data.qty + 1;
          return {
            ...data,
            qty: updatedQty,
          };
        }
        return data;
      })
    );
  };

  const decreaseQuantity = (i) => {
    setProducts((prevValue) =>
      prevValue.map((data, o) => {
        if (i === o) {
          if (data.qty > 1) {
            const updatedQty = data.qty - 1;
            return { ...data, qty: updatedQty };
          } else {
            return data;
          }
        }
        return data;
      })
    );
  };

  const removeFromCart = (i) => {
    if (
      // window.confirm(
      //   "Are you sure you want to remove this item from your cart?"
      // )
      toast.success("Item removed from the cart !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    ) {
      setProducts((prevCart) =>
        prevCart.filter((item, o) => {
          return i !== o;
        })
      );
      setcartValue((prevValue) => prevValue - 1);
      setcartData((prev) => prev.filter((item, o) => i !== o));
    } else {
      // alert('No');
    }
  };

  const emptyCart = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // if (window.confirm("Remove all items from your cart?"))
    {
      setProducts([]);
      setcartValue(0);
      setcartData([]);
    }
  };

  const cartTotalAmount = products.reduce(
    (acc, data) => acc + data.youPaying * data.qty,
    0
  );

  async function handleCheckOut(sessionID) {
    let expandedCartData = new Array();
    await products.map(
      (
        {
          id,
          title,
          couponId,
          brandName,
          categoryName,
          brandId,
          vendorId,
          storeName,
          couponCode,
          expirationDateTime,
          termsAndConditions,
          image,
          amount,
          commission,
          quantity,
          points,
          refPoints,
          nxtRefPoints,
          loginPoints,
          companyPoints,
          FirstPurchPoints,
          FirstPurchCompPoints,
          discount,
          saving,
          youPaying,
          createdDateTime,
          home,
          status,
          qty,
        },
        index
      ) => {
        expandedCartData.push({
          id: `${id}`,
          title: `${title}`,
          couponId: `${couponId}`,
          vendorId: `${vendorId}`,
          brandName: `${brandName}`,
          categoryName: `${categoryName}`,
          storeName: `${storeName}`,
          couponCode: `${couponCode}`,
          expirationDateTime: `${expirationDateTime}`,
          termsAndConditions: `${termsAndConditions}`,
          image: `${image}`,
          amount: parseFloat(amount),
          commission: parseFloat(commission),
          quantity: parseInt(quantity),
          points: parseFloat(points),
          refPoints: parseFloat(refPoints),
          nxtRefPoints: parseFloat(nxtRefPoints),
          loginPoints: parseFloat(loginPoints),
          companyPoints: parseFloat(companyPoints),
          FirstPurchPoints: parseFloat(FirstPurchPoints),
          FirstPurchCompPoints: parseFloat(FirstPurchCompPoints),
          discount: parseFloat(discount),
          saving: parseFloat(saving),
          youPaying: parseFloat(youPaying),
          createdDateTime: `${createdDateTime}`,
          status: `${status}`,
          qty: parseInt(qty),
          totalPoints: parseFloat(points * qty),
          totalPaying: parseFloat(youPaying * qty),
        });
      }
    );
    try {
      await createOrder({
        variables: {
          orderInput: {
            token: `${token}`,
            orderedCoupons: expandedCartData,
            status: `pending`,
            sessionID,
            refUserId: `${refUserId}`,
            refCouponId: `${refCouponId}`,
          },
        },
      })
        .then(() => {
          console.log("ordered", {
            token: `${token}`,
            orderedCoupons: expandedCartData,
            status: `pending`,
            refUserId: `${refUserId}`,
            refCouponId: `${refCouponId}`,
          });
        })
        .catch((e) => {
          console.log("not able to palce your order", e);
        });
    } catch (error) {
      console.log("func error: ", error);
    }
  }

  // make payment

  const handleCheckOutCart = async () => {
    const { firstName, lastName, contact } = UserData.getUserByToken;
    if (!firstName || !lastName || !contact) {
      return alert(
        "Missing some details in you profile please complete your profile first"
      );
    }
    try {
      const stripe = await loadStripe(
        "pk_test_51PR6zfJ7WLlxoSTJFDurbkB6fPHK2cvtwEekdGnq4SOsTu8LeoaUrQ6UFvo3juxbpWRnuOEnjUEUtpGP0hInfeDW00PS8uexrz"
      );

      console.log("products", products);

      const body = {
        products,
        user: {
          name: `${UserData.getUserByToken.firstName} ${UserData.getUserByToken.lastName}`,
          email: UserData.getUserByToken.email,
          contact: UserData.getUserByToken.contact,
        },
      };

      const header = {
        "Content-Type": "application/json",
      };

      const response = await fetch(
        "https://couponback.vercel.app/api/stripe-session-create",
        {
          method: "POST",
          headers: header,
          body: JSON.stringify(body),
        }
      );

      // const res = await axios.post('http://localhost:4000/api/stripe-session-create',body);

      // console.log('server response',res);

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      const session = await response.json();

      //jugad----------------------------------------------

      await handleCheckOut(session.id);

      console.log("incoming", session);
      //bakend hit for storing session id

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.log(result.error);
      }
    } catch (error) {
      console.error("Checkout error:", error);
    }
  };

  return (
    <>
      <Navbar />
      <Subnavbar />
      {token ? null : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}

      <div className="row justify-content-start align-items-start m-0">
        <ToastContainer />
        <div className="col-md-8">
          <div className="row justify-content-center m-0">
            <div className="col-md-12 mt-5">
              <div className="card">
                <div className="card-body p-0 ">
                  <table className="table cart-table mb-0">
                    <thead>
                      <tr>
                        <th className="text-center"></th>
                        <th className="text-center">Deliver to</th>
                        <th className="text-center">Phone</th>
                        <th className="text-center">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <button className="prdct-delete fs-3">
                            <TbTruckDelivery />
                          </button>
                        </td>
                        <td className="text-center text-capitalize">
                          <p className="m-0">
                            {UserData && UserData.getUserByToken?.firstName}{" "}
                            {UserData && UserData.getUserByToken?.lastName}
                          </p>
                        </td>
                        <td>
                          <div className="product-name">
                            <p className="text-center m-0">
                              {UserData && UserData.getUserByToken?.contact}{" "}
                            </p>
                          </div>
                        </td>
                        <td className="text-center">
                          {UserData && UserData.getUserByToken?.email}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-1">
              <div className="card">
                <div className="card-header card-header-main bg-dark p-3">
                  <div className="card-header-flex">
                    <h5 className="text-white m-0">
                      Cart Calculation{" "}
                      {products.length > 0 ? `(${products.length})` : ""}
                    </h5>
                    {products.length > 0 ? (
                      <>
                        <button
                          className="btn btn-danger mt-0 btn-sm"
                          onClick={() =>
                            emptyCart("wait while your cart is being cleared")
                          }
                        >
                          <i className="fa fa-trash-alt mr-2"></i>
                          <span>Empty Cart</span>
                        </button>
                        <button
                          className="btn btn-primary mt-0 btn-sm"
                          // onClick={handleCheckOut}
                          onClick={handleCheckOutCart}
                        >
                          Check Out
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="card-body p-0 ">
                  {products.length === 0 ? (
                    <table className="table cart-table mb-0">
                      <tbody>
                        <tr>
                          <td colSpan="6">
                            <div className="cart-empty">
                              <i className="fa fa-shopping-cart"></i>
                              <p>Your Cart Is empty</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <table className="table cart-table mb-0">
                      <thead>
                        <tr>
                          <th className="text-center">Action</th>
                          <th className="text-center">Product</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Price</th>
                          <th className="text-center">Saving</th>
                          <th className="text-center">Qty</th>
                          <th className="text-center">Total Savings</th>
                          <th className="text-center">Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((data, index) => {
                          const {
                            id,
                            image,
                            title,
                            brandName,
                            discount,
                            amount,
                            saving,
                            youPaying,
                            qty,
                          } = data;
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <button
                                  className="prdct-delete"
                                  onClick={() => removeFromCart(index)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                              <td className="text-center">
                                <div className="product-img">
                                  <img
                                    src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                                    alt=""
                                  />
                                </div>
                              </td>

                              <td>
                                <div className="product-name">
                                  <p className="text-center m-0">{brandName}</p>
                                </div>
                              </td>
                              <td className="text-center m-0">CA${amount}</td>
                              <td className="text-success fw-bold text-center">
                                {discount} % Save
                              </td>

                              <td className="ps-4">
                                <div className="prdct-qty-container">
                                  <button
                                    className="prdct-qty-btn"
                                    type="button"
                                    onClick={() => decreaseQuantity(index)}
                                  >
                                    <TiMinus />
                                  </button>
                                  <input
                                    type="text"
                                    name="qty"
                                    className="qty-input-box"
                                    value={qty}
                                    disabled
                                  />
                                  <button
                                    className="prdct-qty-btn"
                                    type="button"
                                    onClick={() => increaseQuantity(index)}
                                  >
                                    <FaPlus />
                                  </button>
                                </div>
                              </td>
                              <td className="text-center">
                                CA${(qty * saving).toFixed(0)}
                              </td>
                              <td className="text-center">
                                CA${(qty * youPaying).toFixed(0)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={8}>
                            <Row className="m-0">
                              <Col>
                                Items in Cart
                                <span className="ml-2 mr-2">:</span>
                                <span className="text-danger ms-1">
                                  {cartValue}
                                </span>
                              </Col>
                              <Col className="d-flex align-items-center justify-content-end">
                                Total Price<span className="ml-2 mr-2">:</span>
                                <span className="text-danger ms-1">
                                  CA${cartTotalAmount.toFixed(0)}
                                </span>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-5">
          <div className="row justify-content-center align-items-start m-0">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-dark p-3">
                  <div className="card-header-flex">
                    <h5 className="text-white m-0">Payment Summary</h5>
                  </div>
                </div>
                <div className="card-body p-0 ">
                  {products.length === 0 ? (
                    <table className="table cart-table mb-0">
                      <tbody>
                        <tr>
                          <td colSpan="6">
                            <div className="cart-empty">
                              <i className="fa fa-shopping-cart"></i>
                              <p>Your Cart Is empty</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <table className="table cart-table mb-0">
                      <thead>
                        <tr>
                          <th className="text-center">Payment Method</th>
                          <th className="text-center">
                            {" "}
                            Surcharge/ Savings(CA$)
                          </th>
                          <th className="text-center"> Payable(CA$)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((data, index) => {
                          const { saving, youPaying, qty } = data;
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <button className="prdct-qty-btn fs-3">
                                  <MdOutlinePayment />
                                </button>{" "}
                                <span className="fw-bold">UPI</span>
                              </td>

                              <td className="text-center">
                                CA${(qty * saving).toFixed(0)}
                              </td>
                              <td className="text-center">
                                CA${(qty * youPaying).toFixed(0)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={8}>
                            <Row className="m-0">
                              <Col className="d-flex align-items-center justify-content-end">
                                Total Price<span className="ml-2 mr-2">:</span>
                                <span className="text-danger ms-1">
                                  CA${cartTotalAmount.toFixed(0)}
                                </span>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Footer />
    </>
  );
};

export default Cart;
