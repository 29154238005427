import React, { useEffect, useState } from "react";
import { Card, Container, Image, Row, Col, Spinner } from "react-bootstrap";
import {
  GET_TRENDINGDEAL,
  GET_BRAND_BY_SAVING,
  GET_ALLBRAND,
} from "../graphql/Query";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Offers from "./Offers";
import Brands from "./Brands";
import TopCoupons from "./TopCoupons";

// import required modules
import { Grid, Pagination, Autoplay } from "swiper/modules";

export default function FilterPage() {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [select, setSelect] = useState("0% - 100%");
  const [range, setRange] = useState({ start: 6, end: 10 });

  const navigate = useNavigate();

  const { data: trandingDealList } = useQuery(GET_TRENDINGDEAL);
  const { data: allbrands } = useQuery(GET_ALLBRAND);
  const { data: brandList, loading } = useQuery(GET_BRAND_BY_SAVING, {
    variables: {
      discountWiseBrand: {
        start: parseInt(range.start),
        end: parseInt(range.end),
      },
    },
  });

  useEffect(() => {
    if (allbrands?.getBrandAll?.length > 0) {
      setSelectedBrand(allbrands.getBrandAll[0]);
    }
  }, [allbrands]);

  const handleBrandChange = (event) => {
    const selectedId = event.target.value;
    const brand = allbrands.getBrandAll.find((b) => b.id === selectedId);
    setSelectedBrand(brand);
  };

  console.log(allbrands);
  console.log(selectedBrand);
  useEffect(() => {
    let rangeGetter = { start: 0, end: 100 };
    switch (select) {
      case "0% - 100%":
        rangeGetter.start = 0;
        rangeGetter.end = 100;
        break;
      case "0% - 5%":
        rangeGetter.start = 0;
        rangeGetter.end = 5;
        break;
      case "6% - 10%":
        rangeGetter.start = 6;
        rangeGetter.end = 10;
        break;
      case "11% - 20%":
        rangeGetter.start = 11;
        rangeGetter.end = 20;
        break;
      case "21% - Above":
        rangeGetter.start = 21;
        rangeGetter.end = 100;
        break;
      default:
        rangeGetter.start = 0;
        rangeGetter.end = 100;
    }
    setRange(rangeGetter);
  }, [select]);

  return (
    <>
      <Container fluid>
        <div
          className="tagline mb-3 d-flex align-items-center justify-content-center"
          style={{ height: 50, width: "100%", background: "#dddddd94" }}
        >
          <p className="m-0 fw-bold">
            1 Reward/Earned Point <span className="text-success">(p)</span> =
            CAD 1
          </p>
        </div>
        <Row>
          <Col md={8}>
            <Row>
              <Card
                style={{ height: "100%", paddingTop: 35, paddingBottom: 35 }}
              >
                <Card.Body>
                  {loading ? (
                    <Spinner animation="border" className="mx-auto d-block" />
                  ) : allbrands?.getBrandAll?.length > 0 ? (
                    <Swiper
                      autoplay={{ delay: 2500, disableOnInteraction: false }}
                      slidesPerView={window.innerWidth > 768 ? 4 : 1}
                      grid={{ rows: 2, fill: "row" }}
                      spaceBetween={10}
                      pagination={{ dynamicBullets: true }}
                      grabCursor={true}
                      modules={[Grid, Pagination, Autoplay]}
                      className="mySwiper"
                    >
                      {allbrands.getBrandAll.map((brand, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            style={{ boxShadow: "none", flexShrink: 0 }}
                            className={`mx-2 pt-3 ${
                              brand.status !== "Active" ? "inactive" : ""
                            }`}
                            onClick={() => {
                              if (brand.status === "Active") {
                                navigate(`/${brand.brandName}/${brand?.id}`);
                              }
                            }}
                          >
                            <div
                              style={{ width: "100%", height: "70%" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Image
                                src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${brand?.image}`}
                                style={{ width: "60%", height: 80 }}
                                fluid
                              />
                            </div>
                            <h6
                              className="mt-3 fw-bold"
                              style={{ textAlign: "center", fontSize: 10 }}
                            >
                              {brand?.brandName}
                            </h6>
                            <h6
                              style={{
                                fontSize: 10,
                                marginTop: 9,
                                background: "#f1fcff",
                                padding: 10,
                              }}
                              className="text-center text-black fw-bold"
                            >
                              Savings {brand?.saving}%
                            </h6>
                          </Card>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Container className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <h3>No data available</h3>
                    </Container>
                  )}
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col>
                <Card
                  style={{
                    maxHeight: "250px",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Card.Body>
                    {trandingDealList?.getTrendingDeal?.map(
                      ({ dealName, image, dealUrl }, index) => (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open(`${dealUrl}`)}
                        >
                          <Row>
                            <Col md={3}>
                              <Image
                                style={{ width: 70, height: 50 }}
                                src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${image}`}
                                fluid
                              />
                            </Col>
                            <Col
                              md={9}
                              style={{
                                background:
                                  "linear-gradient(to right, #4579f9, #04c2c9)",
                                padding: 10,
                                color: "#fff",
                              }}
                            >
                              {dealName}
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Body>
                    <select
                      onChange={handleBrandChange}
                      style={{ width: "100%", padding: 12, borderRadius: 10 }}
                    >
                      <option value="0">Select Brand:</option>
                      {allbrands?.getBrandAll?.map((brand, index) => (
                        <option key={index} value={brand?.id}>
                          {brand?.brandName}
                        </option>
                      ))}
                    </select>
                    {selectedBrand && (
                      <Link
                        to={`/${selectedBrand?.brandName}/${selectedBrand?.id}`}
                      >
                        <img
                          src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${selectedBrand?.image}`}
                          alt={selectedBrand?.brandName}
                          style={{ height: 135, width: 150, marginTop: 10 }}
                          className="mx-auto d-block"
                        />
                      </Link>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "-30px" }}>
          <Offers />
        </Row>
        <Row>
          <Col md={8}>
            <div className="overflow-hidden shadow border m-0 p-2 rounded">
              <h4 className="mt-2 ms-2 fw-bold text-black">Brand Discount!</h4>

              <div className="row overflow-hidden my-3 px-3">
                <div className="col discount_col">
                  <div
                    style={{
                      background:
                        select === "0% - 100%" ? "#b6d44c" : "#98989a",
                      height: 30,
                      width: 50,
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelect("0% - 100%")}
                    className="mt-2 d-flex justify-content-center align-items-center"
                  >
                    <h6
                      style={{
                        color: select === "0% - 100%" ? "#000" : "#fff",
                      }}
                      className="text-center"
                    >
                      All
                    </h6>
                  </div>
                  <div
                    style={{
                      background: select === "0% - 5%" ? "#b6d44c" : "#98989a",
                      height: 30,
                      width: 100,
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelect("0% - 5%")}
                    className="mt-2 d-flex justify-content-center align-items-center"
                  >
                    <h6
                      style={{ color: select === "0% - 5%" ? "#000" : "#fff" }}
                      className="text-center"
                    >
                      0% - 5%
                    </h6>
                  </div>
                  <div
                    style={{
                      background: select === "6% - 10%" ? "#b6d44c" : "#98989a",
                      height: 30,
                      width: 100,
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelect("6% - 10%")}
                    className="mt-2 d-flex justify-content-center align-items-center"
                  >
                    <h6
                      style={{ color: select === "6% - 10%" ? "#000" : "#fff" }}
                      className="text-center"
                    >
                      6% - 10%
                    </h6>
                  </div>
                  <div
                    style={{
                      background:
                        select === "11% - 20%" ? "#b6d44c" : "#98989a",
                      height: 30,
                      width: 110,
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelect("11% - 20%")}
                    className="mt-2 d-flex justify-content-center align-items-center"
                  >
                    <h6
                      style={{
                        color: select === "11% - 20%" ? "#000" : "#fff",
                      }}
                      className="text-center"
                    >
                      11% - 20%
                    </h6>
                  </div>
                  <div
                    style={{
                      background:
                        select === "21% - Above" ? "#b6d44c" : "#98989a",
                      height: 30,
                      width: 120,
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelect("21% - Above")}
                    className="mt-2 d-flex justify-content-center align-items-center"
                  >
                    <h6
                      style={{
                        color: select === "21% - Above" ? "#000" : "#fff",
                      }}
                      className="text-center"
                    >
                      21% - Above
                    </h6>
                  </div>
                </div>
                <hr className="mt-4" />
              </div>

              <div className="row">
                <div className="d-flex justify-content-start flex-wrap  p-2">
                  {loading ? (
                    <Container className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <h3>Loading...</h3>
                    </Container>
                  ) : brandList?.getBrandDiscountWise?.length > 0 ? (
                    <Swiper
                      slidesPerView={window.innerWidth > 768 ? 4 : 1}
                      grid={{ rows: 2, fill: "row" }}
                      spaceBetween={10}
                      pagination={{ dynamicBullets: true }}
                      grabCursor={true}
                      modules={[Grid, Pagination]}
                      className="mySwiper"
                    >
                      {brandList?.getBrandDiscountWise?.map((brand, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            style={{ boxShadow: "none" }}
                            className={`mx-2 pt-3 ${
                              brand.status !== "Active" ? "inactive" : ""
                            }`}
                            onClick={() => {
                              if (brand.status === "Active") {
                                navigate(`/${brand.brandName}/${brand?.id}`);
                              }
                            }}
                          >
                            <div
                              style={{ width: "100%", height: "70%" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Image
                                src={`https://satyambucket.s3.ap-south-1.amazonaws.com/${brand?.image}`}
                                style={{ width: "60%", height: 80 }}
                              />
                            </div>
                            <h6
                              className="mt-3 fw-bold"
                              style={{ textAlign: "center", fontSize: 10 }}
                            >
                              {brand?.brandName}
                            </h6>

                            <h6
                              style={{
                                fontSize: 10,
                                marginTop: 9,
                                background: "#f1fcff",
                                padding: 10,
                              }}
                              className="text-center text-black fw-bold"
                            >
                              Savings {brand?.saving}%
                            </h6>
                          </Card>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Container className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <h3>No data available</h3>
                    </Container>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <TopCoupons />
          </Col>
        </Row>
      </Container>
    </>
  );
}
