import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import pizza from "../../assets/pizza_hut.png";
import flipcart from "../../assets/flipcart.png";

// import required modules
import { Navigation } from "swiper/modules";

const SliderHowtouse = () => {
  return (
    <>
      <div >
        {/* <h5 className="mb-4">Handpicked for you</h5> */}
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          loop={true}
          navigation={true}
          modules={[Navigation]}
        // className="mySwiper"
        // breakpoints={{
        //   300: {
        //     slidesPerView: 2,
        //   },
        //   768: {
        //     slidesPerView: 3,
        //   },
        //   1024: {
        //     slidesPerView: 3,
        //   },
        // }}
        >
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4">
              <img
                className="d-block mx-auto mb-3"
                src={pizza}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4  ">
              <img
                className="d-block mx-auto mb-3"
                src={flipcart}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4  ">
              <img
                className="d-block mx-auto mb-3"
                src={pizza}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4">
              <img
                className="d-block mx-auto mb-3"
                src={pizza}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4  ">
              <img
                className="d-block mx-auto mb-3"
                src={flipcart}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4  ">
              <img
                className="d-block mx-auto mb-3"
                src={pizza}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4  ">
              <img
                className="d-block mx-auto mb-3"
                src={flipcart}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="shadow py-3 px-2 bg-white rounded-4 mb-4  ">
              <img
                className="d-block mx-auto mb-3"
                src={pizza}
                height={100}
                width={100}
                alt="pizza"
              />
              <p className="text-secondary text-center ">ALDO-MAJOR BRANDS</p>
              <h6
                style={{ backgroundColor: "#F1FCFF" }}
                className="py-2 px-2 text-center "
              >
                Saving 2%
              </h6>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default SliderHowtouse;
