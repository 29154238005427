import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OtpSenderForm from "./OtpSenderForm";
import PwdResetterForm from "./PwdResetterForm";

function ForgetPasswordModal({ show, setShow }) {
  const handleClose = () => setShow(false);
  const [isOtp, setIsOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Forget Password Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isOtp && !success ? (
          <PwdResetterForm setSuccess={setSuccess} email={email} />
        ) : (
          <OtpSenderForm outData={setEmail} done={setIsOtp} />
        )}
        {success ? "Password Reset Successfull" : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ForgetPasswordModal;
