import "./poolDetail.css";
import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table, Modal } from "react-bootstrap";
import {
  GET_POOL_DETAILS,
  GET_POOL_DETAILS_BY_DATE,
  GET_USER_BY_ID,
  GET_ORDER_BY_ORDERID,
} from "../../../graphql/Query";
import Moment from "react-moment";

export default function PoolDetails() {
  const [list, setList] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [viewData, setViewData] = useState({
    for: "",
    data: {},
  });

  //for view modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { refetch: allRefech, loading: allLoading } = useQuery(
    GET_POOL_DETAILS,
    {
      onCompleted: (data) => {
        setList(data?.getPoolDetail);
      },
    }
  );

  const { refetch: filterRefech, loading: filterLoading } = useQuery(
    GET_POOL_DETAILS_BY_DATE,
    {
      variables: {
        filterDate: {
          dateFrom: `${dateFrom}`,
          dateTo: `${dateTo}`,
        },
      },
    }
  );

  const [
    getUserById,
    { loading: userLoading, data: userData, refetch: userRefetch },
  ] = useLazyQuery(GET_USER_BY_ID);
  const [
    getOrderByOrderId,
    { loading: orderLoading, data: orderData, refetch: orderRefetch },
  ] = useLazyQuery(GET_ORDER_BY_ORDERID);

  function handleSubmit(e) {
    e.preventDefault();
    filterRefech()
      .then((res) => {
        setList(res.data.getPoolDetailByDate);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClear() {
    allRefech()
      .then((res) => {
        setList(res.data.getPoolDetail);
        setDateFrom("");
        setDateTo("");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleView(id) {
    if (id === null) return;
    if (id.length < 12) {
      getOrderByOrderId({ variables: { orderId: `${id}` } }).then((res) => {
        console.log("res", res.data.getOrderView);
        setViewData({ for: "Order", data: res.data.getOrderView });
        handleShow();
      });
    } else {
      getUserById({ variables: { userId: `${id}` } }).then((res) => {
        console.log("res", res.data.getUserById);
        setViewData({ for: "User", data: res.data.getUserById });
        handleShow();
      });
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={4}>
            <h2>Pool Detail</h2>
          </Col>
          <Col md={8} className="input-button-group">
            <form className="form-container" onSubmit={handleSubmit}>
              <label htmlFor="dateFrom">From: </label>
              <input
                required
                id="dateFrom"
                type="date"
                className="detail-input"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
              <label htmlFor="dateTo">To: </label>
              <input
                required
                id="dateTo"
                type="date"
                className="detail-input"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
              <button type="submit">Filter</button>
              <button className="clear-button" onClick={handleClear}>
                Clear
              </button>
            </form>
          </Col>
        </Row>
        {allLoading || filterLoading ? (
          <div className="loader"></div>
        ) : (
          <Table responsive bordered striped className="text-center">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Action</th>
                <th>Date / Time</th>
                <th>Action View</th>
                <th>Pre. Points</th>
                <th>New Points</th>
              </tr>
            </thead>
            <tbody>
              {list?.map(
                (
                  { actionId, action, dateAndTime, newPoitns, prePoitns },
                  index
                ) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{action}</td>
                    <td>
                      <Moment format="DD/MM/YY hh/mm/ss A">
                        {dateAndTime}
                      </Moment>
                    </td>
                    <td>
                      <Button
                        disabled={actionId ? false : true}
                        onClick={() => handleView(actionId)}
                      >
                        {actionId
                          ? actionId?.length < 12
                            ? "Order"
                            : "User"
                          : "Admin"}
                      </Button>
                    </td>
                    <td>{prePoitns}</td>
                    <td>{newPoitns}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
      </Container>

      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {viewData.for === "" ? (
                <h2>Have nothing to show</h2>
              ) : viewData.for === "Order" ? (
                <h2>Order Details</h2>
              ) : viewData.for === "User" ? (
                <h2>User Details</h2>
              ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {viewData.for === "" ? (
              <h1>Have nothing to show</h1>
            ) : viewData.for === "Order" ? (
              <>
                <h4 className="text-center mb-3">
                  Order Id: <i>{viewData.data.orderId}</i>
                </h4>
                <h5 className="mb-3 text-center">Order Details </h5>
                <p
                  className="text-center mt-3 py-2 rounded-2"
                  style={{
                    background: "#d1f4ff",
                  }}
                >
                  Date / Time:{" "}
                  <Moment format="DD/MM/YY hh/mm/ss A">
                    {viewData.data.orderedDateTime}
                  </Moment>
                </p>
                <div>
                  <p
                    className="text-center mt-3 py-2 rounded-2"
                    style={{
                      background: "#d1f4ff",
                    }}
                  >
                    Total Paying: {viewData.data.totalOrderPaying}
                  </p>
                  <p
                    className="text-center mt-3 py-2 rounded-2"
                    style={{
                      background: "#d1f4ff",
                    }}
                  >
                    Status: {viewData.data.status}
                  </p>
                  <p
                    className="text-center mt-3 py-2 rounded-2"
                    style={{
                      background: "#d1f4ff",
                    }}
                  >
                    Total Points: {viewData.data.totalOrderPoints}
                  </p>
                </div>
              </>
            ) : viewData.for === "User" ? (
              <>
                <h4 className="text-center mb-3">
                  Email: {viewData.data.email}
                </h4>

                <h5 className="mb-3 text-center">Points Details: </h5>
                <div>
                  <div>
                    <p
                      className="text-center mt-3 py-2 rounded-2"
                      style={{
                        background: "#d1f4ff",
                      }}
                    >
                      Total: {viewData.data.points.totalPoints}
                    </p>
                    <p
                      className="text-center mt-3 py-2 rounded-2"
                      style={{
                        background: "#d1f4ff",
                      }}
                    >
                      Coupon: {viewData.data.points.couponPoints}
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-center mt-3 py-2 rounded-2"
                      style={{
                        background: "#d1f4ff",
                      }}
                    >
                      Login: {viewData.data.points.loginPoints}
                    </p>
                    <p
                      className="text-center mt-3 py-2 rounded-2"
                      style={{
                        background: "#d1f4ff",
                      }}
                    >
                      Referral: {viewData.data.points.referralPoints}
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}
